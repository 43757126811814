import React from "react";

import classes from "./Footer.module.css";

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="bg-white border-t border-gray-400 shadow">
        <div className="container max-w-md mx-auto flex py-8">
          <div className="w-full mx-auto flex flex-wrap">
            <div className="w-full md ">
              <div className="px-8">
                <h3 className="font-bold font-bold text-gray-900">Help / Support</h3>
                <p className="py-4 text-gray-600 text-sm">
                  feedconnect@iprospect.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
