import React, { useState } from 'react';
import { Page, Stack, Divider, Layout, ActivityTimeline, FormatDateTime, FileLockup, Loading, IconButton, Box, Chip, Caption } from '@dentsu-ui/components';
import { useEffect } from 'react';
import zenDeskService from '../../services/zenDeskService';
import Date from '../Date';
import { secondaryAction } from "../../helper/secondaryAction"
import banner from "../../assets/images/background/dentsu_website_headers_16.jpg"
import thumbnail from "../../assets/images/FC-Logo-Icon.png"
import ThemeSwitch from '../../components/ThemeSwitch'
import RefreshContent from '../../components/RefreshContent'

const TicketDetailsReference = (props) => {
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState({ eticketDetails: {}, comments: [], attachments: [], users: [] })
    const query = new URLSearchParams(props.location.search);
    const ticketId = query.get('ticketId')

    const findAttachments = (comment, attachments) => {
        let output = []
        attachments.forEach(attachment => {
            if (attachment.commentId == comment.id) {
                output.push(attachment)
                output = [...output, ...findAttachments(attachment, attachments)]
            }
        })
        return output
    }

    const findUserName = (id) => {
        return (details.users.filter(x => x.id == id)[0] || { name: id }).name
    }

    useEffect(() => {
        async function getTicketDetails() {
            if (ticketId) {
                setLoading(true)
                let response = await zenDeskService.getTicketDetails(ticketId)
                setDetails(response.data)
                response.data.comments.forEach((comment) => {
                    comment.attachments = findAttachments(comment, response.data.attachments)
                    //console.table(comment.id, comment.attachments.map(x => x.id))
                })
                setLoading(false)
            }
        }
        getTicketDetails()
    }, [])

    const title = ticketId + ' ' + (details.eticketDetails.ticket ? details.eticketDetails.ticket.subject : '');
    const metadata = details.eticketDetails.ticket ? <> <FormatDateTime mode="local" value={details.eticketDetails.ticket.createdAt}></FormatDateTime> . {details.eticketDetails.ticket.requesterId}.{details.eticketDetails.ticket.createdAt}</> : ''


    return (
        <>
            <Page title="Zendesk"
                thumbnail={thumbnail}
                banner={banner}
                availableSpace={
                    <Stack alignItems="center">
                        <Caption as="span" isSecondary className="white">
                            <RefreshContent onClick={() => { this.loadData() }}></RefreshContent>
                        </Caption>
                        <Divider isFlexChild orientation="vertical" />
                        <Caption as="span" isSecondary className="white">
                            <ThemeSwitch>{props.theme}</ThemeSwitch>
                        </Caption>
                    </Stack>
                }
                secondaryActions={secondaryAction(props)}
            >
                <Layout>
                    <Layout.Section>
                        <Layout.Panel hasBorder metadata={metadata} title={title}>
                            <Layout>
                                <Layout.Section>
                                    <Layout.Panel hasBorder variant="darker">
                                        {details.eticketDetails.ticket
                                            ? <Box className="flex justify-between">
                                                <div className='w-full capitalize'>
                                                    <Caption>State</Caption>
                                                    <Chip size="small" variant="status" status="neutral" hasStatusLight style={{ marginLeft: 0 }}>
                                                        {details.eticketDetails.ticket.status}
                                                    </Chip>
                                                </div>
                                                <div className='w-full'>
                                                    <Caption>Requester</Caption>
                                                    {details.eticketDetails.requester.name}
                                                </div>
                                                <div className='w-full'>
                                                    <Caption>Client</Caption>
                                                    {details.eticketDetails.client.value}
                                                </div>
                                                <div className='w-full'>
                                                    <Caption>Partner</Caption>
                                                    {details.eticketDetails.partner.value}
                                                </div>
                                                <div className='w-full capitalize'>
                                                    <Caption>Priority</Caption>
                                                    {details.eticketDetails.ticket.priority}
                                                </div>
                                                <div className='w-full'>
                                                    <Caption>Closed</Caption>
                                                    <Date mode="local" value={details.eticketDetails.completionDate.value}></Date>
                                                </div>
                                                <div className='w-full'>
                                                    <Caption>Source</Caption>
                                                    {details.eticketDetails.sourceOfRequest.value}
                                                </div>
                                                <div className='w-full'>
                                                    <Caption>Assignee</Caption>
                                                    {details.eticketDetails.assignee != null ? details.eticketDetails.assignee.name : "N/A"}
                                                </div>
                                            </Box>
                                            : <Loading />}
                                    </Layout.Panel>
                                </Layout.Section>
                            </Layout>
                            <Layout>
                                <Layout.Section isScrollable>
                                    <Layout.Panel title="Conversations" hasBorder hasDivider>
                                        <ActivityTimeline>
                                            {
                                                loading
                                                    ? <Loading>Please wait...</Loading>
                                                    : details.comments.map(comment => {
                                                        return <ActivityTimeline.Item
                                                            name={findUserName(comment.authorId)}
                                                            subject="commented"
                                                            timestamp={comment.createdAt}
                                                            description={comment.metadata}
                                                            attachments={
                                                                comment.attachments.length > 0
                                                                    ? <Box className=''>
                                                                        {comment.attachments.map(attachment => {
                                                                            return <FileLockup
                                                                                name={attachment.fileName}
                                                                                extension={attachment.contentType.split('/').pop()}
                                                                                filesize={attachment.size}
                                                                                action={<IconButton variant="ghost" icon="download" size="small"
                                                                                    onClick={() => {
                                                                                        window.open(attachment.localPath, '_blank')
                                                                                    }} />}
                                                                            />
                                                                        })}
                                                                    </Box>
                                                                    : ''
                                                            }
                                                        >
                                                            <div dangerouslySetInnerHTML={{ __html: comment.htmlBody }}>
                                                            </div>
                                                        </ActivityTimeline.Item>
                                                    })
                                            }
                                        </ActivityTimeline>
                                    </Layout.Panel>
                                </Layout.Section>
                            </Layout>
                        </Layout.Panel>
                    </Layout.Section>
                </Layout>
            </Page>
        </>
    )
}

export default TicketDetailsReference