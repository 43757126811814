import React from "react";
import { connect } from "react-redux";
import { addClients } from "../store/reducers/clientsSlice";
import { addMarkets } from "../store/reducers/marketsSlice";
import { addVendors } from "../store/reducers/vendorsSlice";

import { Page, Loading, Caption, Stack, Divider, Tooltip, Layout, Box, Switch } from "@dentsu-ui/components";
import moment from 'moment';

//import { LineChart, PieChart } from '@dentsu-ui/charts';
import Errors from "../components/home/errors";
import Imports from "../components/home/imports";
import Exports from "../components/home/exports";
import ClientService from "../services/clientService";
//import data from "../services/chartData.json";
import AccountService from "../services/AccountService";
import Metrics from "../components/home/metrics";
import RefreshContent from "../components/RefreshContent";
import banber from "../assets/images/FeedConnectLogo.png"
import ThemeSwitch from "../components/ThemeSwitch";
import thumbnail from "../assets/images/FC-Logo-Icon.png"
import banner from "../assets/images/background/dentsu_website_headers_2.jpg"
import Timeline from "../components/home/timeline";
import { secondaryAction } from "../helper/secondaryAction"


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      clients: "",
      totalAccounts: "",
      activeAccounts: "",
      receivedImports: "",
      deliveredExports: "",
      feedWarnings: "",
      feedErros: "",
      latestExportImport: [],
      clientNames: [],
      exports: [],
      imports: [],
      errors: [],
      vendors: [],
      markets: [],
      showMoreLatest: false,
      latestText: "show more",
      showTimeline: false,
      timeline: []
    };
    document.title ='Dashboard'
  }
  componentDidMount() {
    this.loadData();
  }

  fetchData = async () => {
    return Promise.all([
      ClientService.getClientMetrics(),
      ClientService.getClients(),
      ClientService.getImports(),
      ClientService.getExports(),
      ClientService.getErrors(),
      ClientService.getVendors(),
      AccountService.getAccountsMarkets(),
      ClientService.getTimeline()
    ])
  }

  loadData = async () => {
    try {
      this.setState({ loading: true });
      let [metrics, clients, imports, exports, errors, vendors, markets, timeline] = await this.fetchData()

      this.setState({ clients: metrics.data.clients });
      this.setState({ totalAccounts: metrics.data.totalAccounts });
      this.setState({ activeAccounts: metrics.data.activeAccounts });
      this.setState({ receivedImports: metrics.data.receivedImports });
      this.setState({ deliveredExports: metrics.data.deliveredExports });
      this.setState({ feedWarnings: metrics.data.feedWarnings });
      this.setState({ feedErros: metrics.data.feedErrors });
      this.setState({ clientNames: clients.data });
      this.processImport(imports.data, errors.data)

      this.setState({ exports: exports.data });
      this.setState({ vendors: vendors.data });
      this.setState({ markets: markets.data });

      this.props.addClients(clients.data)
      this.props.addVendors(vendors.data)
      this.props.addMarkets(markets.data)

      let errorsData = []
      errors.data.forEach(element => {
        element = { id: element.id, clientId:element.clientId, type: 'N/A', ...element.details }
        errorsData.push(element)
      });
      this.setState({ errors: errorsData });
      this.setState({ timeline: timeline.data })
    }

    catch (error) {
    } finally {
      this.setState({ loading: false });
    }
  }

  processTimeline(data) {
    console.log(data)
    this.setState({ timeline: data })
    // this.setState({
    //   timeline: {
    //     totalFeeds: data.length,
    //     receivedFeeds: this.state.imports.length
    //   }
    // })
    // data.forEach(timeline => {
    //   //if(this.state.imports.filter(x=>))
    //   console.log(timeline.clientId, moment(timeline.averagetimeOfImportReceivedOnFtp).format('h:mma'))
    // });
  }

  processImport(data, errors) {
    let imports = []
    data.forEach(element => {
      if (element.status.toLowerCase() == 'error') {
        element.error = errors.filter(x => x.details.errorType == 'import' && x.details.queueKey == element.queueKey)[0]
      }
      imports.push(element)
    });

    this.setState({ imports: imports });
  }

  getVendorName(id) {
    let vendor = this.state.vendors.filter(x => x.id == id)[0] || {}
    return vendor.displayName
  }

  clientName = (id) => {
    let market = this.state.markets.filter((market) => market.id == id)[0] || {}
    let clientName = this.state.clientNames.filter((client) => client.id == market.parentId)[0] || {}
    return clientName.name;
  }

  showMoreLatestImportExport = () => {
    this.setState(
      prevState => {
        const latestText = !prevState.showMoreLatest ? "show less" : "show more";
        return {
          showMoreLatest: !prevState.showMoreLatest,
          latestText,
        };
      }
    );
  };
 
  render() {
    if (this.state.loading) {
      return (
        <>
          <Box className="flex items-center justify-center" height="100%">
            <Loading>Please wait while we are fetching the latest content</Loading>
          </Box>
        </>
      );
    }
    // const linedata = data.linechartData;
    // const piedata = data.PiechartData;
    const formatter = new Intl.NumberFormat('en-US');
    const errors = this.state.errors; // data.Errors;
    const importdata = (this.state.imports)
    const exportdata = (this.state.exports)
    const newLayout = this.props.theme == 'dentsu' && <>
      <Layout>
        <Layout.Section>
          <Layout.Panel title="Metrics" description="Metrics Description" variant="darker">
            <Metrics value={{
              isNew: true,
              clients: this.state.clients,
              activeAccounts: this.state.activeAccounts,
              totalAccounts: this.state.totalAccounts,
              receivedImports: this.state.receivedImports,
              deliveredExports: this.state.deliveredExports,
              feedWarnings: this.state.feedWarnings,
              feedErros: this.state.feedErros
            }}></Metrics>
          </Layout.Panel>
        </Layout.Section>
      </Layout>

      <Layout>
        <Layout.Section>
          <Layout.Panel title="Errors" description="Errors Description" variant="darker">
            <Errors data={errors}></Errors>
          </Layout.Panel>
        </Layout.Section>
      </Layout>

      <Layout>
        <Layout.Section>
          <Layout.Panel title="Latest Imports" description="Latest Imports Description" variant="darker">
            <Imports data={importdata}></Imports>
          </Layout.Panel>
        </Layout.Section>
      </Layout>

      <Layout>
        <Layout.Section>
          <Layout.Panel title="Latest Exports" description="Latest Exports Description" variant="darker">
            <Exports data={exportdata}></Exports>
          </Layout.Panel>
        </Layout.Section>
      </Layout>
    </>

    const oldLayout = this.props.theme != 'dentsu' && <>
      <div className="container w-full mx-auto">
        <div className="w-full px-4 md:px-0 mb-16 text-gray-800 leading-normal">
          <Metrics value={{
            clients: this.state.clients,
            activeAccounts: this.state.activeAccounts,
            totalAccounts: this.state.totalAccounts,
            receivedImports: isNaN(this.state.receivedImports)?" ":(formatter.format(this.state.receivedImports)),
            deliveredExports: isNaN(this.state.deliveredExports)?" ":(formatter.format(this.state.deliveredExports)),
            feedWarnings: this.state.feedWarnings,
            feedErros: isNaN(this.state.feedErros)?" ":(formatter.format(this.state.feedErros)),
          }}></Metrics>

          <div className="flex flex-wrap items-stretch">
            <Timeline value={this.state.timeline} imports={this.state.imports} />
          </div>

          <div className="flex flex-wrap items-stretch">
            <div className="w-full p-3">
              <div className="bg-white border rounded shadow h-full">
                <div className="border-b p-3 bg-[#60607d]">
                  <h5 className="font-bold uppercase text-[#ffffff]">Errors</h5>
                </div>
                <div className="w-full p-5">
                  <Errors data={errors}></Errors>
                </div>
              </div>
            </div>

            <div className="w-full p-3">
              <div className="bg-white border rounded shadow h-full">
                <div className="border-b p-3 bg-[#60607d]" >
                  <h5 className="font-bold uppercase text-[#ffffff]">Latest Imports</h5>
                </div>
                <div className="p-5">
                  <Imports data={importdata}></Imports>
                </div>
              </div>
            </div>

            <div className="w-full p-3">
              <div className="bg-white border rounded shadow h-full">
                <div className="border-b p-3 bg-[#60607d]">
                  <h5 className="font-bold uppercase text-[#ffffff]">Latest Exports</h5>
                </div>
                <div className="p-5">
                  <Exports data={exportdata}></Exports>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="flex flex-wrap items-stretch">
            <div className="w-full md:w-1/2 xl:w-1/3 p-3">
              <div className="bg-white border rounded shadow h-full">
                <div className="border-b p-3 bg-[#60607d]">
                  <h5 className="font-bold uppercase text-[#ffffff]">Import Timeline</h5>
                </div>
                <div className="p-5">
                  <LineChart height="280px" colors="pink" data={linedata}
                    margin={{ top: 10, right: 20, bottom: 25, left: 25, }}
                    axisBottom={{
                      legend: 'JCP',
                      legendOffset: 50,
                      legendPosition: 'middle'
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-1/3 p-3">
              <div className="bg-white border rounded shadow h-full">
                <div className="border-b p-3 bg-[#60607d]">
                  <h5 className="font-bold uppercase text-[#ffffff]">Optimizations Needed</h5>
                </div>
                <div style={{ height: 280 }} className="p-5">
                  <Legends.Item color="#6AA6EB">Active &nbsp;</Legends.Item>
                  <Legends.Item color="#8C5ED5">Cancelled &nbsp;</Legends.Item>
                  <Legends.Item color="#A32DC1">Reactivated &nbsp;</Legends.Item>
                  <Legends.Item color="#00A1A5">New</Legends.Item>
                  <PieChart innerRadius={0.5} data={piedata} margin={{ top: 25, right: 25, bottom: 10, left: 25 }}
                  />
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="w-full p-3">
            <div className="bg-white border rounded shadow">
              <div className="border-b p-3 bg-[#60607d]">
                <h5 className="font-bold uppercase text-[#ffffff]">Latest Import / Export</h5>
              </div>
              <div className="p-5">
                <table className="w-full p-5 text-gray-700">
                  <thead>
                    <tr>
                      <th className="text-left text-blue-900">Client</th>
                      <th className="text-left text-blue-900">Type</th>
                      <th className="text-left text-blue-900">Status</th>
                      <th className="text-left text-blue-900">Received</th>
                      <th className="text-left text-blue-900">Delivered</th>
                      <th className="text-left text-blue-900">Total Feed Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.showMoreLatest ? lastestImportandexport : lastestImportandexport.slice(0, 5)}
                  </tbody>
                </table>
                <div style={{
                  marginBottom: 8
                }}>
                  <a variant="ghost" onClick={this.showMoreLatestImportExport}>{this.state.latestText}</a><Spacer width={10} />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>

    return (
      <Page title="Dashboard"
        thumbnail={thumbnail}
        banner={banner}
        availableSpace={
          <Stack alignItems="center">
            <Caption as="span" isSecondary className="white">
              <RefreshContent onClick={() => { this.loadData() }}></RefreshContent>
            </Caption>
            <Divider isFlexChild orientation="vertical" />
            <Caption as="span" isSecondary className="white">
              <ThemeSwitch>{this.props.theme}</ThemeSwitch>
            </Caption>
          </Stack>
        }
        secondaryActions={secondaryAction(this.props)}                    

      >

        {newLayout}

        {oldLayout}
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients.value,
    markets: state.markets.value,
    vendors: state.vendors.value,
    theme: state.theme.value
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addClients: (clients) => dispatch(addClients(clients)),
    addVendors: (vendors) => dispatch(addVendors(vendors)),
    addMarkets: (markets) => dispatch(addMarkets(markets))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);