import http from "../helper/http-common";
import AuthService from "./authService";

export default class ProductService {
    static getProduct(id, client) {
        return http.get(`products/${id}`, {
            params: { client },
            headers: { Authorization: `bearer ${AuthService.getToken()}` },
        }).catch(function (error) {
            return Promise.reject(error)
        });
    }

    static getProductHistory(id, client) {
        return http.get(`products/${id}/history`, {
            params: { client },
            headers: { Authorization: `bearer ${AuthService.getToken()}` },
        }).catch(function (error) {
            return Promise.reject(error)
        });
    }
}