import React, { useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Page, Stack, Caption, Divider, Layout, Select } from "@dentsu-ui/components";
import RuleService from '../../../services/ruleService';
import ClientService from '../../../services/clientService';
import RefreshContent from "../../../components/RefreshContent";
import ThemeSwitch from "../../../components/ThemeSwitch";
import AgGridModal from '../../../components/AgGridModal';
import Date from '../../../components/Date';
import banner from "../../../assets/images/background/dentsu_website_headers_7.jpg"
import noImage from '../../../assets/images/no-image.png'
import { secondaryAction } from "../../../helper/secondaryAction";
import ViewLogicDetails from '../../../components/ViewLogicDetails';
const Logic = (props) => {
    const [rules, setRules] = useState([]);
    const [clients, setClients] = useState([]);
    const [markets, setMarkets] = useState([]);
    const [vendor, setVendor] = useState({});
    const [selectedMarket, setSelectedMarket] = useState();

    const gridRef = useRef();

    const defaultColDef = { resizable: true, flex: 1, filter: true, sortable: true, autoHeight: true };

    useEffect(() => {
        document.title = `Logic (${props.match.params.id})`
        async function getRules() {
            console.log(props.match.params.id, props.match.params.account)
            if (props.match.params.id) {
                gridRef.current.api.showLoadingOverlay();
                let rules = await RuleService.getRules(props.match.params.id)
                rules.data.customRules.forEach(rule => {
                    if (rule.IsEnabled) {
                        rule.Status = 'Active'
                    } else {
                        rule.Status = 'Inactive'
                    }
                });
                rules.data.customRules.forEach(rule => {
                  rule.View = {cellRenderer :ViewLogicDetails}
                });
                if (props.match.params.account) {
                    let accounts = localStorage.getItem('Accounts_' + props.match.params.id)
                    accounts = JSON.parse(accounts)
                    let vendor = (accounts.accounts.filter(x => x.id == props.match.params.account)[0] || { vendorId: 0 })
                    setVendor(vendor)
                    setRules(rules.data.customRules.filter(x => x.VendorKey == vendor.vendorId))
                } else {
                    setRules(rules.data.customRules)
                }
                gridRef.current.api.hideOverlay();
            }
        }
        setTimeout(() => { getRules() }, 1000);
    }, [])

    useEffect(() => {
        async function getClients() {
            let clients = await ClientService.getClients()
            let markets = await ClientService.getMarkets()
            setMarkets(markets.data)

            clients.data.forEach(client => {
                client.label = client.name
                client.options = markets.data.filter(x => x.parentId == client.id)
                client.options.forEach(option => {
                    option.label = option.name
                });
            });
            setClients(clients.data)
            setSelectedMarket(markets.data.filter(x => x.id == props.match.params.id)[0])
        }
        getClients()
    }, [])

    const getMarketName = (id) => {
        let client = markets.filter((market) => market.id == id)[0] || {}
        return client.name
    }

    const getColumns = () => {
        if (rules && rules.length > 0) {
            let keys = Object.keys(rules[0])
            let columns = []
            keys.filter(x => ["RuleKey", "ClientId", "VendorKey", "SqlStatement", "Type", "Task", "IsEnabled", "SortIndex", "Category", "TicketId", "Comment", "CreatedDate", "Status"].includes(x))
                .map(key => {
                    let column = { field: key, cellClass: 'cell-wrap-text' }
                    switch (key) {
                        case "RuleKey":
                            column = { ...column, headerName: "RuleKey", field: 'RuleKey', minWidth: 110 }; break;
                        case "ClientId":
                            column = { ...column, headerName: "Client ID", field: 'ClientId', minWidth: 110 }; break;
                        case "VendorKey":
                            column = { ...column, headerName: "Partner", field: 'VendorKey', minWidth: 100 }; break;
                        case "SqlStatement":
                            column = { ...column, headerName: "SQL Statement", field: 'SqlStatement', minWidth: 150 }; break;
                        case "Type":
                            column = { ...column, headerName: "Type", field: 'Type', minWidth: 100 }; break;
                        case "Task":
                            column = { ...column, headerName: "Task", field: 'Task', minWidth: 100 }; break;
                        case "IsEnabled":
                            column = { ...column, headerName: "IsEnabled", field: 'IsEnabled', minWidth: 120 }; break;
                        case "SortIndex":
                            column = { ...column, headerName: "Sort Index", field: 'SortIndex', minWidth: 120 }; break;
                        case "Category":
                            column = { ...column, headerName: "Target Field", field: 'Category', minWidth: 130 }; break;

                        case "TicketId":
                            column = { ...column, headerName: "Ticket ID", field: 'TicketId', minWidth: 120 }; break;
                        case "Comment":
                            column = { ...column, headerName: "Comment", field: 'Comment', minWidth: 130 }; break;
                        case "CreatedDate":
                            column = { ...column, headerName: 'Created Date', field: 'CreatedDate', cellRenderer: Date, minWidth: 175 }; break;
                        case "Status":
                            column = { ...column, headerName: "Status", field: 'Status', minWidth: 100  }; break;
                        default:
                            break;
                    }
                    columns.push(column)
                    return columns
                });
            
            const viewColumn = {
                field: "View",
                cellRenderer: ViewLogicDetails,
                headerName: "View",   
                minWidth: 85,
                maxWidth: 85          
                };
                columns.unshift(viewColumn);
                 return columns;
        }
        return []
    };

    const exportDataAsCsv = () => {
        let fileName = selectedMarket.id + '-Logic-' + moment().format('MMDDyyyy') + '.csv'
        if (gridRef) gridRef.current.api.exportDataAsCsv({ fileName });
    }
    let thumbnail = noImage
    try {
        thumbnail = require(`../../../assets/images/client-logo/${props.match.params.id}.jpg`)
    } catch (error) {
        thumbnail = noImage
    }
    let metadata = props.match.params.id +
        (props.match.params.account
            ? (' - ' + vendor.name)
            : '')
    return <>
        <Page title="Logic"
            thumbnail={thumbnail}
            banner={banner}
            metadata={metadata}
            secondaryActions={secondaryAction(props)}

        >

            <Layout>
                <Layout.Section>
                    <div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center pt-4 pb-4">
                        <label>Account &nbsp;&nbsp;&nbsp;</label>
                        {getMarketName(props.match.params.id)}
                        {/* <Select
                            width={250}
                            options={clients}
                            customLabelKey="name"
                            customValueKey="id"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            onChange={(selected, event) => {
                                setSelectedMarket(selected)
                            }} value={selectedMarket} /> */}
                    </div>
                </Layout.Section>
            </Layout>

            <Layout>
                <Layout.Section>
                    <Layout.Panel title="Existing Logic" hasBorder variant="darker">
                        <div className="ag-theme-alpine" style={{ height: '700px', width: '100%' }}>
                            <AgGridReact ref={gridRef}
                                rowData={rules}
                                columnDefs={getColumns()}
                                defaultColDef={defaultColDef}
                                pagination={true}
                                paginationPageSize={25}                
                            >
                            </AgGridReact>
                        </div>
                    </Layout.Panel>
                </Layout.Section>
            </Layout>

            <Layout>
                <Layout.Section>
                    <div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center pb-4" onClick={exportDataAsCsv}>
                        <FontAwesomeIcon size="2x" className="cursor-pointer" icon={faDownload} />
                    </div>
                </Layout.Section>
            </Layout>
        </Page>
    </>
}

export default Logic