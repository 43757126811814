import React from "react";
import { Link } from "react-router-dom";

import { Avatar, Menu, Icon } from "@dentsu-ui/components";

import AuthService from "../../services/authService";
import logo from "../../assets/images/FeedConnectLogo.png";

import classes from "./Navigation.module.css";

export default class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
    };
  }

  async getUser() {
    try {
      const user = AuthService.getUser();
      this.setState({ user: user });
    } catch (error) { }
  }

  componentDidMount() {
    this.getUser();
  }

  render() {
    return (
      <nav id="header" className="bg-white fixed w-full z-10 top-0 shadow">
        <div className="w-full container mx-auto flex flex-wrap items-center mt-0 pt-3 pb-3 md:pb-0">
          <div className="w-1/2 pl-2 md:pl-0">
            <a href="../FeedConnect/index.html">
              <img className="object-scale-down h-12" src={logo} />
            </a>
          </div>
          <div className="w-1/2 pr-0">
            <div className="flex relative inline-block float-right">
              <div className="relative text-sm">
                <Menu>
                  <Menu.Button size="large" icon={"caret-down"}>
                    <Avatar size="xlarge" name={this.state.user.firstName + " " + this.state.user.lastName}>
                      <span className="hidden md:inline-block">
                        {this.state.user.firstName} {this.state.user.lastName}
                      </span>
                    </Avatar>
                  </Menu.Button>
                  <Menu.List>
                    <Menu.Item
                      icon="user"
                      onClick={() => {
                        window.location.href = '/profile'
                      }}
                    >
                      My Account
                    </Menu.Item>
                    <Menu.Item
                      icon="settings"
                      onClick={() => {
                        console.log("Admin panel");
                      }}
                    >
                      Admin Panel
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item
                      icon="log-out"
                      onClick={handleLogout}
                    >
                      Logout
                    </Menu.Item>
                  </Menu.List>
                </Menu>
              </div>
              <div className="block lg:hidden pr-4">
                <button id="nav-toggle"
                  className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-900 hover:border-teal-500 appearance-none focus:outline-none">
                  <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div
            className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-white z-20"
            id="nav-content"
          >
            <ul className="list-reset lg:flex flex-1 items-center px-4 md:px-0">
              <li className="mr-6 my-2 md:my-0">
                <Link
                  to="/"
                  className="flex items-end block py-1 md:py-3 pl-1 align-middle text-gray-500 hover:no-underline hover:text-gray-900 border-b-2 border-white hover:border-green-500"
                >
                  <Icon icon={'home'} className="mr-3" />
                  <span className="pb-1 md:pb-0 text-sm ">Home</span>
                </Link>
              </li>
              <li className="mr-6 my-2 md:my-0">
                <Link
                  to="/accounts"
                  className="flex items-end block py-1 md:py-3 pl-1 align-middle text-gray-500 hover:no-underline hover:text-gray-900 border-b-2 border-white hover:border-pink-500"
                >
                  <Icon icon={'properties'} className="mr-3" />
                  <span className="pb-1 md:pb-0 text-sm">Accounts</span>
                </Link>
              </li>
              <li className="mr-6 my-2 md:my-0">
                <Link
                  to="accounts/management"
                  className="flex items-end block py-1 md:py-3 pl-1 align-middle text-gray-500 hover:no-underline hover:text-gray-900 border-b-2 border-white hover:border-pink-500"
                >
                  <Icon icon={'properties'} className="mr-3" />
                  <span className="pb-1 md:pb-0 text-sm" onChange={this.changePath}>Accounts management</span>
                </Link>
              </li>

              <li className="mr-6 my-2 md:my-0">
                <Link
                  to="data/import"
                  className="flex items-end block py-1 md:py-3 pl-1 align-middle text-gray-500 hover:no-underline hover:text-gray-900 border-b-2 border-white hover:border-pink-500"
                >
                  <Icon icon={'th'} className="mr-3" />
                  <span className="pb-1 md:pb-0 text-sm">Data</span>
                </Link>
              </li>
              <li className="mr-6 my-2 md:my-0">
                <Link
                  to="data/import" 
                  className="flex items-end block py-1 md:py-3 pl-1 align-middle text-gray-500 hover:no-underline hover:text-gray-900 border-b-2 border-white hover:border-pink-500"
                >
                  <Icon icon={'th'} className="mr-3" />
                  <span className="pb-1 md:pb-0 text-sm">Zendesk-Details</span>
                </Link>
              </li>
              {/* <li className="mr-6 my-2 md:my-0">
                <a
                  href="../FeedConnect/reports.html"
                  className="block py-1 md:py-3 pl-1 align-middle text-gray-500 no-underline hover:text-gray-900 border-b-2 border-white hover:border-purple-500"
                >
                  <i className="fa fa-folder fa-fw mr-3"></i>
                  <span className="pb-1 md:pb-0 text-sm">Reports</span>
                </a>
              </li>
              <li className="mr-6 my-2 md:my-0">
                <a
                  href="../FeedConnect/analyticsx.html"
                  className="block py-1 md:py-3 pl-1 align-middle text-gray-500 no-underline hover:text-gray-900 border-b-2 border-white hover:border-green-500"
                >
                  <i className="fas fa-chart-area fa-fw mr-3"></i>
                  <span className="pb-1 md:pb-0 text-sm">Analytics</span>
                </a>
              </li> */}
            </ul>

            <div className="relative pull-right pl-4 pr-4 md:pr-0">
              {/* <input
                disabled
                type="search"
                placeholder="JCP-EN-US"
                className="w-full bg-gray-100 text-sm text-gray-800 transition border focus:outline-none focus:border-gray-700 rounded py-1 px-2 pl-10 appearance-none leading-normal"
              /> */}
              {/* <div
                className="absolute search-icon"
                style="top: 0.375rem;left: 1.75rem;"
              >
                <svg
                  className="fill-current pointer-events-none text-gray-800 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
                </svg>
              </div> */}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}


const handleLogout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem('user');
  window.location.href = '/'
}