
export default props => {
    const data = props.api.getDisplayedRowAtIndex(props.rowIndex).data || {};
    return (
        <div className="custom-tooltip whitespace-pre-line" style={{ backgroundColor: props.color || 'white' }}>
            <div className="whitespace-pre-line">
                {data.ticket.description}
            </div>
        </div>
    );
};