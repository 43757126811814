import React, { useState, useMemo, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import AgGridModal from '../AgGridModal';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Date from '../Date';
import ClientVendor from './client-vendor';

const Errors = (props) => {
    const gridRef = useRef();

    const [rowData] = useState(props.data);

    const defaultColDef = { resizable: true, flex: 1, autoHeight: true, filter: true, sortable: true };

    const getColumns = () => {
        if (props.data && props.data.length > 0) {
            let keys = Object.keys(props.data[0])
            let columns = [];
            //keys.filter(x => ["id", "queueKey", "type", "errorType", "activityName", "createdDate"].includes(x))
            ["id", "queueKey",
                //"type",
                "errorType", "activityName", "createdDate"]
                .forEach(key => {
                    let column = { field: key, cellClass: 'cell-wrap-text' }
                    switch (key) {
                        case "id": column = { ...column, headerName: 'Client / Partner', cellRenderer: ClientVendor, minWidth: 250 }; break;
                        case "queueKey": column = { ...column, headerName: 'Feed ID' }; break;
                        case "type": column = { ...column, maxWidth: 150 }; break;
                        case "errorType": column = { ...column, headerName: 'Stage', cellClass: 'capitalize' }; break;
                        case "activityName":
                            column = {
                                ...column,
                                headerName: 'Details',
                                cellRenderer: (params) => {
                                    const rowData = params.data;
                                    return rowData.activityName === "" ? rowData.pipelineName : rowData.activityName;
                                },
                            };
                            break;
                        case "createdDate": column = { ...column, headerName: 'Error Time (CT)', cellRenderer: Date }; break;

                        default:
                            break;
                    }
                    columns.push(column)
                });
            columns.push({ field: "link", cellRenderer: AgGridModal, maxWidth: 150 })
            return columns
        }
        return []
    };

    return (
        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
            <AgGridReact ref={gridRef} rowData={rowData} columnDefs={getColumns()} defaultColDef={defaultColDef} maintainColumnOrder={true}>
            </AgGridReact>
        </div>
    );
};

export default Errors