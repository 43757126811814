import { Icon, Skeleton, Stack, Statistic, Box, Layout, FormatNumber } from "@dentsu-ui/components"
const Metrics = (props) => {
    if (props.value.isNew) {
        const metrics = <div className="flex justify-center">
            <Box>
                <Stack className="pb-5">
                    <Statistic width={240}>
                        <Statistic.Label>
                            Total Accounts
                        </Statistic.Label>
                        <Statistic.Content value={{ current: props.value.accounts }} />
                    </Statistic>
                    <Statistic width={240}>
                        <Statistic.Label >
                            Total Partners
                        </Statistic.Label>
                        <Statistic.Content value={{ current: props.value.partners }} />
                    </Statistic>
                    <Statistic width={240}>
                        <Statistic.Label>
                            Total Items
                        </Statistic.Label>
                        <Statistic.Content value={{ current: props.value.totalItems }} />
                    </Statistic>
                </Stack>
                <Stack>
                    <Statistic width={240}>
                        <Statistic.Label >
                            Total Feeds
                        </Statistic.Label>
                        <Statistic.Content value={{ current: props.value.feeds }} />
                    </Statistic>
                    <Statistic width={240}>
                        <Statistic.Label >
                            Total Suppressed Items
                        </Statistic.Label>
                        <Statistic.Content value={{ current: props.value.totalSuppressedItems }} />
                    </Statistic>
                </Stack>
            </Box>
        </div>

        return <Layout>
            <Layout.Section>
                <Layout.Panel title="Market Overview" description="Market Overview Description" variant="darker">
                    {props.value.loading ? <Skeleton count={6} /> : metrics}
                </Layout.Panel>
            </Layout.Section>
        </Layout>
    }
    return <>
        <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                <div className="bg-white border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pr-4">
                            <div className="rounded p-3 bg-green-600"><Icon color={'#e2e8f0'} size={35} icon={'globe'}></Icon></div>
                        </div>
                        <div className="flex-1 text-right md:text-center">
                            <h5 className="font-bold uppercase text-gray-500">Total Accounts</h5>
                            <h3 className="font-bold text-3xl">{props.value.accounts} <span className="text-green-500"><i className="fas fa-caret-up"></i></span></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                <div className="bg-white border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pr-4">
                            <div className="rounded p-3 bg-orange-600"><Icon color={'#e2e8f0'} size={35} icon={'person'}></Icon></div>
                        </div>
                        <div className="flex-1 text-right md:text-center">
                            <h5 className="font-bold uppercase text-gray-500">Total Partners</h5>
                            <h3 className="font-bold text-3xl">{props.value.partners} <span className="text-orange-500"><i className="fas fa-exchange-alt"></i></span></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                <div className="bg-white border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pr-4">
                            <div className="rounded p-3 bg-yellow-600"><Icon color={'#e2e8f0'} size={35} icon={'tick'}></Icon></div>
                        </div>
                        <div className="flex-1 text-right md:text-center">
                            <h5 className="font-bold uppercase text-gray-500">Total Items</h5>
                            <h3 className="font-bold text-3xl">
                                <FormatNumber value={props.value.totalItems}></FormatNumber>
                                <span className="text-yellow-600"><i className="fas fa-caret-up"></i></span></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                <div className="bg-white border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pr-4">
                            <div className="rounded p-3 bg-blue-600"><Icon color={'#e2e8f0'} size={35} icon={'import'}></Icon></div>
                        </div>
                        <div className="flex-1 text-right md:text-center">
                            <h5 className="font-bold uppercase text-gray-500">Total Feeds</h5>
                            <h3 className="font-bold text-3xl">{props.value.feeds}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                <div className="bg-white border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pr-4">
                            <div className="rounded p-3 bg-indigo-600"><Icon color={'#e2e8f0'} size={35} icon={'grid-view'}></Icon></div>
                        </div>
                        <div className="flex-1 text-right md:text-center">
                            <h5 className="font-bold uppercase text-gray-500">Total Suppressed Items</h5>
                            <h3 className="font-bold text-3xl">{props.value.totalSuppressedItems}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default Metrics