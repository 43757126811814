import React from "react";
import Moment from 'react-moment';


export default class Time extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <Moment format="hh:mm:ss">{this.props.value}</Moment>
      </>
    );
  }
}
