import { Button } from "@dentsu-ui/components";
import React from "react";


class ViewAndDownload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        const openInNewTab = (props) => {
            window.open(props, '_blank', 'noopener,noreferrer');
        };
        let button;
        if (this.props.data.filePath != null) {
            button =
                <div>
                    <Button variant="tertiary" size="small" onClick={() => openInNewTab(this.props.data.filePath)}>View</Button>
                </div>
        }
        else {
            button =
                <div>
                    <Button variant="tertiary" size="small" isDisabled>View</Button>
                </div>
        }

        return (
            <div className="flex">
                  {button}
            </div>
        )
    }
}
export default ViewAndDownload
