import React from "react";
import { Loading, Page, Box, Layout, Switch,Dialog, FormField, Toast, Checkbox, TextInput, Select, Button, CheckboxGroup, Radio, DateInput, TimeInput, Icon, Stack, Caption, Divider, FormatNumber } from "@dentsu-ui/components";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import viewAndDownload from '../../components/management/viewAndDownload';
import Date from "../../components/Date";
import ThemeSwitch from "../../components/ThemeSwitch";
import RefreshContent from "../../components/RefreshContent";
import AccountService from "../../services/AccountService";
import ClientService from "../../services/clientService";
import banner from "../../assets/images/background/dentsu_website_headers_6.jpg"
import Feeds from "../../components/management/feeds";
import NewDeliveryMethod from "../../components/delivery/newDeliveryMethod";
import noImage from '../../assets/images/no-image.png'
import { secondaryAction } from "../../helper/secondaryAction";

const toast = Toast();

class Delivery extends React.Component {
    constructor(props) {
        super(props);
        this.selectStage = [
            { id: 2001, name: "FTP" },
            { id: 2002, name: "API" },
            { id: 2003, name: "InternalCopy" }
        ]
        this.state = {
            loading: false,
            updating: false,
            activeMode: false,
            accountsData: [],
            workDays: {},
            selectedDeliveryMethod: "",
            deliveryMethods: [],
            imports: [],
            exports: [],
            acountExport: [],
            acountImport: [],
            importOptions: [],
            activeMode: false,
            dialogOpen: false,
            userId: "",
            vendor: {},
            client: "",
            selectedMethod: "",
            exportFileName: "",
            accountName: "",
            accountFileName: [],
            vendrName: [],
            cadence: "",
            active: false,
            startTime: "",
            mon: false,
            tue: false,
            wed: false,
            thur: false,
            fri: false,
            sat: false,
            sun: false,
            options: [
                {
                    label: "Sun",
                    value: "one"
                },
                {
                    label: "Mon",
                    value: "two"
                },
                {
                    label: "Tue",
                    value: "three"
                },
                {
                    label: "Wed",
                    value: "four"
                },
                {
                    label: "Thurs",
                    value: "five"
                },
                {
                    label: "Fri",
                    value: "six"
                },
                {
                    label: "Sat",
                    value: "seven"
                }
            ],
            hours: [
                { value: 1, label: "Hours" },
                { value: 2, label: "Days" },
            ],

            rowData: [
                {
                    "file": "MARKET_DATAFEED_DELTA_01042021230422",
                    "totalCount": "1,326,253",
                    "fileType": "GZ",
                    date: new Date()
                },
                {
                    "file": "MARKET_DATAFEED_DELTA_01042021230422",
                    "totalCount": "1,326,253",
                    "fileType": "GZ"
                },
                {
                    "file": "MARKET_DATAFEED_DELTA_01042021230422",
                    "totalCount": "1,326,253",
                    "fileType": "GZ"
                },
            ],
            defaultColDef: {
                resizable: true, flex: 1, sortable: true
            },
        }
        document.title = `Accounts Management (${this.props.match.params.account})`

    }

    cellStyle = {
        'height': '100%',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center'
    }

    importColumnDefs = [
        { headerName: "Queue Key", field: 'queueKey' },
        { headerName: "Type", field: 'type' },
        { headerName: "Import Date (CT)", field: 'receivedTime', cellRenderer: Date, minWidth: 155,maxWidth: 200 },
        { headerName: "Total", field: 'importCount',maxWidth: 150, cellRenderer: FormatNumber },
        { cellRenderer: viewAndDownload,  minWidth: 70,maxWidth: 70, cellStyle: this.cellStyle }
    ]

    exportColumnDefs = [
        { headerName: "Queue Key", field: 'queueKey' },
        { headerName: "Type", field: 'type' },
        { headerName: "Export Date (CT)", field: 'deliveredTime', cellRenderer: Date, minWidth: 155,maxWidth: 200 },
        { headerName: "Total", field: 'deliveredCount', maxWidth: 150, cellRenderer: FormatNumber },
        { cellRenderer: viewAndDownload, maxWidth: 70, cellStyle: this.cellStyle }
    ]

    componentDidMount = async () => {
        this.loadData();
        if (this.props.match.params.account) {
            let updatedaccounts = await AccountService.getMarketAccounts(this.props.match.params.id);
            localStorage.setItem('Accounts_' + this.props.match.params.id, JSON.stringify(updatedaccounts.data))
            let accounts = localStorage.getItem('Accounts_' + this.props.match.params.id)
            accounts = JSON.parse(accounts)
            let vendor = (accounts.accounts.filter(x => x.id == this.props.match.params.account)[0] || { vendorId: 0 })
            this.setState({ vendor: vendor })
            this.setState({ activeMode: vendor.status == "True" ? true : false })
            this.setState({ vendrName: vendor.name })
        }
    }
    fecthAccounts = async (id) => {
        return Promise.all([
            //AccountService.getManagementAccount(id), 
            // ClientService.getVendors(),
            // AccountService.getAccountsMarkets(),
            ClientService.getDeliveryMethods(this.props.match.params.id, this.props.match.params.account),
            ClientService.acountExport(this.props.match.params.id, this.props.match.params.account),
            ClientService.acountImport(this.props.match.params.id),
            ClientService.getExportFileName(this.props.match.params.id, this.props.match.params.account)
        ]);
    }
    loadData = async () => {
        console.log(this.props.match.params)
        try {
            this.setState({ loading: true });
            let [deliveryMethods, acountExport, acountImport, fileName] = await this.fecthAccounts(this.props.match.params.account);
            // this.setState({ accountsData: accountsData.data });
            // this.setState({ accountName: accountsData.data.name });
            // this.setState({ active: accountsData.data.isActive });
            // this.setState({ cadence: accountsData.data.cadence });
            // this.setState({ startTime: accountsData.data.startTime });
            this.setState({ deliveryMethods: deliveryMethods.data })
            //this.weekDays(accountsData.data.dayOfWeek);
            this.setState({ acountImport: acountImport.data });
            this.setState({ acountExport: acountExport.data });
            this.setState({
                importOptions: acountImport.data.map(x => {
                    return { label: x.queueKey, value: x.queueKey, type: x.mappingKey };
                })
            });
            this.setState({
                accountFileName: fileName.data[0].exportFileName
            })

        }
        catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    }
    weekDays = (weekDays) => {
        let arr = weekDays.split(' ');
        if (arr[0] == 'M') {
            this.setState({ mon: true });
        }
        if (arr[0] == 'T') {
            this.setState({ tue: true });
        }
        if (arr[0] == 'W') {
            this.setState({ wed: true });
        }
        if (arr[0] == 'R') {
            this.setState({ thur: true });
        }
        if (arr[0] == 'F') {
            this.setState({ fri: true });
        }
        if (arr[0] == 'S') {
            this.setState({ sat: true });
        }
        if (arr[0] == 'U') {
            this.setState({ sun: true });
        }
    }

    handleSwitchChange = () => {
        this.setState({ dialogOpen: true });
      };

      handleDialogClose = (confirmed) => {
        console.log(confirmed)
        this.setState({ dialogOpen: false });
        if (confirmed) {
          this.updateStatus();
        } else {
          // Revert the switch state if not confirmed
          //this.setState((prevState) => ({ activeMode: !prevState.activeMode }));
        }
      };


    updateStatus = () => {
        this.setState((prevState) => {
            const activeMode = !prevState.activeMode;
            const ID = this.state.vendor.id;
            const clientId = this.props.match.params.id;

            this.state.vendor.status = activeMode ? 'True' : 'False';

            ClientService.updateStatus(ID, activeMode, clientId)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });

            return { activeMode };
        });
    };


    // ActiveOrInactive = () => {
    //     this.setState(
    //         prevState => {
    //             const activeMode = !prevState.activeMode ? true : false;
    //             let ID = this.state.vendor.id;
    //             let clientId = this.props.match.params.id;
    //             this.state.vendor.status = activeMode ? "True" : "False";
    //             ClientService.updateStatus(ID, activeMode, clientId)
    //                 .then((res) => {
    //                     console.log(res)
    //                 })
    //                 .catch((err) => {
    //                     console.log(err)
    //                 })
    //             return {
    //                 activeMode: !prevState.activeMode,
    //                 activeMode,
    //             };
    //         }
    //     )

    // }
    updateSunCheckbox = () => {
        this.setState({
            sun: !this.state.sun
        });
    }
    updateMonCheckbox = () => {
        this.setState({
            mon: !this.state.mon
        });
    }


    updateTueCheckbox = () => {
        this.setState({
            tue: !this.state.tue
        });
    }
    updateWedCheckbox = () => {
        this.setState({
            wed: !this.state.wed
        });
    }
    updateThurCheckbox = () => {
        this.setState({
            thur: !this.state.thur
        });
    }
    updateFriCheckbox = () => {
        this.setState({
            fri: !this.state.fri
        });
    }
    updateSatCheckbox = () => {
        this.setState({
            sat: !this.state.sat
        });
    }
    updateAccountName = (event) => {
        this.setState({ vendrName: event.target.value })
    };
    updateState = (event) => {
        this.setState({ accountFileName: event.target.value })
    };
    handleSaveClick = () => {
        const { vendrName, accountFileName } = this.state;
        const clientId = this.props.match.params.id;
        const accountKey = this.props.match.params.account;

        ClientService.updateAccountName(clientId, accountKey, vendrName, accountFileName)
            .then((res) => {
                // console.log(res)
                if (res.data.status == true) {
                    const updatedVendor = { ...this.state.vendor, name: vendrName };
                    this.setState({ vendor: updatedVendor })
                   //  console.log("after  " + this.state.vendor.name)
                    toast({ title: "Success", content: res.data.message, status: "success" })
                }

            })
            .catch((err) => {
                console.log(err)
            })        

    }
    updateCadence = (event) => {
        this.setState({
            Cadence: event.target.value,
        });
    };
    updateWeekDays = (event) => {
        this.setState({
            [this.state.workDays]: event.target.value
        });
        console.log(this.state.workDays);
    }
    saveDeliveryMethod(index) {
        console.log(this.state.deliveryMethods[index])
    };
    onChangeStage(name) {
        this.setState({ selectedDeliveryMethod: name });
    }
    onClickAddDeliveryMethod = () => {
        let fm = [...this.state.deliveryMethods, {}]
        this.setState({ deliveryMethods: fm })
    }
    render() {
        if (this.state.loading) {
            return (
                <>
                    <Box className="flex items-center justify-center" height="100%">
                        <Loading>Please wait while we are fetching the latest content</Loading>
                    </Box>
                </>
            );
        }
        const downloadLatestFeed = () => {
            ClientService.getLatestExport(this.props.match.params.id, this.props.match.params.account)
                .then((response) => {
                    if (response.data != null && response.data != "") {
                        let filepath;
                        filepath = response.data.filePath
                        window.open(filepath, '_blank', 'noopener,noreferrer');
                    } else {
                        toast({ title: "Error", content: "File is missing", status: "error" })
                    }
                }
                )
        }


        const addExport = async () => {
            let data = {
                ClientId: this.props.match.params.id,
                VendorKey: this.state.vendor.vendorId,
                AccountKey: this.props.match.params.account,
            }
            try {
                let response = await ClientService.addNewExport(data)
                if (response.data.item1 == true) {
                    toast({ title: "Success", content: response.data.item2, status: "success" })
                } else {
                    toast({ title: "Error", content: response.data.item2, status: "error" })
                }
            } catch (error) {
                toast({ title: "Error", content: error, status: "error" })
            }
        }
        const runImport = async (value, type) => {
            this.setState({ loading: true });
            let response = await AccountService.CopyLastedImportFiles(this.props.match.params.id, type, value);
            //console.log(response.data);
            if (response.data.status) {
                toast({ title: "Success", content: response.data.message, status: "success" })
            }
            else {
                toast({ title: "Error", content: response.data.message, status: "error" })

            }
            this.setState({ loading: false });

        };


        const checkstatus = this.state.vendor.status;

        const dialogTitle = "Status Change Alert";
        const dialogMessage = "Do you want to change, Please confirm?";

        const accountManagement = <div>
            <div className="px-5 flex flex-row flex-wrap flex-grow justify-between items-center space-x-2">
                <div className="pb-5">
                    <FormField label="Active/Inactive">
                        <div>

                            {/* {<Switch isChecked={this.state.activeMode} onChange={this.ActiveOrInactive} />} */}

                            <Switch isChecked={this.state.activeMode} onChange={this.handleSwitchChange} />

                            <Dialog
                                title={dialogTitle}
                                isOpen={this.state.dialogOpen}
                                onClose={() => this.handleDialogClose(false)}
                            >
                                <Dialog.Body>
                                    {dialogMessage}
                                </Dialog.Body>
                                <Dialog.Footer>
                                    <Button onClick={() => this.handleDialogClose(true)}>OK</Button>
                                    <Button variant="secondary" onClick={() => this.handleDialogClose(false)}>
                                        Cancel
                                    </Button>

                                </Dialog.Footer>
                            </Dialog>

                            {/* { <Switch isChecked={(checkstatus =="True")?true:false} onChange={() => onChangeStatus()}  /> }  */}

                            {/* {checkstatus =="True"? (<Switch  isChecked ={true} /> ) : (<Switch  isChecked ={false} />)} */}
                        </div>

                    </FormField>
                </div>
                <div>
                    <FormField label="Account Name" className="w-96">
                        <TextInput placeholder="Name"
                            value={this.state.vendrName}
                            //onChange={(event) => this.updateAccountName(event.target.value)}
                            onChange={this.updateAccountName}
                        />
                    </FormField>
                </div>
                <div>
                    <FormField label="Rollup">
                        <Select width={170} />
                    </FormField>
                </div>
            </div>
            <div className="flex flex-row flex-wrap flex-grow item-center justify-center mr-10">
                <FormField label="File Name" className="w-96">
                    <TextInput placeholder="Name"
                        value={this.state.accountFileName}
                        //  onChange={(event) => this.updateState(event.target.value)}
                        onChange={this.updateState}
                    />
                </FormField>
            </div><br />
            <div className="flex flex-row flex-wrap flex-grow item-center justify-center mr-10">
                <Select width={150} options={this.state.importOptions} onChange={(selectedOption) => this.setState({ selectedImportOption: selectedOption })} />
                <Button className="ml-5" variant="tertiary" size="small"
                    onClick={() => runImport(this.state.selectedImportOption.value, this.state.selectedImportOption.type)}
                >Run Import</Button> &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="tertiary" size="small" onClick={() => downloadLatestFeed()}>Download Feed</Button> &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="tertiary" size="small" disabled={(checkstatus == "True") ? false : true} onClick={() => addExport()}>Generate Export</Button>
            </div><br />
            <div className="flex justify-center">
                <div>
                    <FormField>
                        <div className="flex flex-row flex-wrap -space-x-8">
                            <FormField label="Delivery Dates"></FormField>
                            <div className="px-10">
                                <Radio name="group" value={""} label="All" />
                            </div>
                            <div>
                                <Checkbox id={this.state.sun} isChecked={this.state.sun} onChange={this.updateSunCheckbox}>Sun</Checkbox>
                            </div>
                            <div>
                                <Checkbox id={this.state.mon} isChecked={this.state.mon} onChange={this.updateMonCheckbox}>Mon</Checkbox>
                            </div>
                            <div>
                                <Checkbox id={this.state.tue} isChecked={this.state.tue} onChange={this.updateTueCheckbox}>Tue</Checkbox>
                            </div>
                            <div>
                                <Checkbox id={this.state.wed} isChecked={this.state.wed} onChange={this.updateWedCheckbox}>Wed</Checkbox>
                            </div>
                            <div>
                                <Checkbox id={this.state.thur} isChecked={this.state.thur} onChange={this.updateThurCheckbox}>Thur</Checkbox>
                            </div>
                            <div>
                                <Checkbox id={this.state.fri} isChecked={this.state.fri} onChange={this.updateFriCheckbox}>Fri</Checkbox>
                            </div>
                            <div>
                                <Checkbox id={this.state.sat} isChecked={this.state.sat} onChange={this.updateSatCheckbox}>Sat</Checkbox>
                            </div>
                        </div>
                    </FormField>
                </div>
            </div>
            <div className="flex justify-center">
                <div>
                    <FormField label="Start Time">
                        <DateInput placeholder="DD/MM/YYYY" value={this.state.startDate} parseDate={date => new Date(date)} formatDate={date => date.toLocaleDateString()} onChange={date => {
                            this.setState({ startTime: date });
                        }} />
                    </FormField>
                </div>
                <div className="py-8 px-2">
                    <TimeInput />
                </div>
                <div className="px-12">

                    <div className="flex">
                        <div>
                            <FormField label="Cadence">
                                <TextInput value={this.state.cadence || ''} onChange={this.updateCadence} />
                            </FormField>
                        </div>
                        <div className="px-3 py-6">
                            <Select
                                width={200}
                                customLabelKey="label"
                                customValueKey="value"
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                onChange={(option) => {
                                    this.setState({ cadence: option })
                                }}
                                options={this.state.hours}
                                value={this.state.cadence}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        const addIcon = <div className="flex justify-end content-center relative ">
            <div className="absolute top-0 right-0">
                <Icon size={35} icon={'add'} onClick={this.onClickAddDeliveryMethod} />
            </div>
        </div>

        let thumbnail = noImage
        try {
            thumbnail = require(`../../assets/images/client-logo/${this.props.match.params.id}.jpg`)
        } catch (error) {
            thumbnail = noImage
        }
        let metadata = this.props.match.params.id +
            (this.props.match.params.account
                ? (' - ' + this.state.vendor.name)
                : '')
        return (
            <>
                <Page title="Accounts Management"
                    thumbnail={thumbnail}
                    banner={banner}
                    metadata={metadata}
                    availableSpace={
                        <Stack alignItems="center">
                            <Caption as="span" isSecondary className="white">
                                <RefreshContent onClick={() => { this.loadData() }}></RefreshContent>
                            </Caption>
                            <Divider isFlexChild orientation="vertical" />
                            <Caption as="span" isSecondary className="white">
                                <ThemeSwitch>{this.props.theme}</ThemeSwitch>
                            </Caption>
                        </Stack>
                    }
                    secondaryActions={secondaryAction(this.props)}

                >
                    <br />
                    <Layout>
                        <Layout.Section>
                            <Layout.Panel title="Past Import Feeds" variant="darker">
                                <Feeds rowData={this.state.acountImport} columnDefs={this.importColumnDefs} defaultColDef={this.state.defaultColDef} />
                            </Layout.Panel>
                        </Layout.Section>
                        <Layout.Section>
                            <Layout.Panel title="Past Export Feeds" variant="darker">
                                <Feeds rowData={this.state.acountExport} columnDefs={this.exportColumnDefs} defaultColDef={this.state.defaultColDef} />
                            </Layout.Panel>
                        </Layout.Section>
                    </Layout>

                    <Layout>
                        <Layout.Section>
                            <Layout.Panel title="Accounts Management" variant="darker">
                                {accountManagement}
                                <div className="px-5 flex flex-row flex-wrap flex-grow justify-end items-center">
                                    <Button variant="tertiary" size="small" onClick={this.handleSaveClick}>Save</Button>
                                </div>
                            </Layout.Panel>
                        </Layout.Section>
                    </Layout>

                    <Layout>
                        <Layout.Section>
                            {
                                this.state.deliveryMethods.map((method, index) => {
                                    return <NewDeliveryMethod value={method} vendor={this.state.vendor} client={this.props.match.params.id} />
                                })
                            }
                        </Layout.Section>
                    </Layout>
                    <Layout>
                        <Layout.Section>
                            <Layout.Panel title="Delivery Method" variant="darker" actions={addIcon}>
                            </Layout.Panel>
                        </Layout.Section>
                    </Layout>
                </Page>
            </>
        )
    }
}

export default Delivery