import React from "react";
import { TextInput, Select, Icon, Button, Spacer, Image, FormGroup, FormField, FileInput } from "@dentsu-ui/components";
import ClientService from "../../services/clientService";
import fallbackImage from '../../assets/images/no-image.png'

class ClientOnboarding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            industry: "",
            name: "",
            symbol: "",
            pocName: "",
            pocEmail: "",
            technicalPocName: "",
            technicalPocEmail: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zipcode: "",
            vendors: [],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }
    fetchData = async () => {
        return Promise.all([
            ClientService.getVendors(),
        ])
    }
    loadData = async () => {
        try {
            this.setState({ loading: true });
            let [vendors] = await this.fetchData()
            this.setState({ vendors: vendors.data });
        }

        catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    }
    handleSubmit = () => {
        debugger
        console.log("Name " + this.state.name);
        console.log("symbol " + this.state.symbol);
        console.log("pocName " + this.state.pocName);
        console.log("pocEmail " + this.state.pocEmail);
        console.log("TechnicalPocName " + this.state.technicalPocName);
        console.log("TechnicalPocEmail " + this.state.technicalPocEmail);
    }
    getVendorLogo = (id) => {
        try {
            return require(`../../assets/images/vendor-logo/${id}.png`);
        } catch (error) {
            return fallbackImage
        }
    }
    render() {
        const industry =
            <div className="w-full">
                <label className="px-8 font-bold">Industry </label>
                <Select options={""}
                    maxMenuHeight="50"
                    menuPlacement='bottom'
                    width={398}
                    customLabelKey="name"
                    customValueKey="id"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    onChange={(selected, event) => {
                        this.onChangeState(selected.name)
                        this.setState({ stageName: selected });
                    }} value={this.state.Industry}
                />
            </div>
        const timeZone = <div>
            <label className="px-3 font-bold"> TimeZone </label>
            <Select options={""}
                maxMenuHeight="50"
                menuPlacement='bottom'
                width={398}
                customLabelKey="name"
                customValueKey="id"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.name}
                onChange={(selected, event) => {
                    this.onChangeState(selected.name)
                    this.setState({ stageName: selected });
                }} value={this.state.Industry}
            />
        </div>
        return (
            <div className="container w-full mx-auto pt-20">
                <div className="w-full px-4 md:px-0 md:mt-8 mb-16 text-gray-800 leading-normal">
                    <div className="flex flex-row flex-wrap flex-grow mt-2">
                        <div className="w-full p-3">
                            <div className="bg-white border rounded shadow">
                                <div className="border-b px-10 p-3 bg-[#60607d]">
                                    <h5 className="font-bold uppercase text-[#ffffff]">Client Setup</h5>
                                </div>
                                <FormGroup onSubmit={this.handleSubmit}>
                                    <div className="p-5 grid grid-rows-1 grid-flow-col gap-4 justify-around">
                                        <div>
                                            <FormField label="Name">
                                                <TextInput id={this.state.name} placeholder="Name" value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }) }} />
                                            </FormField>
                                            <FormField label="Symbol">
                                                <TextInput id={this.state.symbol} placeholder="symbol" value={this.state.symbol} onChange={(e) => { this.setState({ symbol: e.target.value }) }} />
                                            </FormField>
                                            <FormField label="POC Name">
                                                <TextInput id={this.state.pocName} placeholder="pco name" value={this.state.pocName} onChange={(e) => { this.setState({ pocName: e.target.value }) }} />
                                            </FormField>
                                            <FormField label="POC Email">
                                                <TextInput id={this.state.pocEmail} placeholder="poc Email" value={this.state.pocEmail} onChange={(e) => { this.setState({ pocEmail: e.target.value }) }} />
                                            </FormField>
                                        </div>
                                        <div>
                                            <FormField label="Technical POC Name">
                                                <TextInput id={this.state.technicalPocName} placeholder="technical poc name" value={this.state.technicalPocName} onChange={(e) => { this.setState({ technicalPocName: e.target.value }) }} />
                                            </FormField>
                                            <FormField label="Technical POC Email">
                                                <TextInput id={this.state.technicalPocEmail} placeholder="technical poc Email" value={this.state.technicalPocEmail} onChange={(e) => { this.setState({ technicalPocEmail: e.target.value }) }} />
                                            </FormField>
                                            <FormField label="Industy">
                                                <Select />
                                            </FormField>
                                        </div>
                                        <div>
                                            <label className="px-3">Logo Upload</label>
                                            <input type="file" />
                                            <Image className="h-10 w-15 px-3" src={""} fallbackSrc={fallbackImage}></Image>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap flex-grow mt-2">
                        <div className="w-full p-3">
                            <div className="bg-white border rounded shadow">
                                <div className="border-b px-10 p-3 bg-[#60607d]">
                                    <h5 className="font-bold uppercase text-[#ffffff]">Business Address</h5>
                                </div>
                                <FormGroup onSubmit={this.handleSubmit}>
                                    <div className="p-5 grid grid-rows-1 grid-flow-col gap-4 justify-around">
                                        <div>
                                            <FormField label="Address Line 1">
                                                <TextInput id={this.state.addressLine1} placeholder="Address Line 1" value={this.state.addressLine1} onChange={(e) => { this.setState({ addressLine1: e.target.value }) }} />
                                            </FormField>
                                            <FormField label="Address Line 2">
                                                <TextInput id={this.state.addressLine2} placeholder="Address Line 2" value={this.state.addressLine2} onChange={(e) => { this.setState({ addressLine2: e.target.value }) }} />
                                            </FormField>
                                            <FormField label="City">
                                                <TextInput id={this.state.city} placeholder="City" value={this.state.city} onChange={(e) => { this.setState({ city: e.target.value }) }} />
                                            </FormField>
                                            <FormField label="State">
                                                <TextInput id={this.state.state} placeholder="state" value={this.state.state} onChange={(e) => { this.setState({ state: e.target.value }) }} />
                                            </FormField>
                                        </div>
                                        <div>
                                            <FormField label="Zip Code">
                                                <TextInput id={this.state.zipcode} placeholder="zipcode" value={this.state.zipcode} onChange={(e) => { this.setState({ zipcode: e.target.value }) }} />
                                            </FormField>
                                            <FormField label="TimeZone">
                                                <Select />
                                            </FormField>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap flex-grow mt-2">
                        <div className="w-full p-3">
                            <div className="bg-white border rounded shadow">
                                <div className="border-b p-3 px-10 bg-[#60607d]">
                                    <h5 className="font-bold uppercase text-[#ffffff]">Targeted Countries & Languages</h5>
                                </div>
                                <div>
                                    <label className="font-bold px-10">Country of Sales:</label>
                                </div>
                                <FormGroup onSubmit={this.handleSubmit}>
                                    <div className="flex justify-center">
                                        <div>
                                            <label>Available Countries</label>
                                            <Select options={""} isMulti
                                                maxMenuHeight="50"
                                                menuPlacement='bottom'
                                                width={250}
                                                customLabelKey="name"
                                                customValueKey="id"
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.name}
                                                onChange={(selected, event) => {
                                                    this.onChangeState(selected.name)
                                                    this.setState({ stageName: selected });
                                                }} value={this.state.Industry}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                                <div className="px-10 font-bold">
                                    <label>Languages</label>
                                </div>
                                <FormGroup onSubmit={this.handleSubmit}>
                                    <div className="flex justify-around">
                                        <div className="py-3 underline">
                                            <label>Selected Markets</label>
                                        </div>
                                        <div className="py-3 underline">
                                            <label>Languages</label>
                                        </div>
                                    </div>
                                    <div className="flex justify-around w-full">
                                        <div className="">
                                            <FormField label="Country #1">
                                                <Select width={250} />
                                            </FormField>
                                        </div>
                                        <div className="py-5">
                                            <FormField label="">
                                                <Select width={250} />
                                            </FormField>
                                        </div>
                                    </div>
                                    <div className="flex justify-around w-full">
                                        <div>
                                            <FormField label="Country #2">
                                                <Select width={250} />
                                            </FormField>
                                        </div>
                                        <div className="py-5">
                                            <FormField label="">
                                                <Select width={250} />
                                            </FormField>
                                        </div>
                                    </div>
                                    <div className="flex justify-around w-full">
                                        <div>
                                            <FormField label="Country #3">
                                                <Select width={250} />
                                            </FormField>
                                        </div>
                                        <div className="py-5">
                                            <FormField label="">
                                                <Select width={250} />
                                            </FormField>
                                        </div>
                                    </div>
                                    <div className="flex justify-around w-full">
                                        <div>
                                            <FormField label="Country #4">
                                                <Select width={250} />
                                            </FormField>
                                        </div>
                                        <div className="py-5">
                                            <FormField label="">
                                                <Select width={250} />
                                            </FormField>
                                        </div>
                                    </div>
                                    <div className="flex justify-around w-full">
                                        <div>
                                            <FormField label="Country #5">
                                                <Select width={250} />
                                            </FormField>
                                        </div>
                                        <div className="py-5">
                                            <FormField label="">
                                                <Select width={250} />
                                            </FormField>
                                        </div>
                                    </div>
                                    <div className="flex justify-around w-full">
                                        <div>
                                            <FormField label="Country #6">
                                                <Select width={250} />
                                            </FormField>
                                        </div>
                                        <div className="py-5">
                                            <FormField label="">
                                                <Select width={250} />
                                            </FormField>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center p-3">
                        <div className="px-5">
                            <Button variant="secondary" type="submit" onSubmit={this.handleSubmit}>Save</Button>
                        </div>
                        <div className="px-5">
                            <Button variant="secondary">Cancel</Button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default ClientOnboarding