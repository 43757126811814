import React from "react";
import { Checkbox } from "@dentsu-ui/components";

class FCCheckbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        }
        this.HandleCheckbox = this.HandleCheckbox.bind(this);
    }

    HandleCheckbox = () => {
        let value = !this.state.value;
        this.setState({ value: value }, function (event) {
            let colId = this.props.column.colId;
            this.props.node.setDataValue(colId, this.state.value);
        });
    };
    render() {
        return (
            <div>
                <Checkbox size="small" isChecked={this.state.value} onChange={this.HandleCheckbox}>
                </Checkbox>
            </div>
        )
    }
}

export default FCCheckbox