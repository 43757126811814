import { useState } from 'react';
import { connect } from "react-redux";
import { Switch } from '@dentsu-ui/components';
import { setTheme } from "../store/reducers/themeSlice";


const ThemeSwitch = (props) => {
    const [value, setValue] = useState(false)
    const refresh = () => {
        if (value) {
            props.setTheme('standard')
            setValue(false)
        } else {
            props.setTheme('dentsu')
            setValue(true)
        }
    }
    return (
        <>
            <Switch isChecked={value} onChange={e => refresh()}>{props.children}</Switch>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme.value
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setTheme: (value) => dispatch(setTheme(value))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSwitch);