import React, { useState, useRef, useEffect } from 'react';
import { Layout, Loading, Box } from "@dentsu-ui/components"
import ViewRender from '../../ViewRender';
import HealthRender from '../../HealthRender';
import AgGridNumbers from '../../AgGridNumbers';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Date from '../../Date';
import AccountService from '../../../services/AccountService';

const Health = (props) => {
    const gridRef = useRef();
    const paginationPageSize = 100;
    const [rowData, setRawData] = useState([]);
    const [loadingProductCount, setLoadingProductCount] = useState(false)

    useEffect(() => {
        loadProductCounts(props.data)
    }, [])

    const loadProductCounts = async (items) => {
        setLoadingProductCount(true)
        let promises = []
        for await (let item of items) {
            let promise = Promise.all([
                AccountService.getMarketProductCount(item.id, 'Active'),
                AccountService.getMarketProductCount(item.id),
                AccountService.getMarketLastImport(item.id)
            ]).then(([activeProductCount, totalProductCount, lastImportProcessed]) => {
                item.products = [activeProductCount.data, totalProductCount.data]
                item.lastImportProcessed = lastImportProcessed.data
            })
            promises.push(promise)
        }

        Promise.all(promises).then(() => {
            setRawData(items)
            setLoadingProductCount(false)
        })
    }

    const cellStyle = {
        'height': '100%',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center'
    }
    const defaultColDef = { resizable: true, flex: 1, autoHeight: true }

    const getColumns = () => {
        if (props.data && props.data.length > 0) {
            let keys = Object.keys(props.data[0])
            let columns = []
            keys.filter(x => ["view", "market", "health", "accounts", "products", "lastImportProcessed", "nextScheduledImport"].includes(x))
                .forEach(key => {
                    let column = { field: key, cellClass: 'cell-wrap-text' }
                    switch (key) {
                        case "view":
                            column = { ...column, headerName: "View", field: 'view', cellRenderer: ViewRender, cellStyle: cellStyle, maxWidth: 100 }; break;
                        case "market":
                            column = { ...column, headerName: "Market", field: 'market', filter : true , sortable: true}; break;
                        case "health":
                            column = { ...column, headerName: "Health", field: 'health', cellRenderer: HealthRender, cellStyle: cellStyle, maxWidth: 90 }; break;
                        case "accounts":
                            column = { ...column, headerName: '# of Accounts', field: 'accounts', minWidth: 80 ,filter : true , sortable: true}; break;
                        case "products":
                            column = { ...column, headerName: 'Item Count', field: 'products', cellRenderer: AgGridNumbers ,filter : true , sortable: true }; break;
                        case "lastImportProcessed":
                            column = { ...column, headerName: 'Last Import Processed (CT)', field: 'lastImportProcessed', cellRenderer: Date ,filter : true ,sortable: true }; break;
                        case "nextScheduledImport":
                            column = { ...column, headerName: 'Next Scheduled Import (CT)', field: 'nextScheduledImport', cellRenderer: Date, minWidth: 175 ,filter : true , sortable: true }; break;
                        default:
                            break;
                    }
                    columns.push(column)
                });
            return columns
        }
        return []
    };

    const agGrid = <div style={{ height: 400, width: '100%' }} className="ag-theme-alpine">
        {loadingProductCount
            ? <>
                <Box className="flex items-center justify-center" height="100%">
                    <Loading>Loading...</Loading>
                </Box>
            </>

            : <AgGridReact ref={gridRef} rowData={rowData}
                columnDefs={getColumns()} defaultColDef={defaultColDef}
                pagination={true}
                loadingOverlayComponent={Loading}
                rowSelection={'multiple'}
                paginationPageSize={paginationPageSize}
            >
            </AgGridReact>
        }
    </div>

    if (props.value.isNew) {
        return <>
            <Layout>
                <Layout.Section>
                    <Layout.Panel title="Market Health" description="Market Health Description" variant="darker">
                        <div style={{ width: '100%', height: '450px' }}>
                            {props.value.selectedClient ? agGrid : ''}
                        </div>
                    </Layout.Panel>
                </Layout.Section>
            </Layout>
        </>
    }
    return <>
        <div className="w-full p-3">
            <div className="bg-white border rounded shadow h-full">
                <div className="border-b p-3 bg-[#60607d]">
                    <h5 className="font-bold uppercase text-[#ffffff]">Market Health</h5>
                </div>
                <div className="w-full p-5">
                    <div style={{ width: '100%', height: '450px' }}>
                        {props.value.selectedClient ? agGrid : ''}
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default Health
