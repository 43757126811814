import { createSlice } from '@reduxjs/toolkit'

export const vendorsSlice = createSlice({
    name: 'vendors',
    initialState: {
        value: [],
    },
    reducers: {
        addVendors: (state, action) => {
            state.value = action.payload
        }
    },
})

export const { addVendors } = vendorsSlice.actions

export const getVendors = (state) => state.vendors.value

export default vendorsSlice.reducer
