import axios from "axios";
import { Toast } from '@dentsu-ui/components';


const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const toast = Toast();

// Interceptor
// Will be called before each request
// This will get the jwt token from local storage and add it as a header in the request
const requestHandler = (request) => {
  request.headers.Authorization = "Bearer " + localStorage.getItem("token");
  return request;
};

const responseHandler = (response) => {
  if (response.status === 401) {
    window.location = "/login";
  }
  return response;
};

const errorHandler = (error) => {
  return Promise.reject(error);
};

http.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

http.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      window.location.reload()
    } else if (error.response.status === 500) {
      toast({
        status: 'error',
        title: "Error",
        content: error.response.data.Message,
        duration: 10000
      })
    }
    else if (error.response.status === 400) {
      toast({
        status: 'error',
        title: "Error",
        content: error.response.data.message,
        duration: 10000
      })
    }
    return error
  }
);

export default http;
