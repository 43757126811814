import http from "../helper/http-common";
import AuthService from "./authService";

export default class RuleService {
    static getRules(market, type) {
        return http.get(`rules`, {
            params: { market, type },
            headers: { Authorization: `bearer ${AuthService.getToken()}` },
        }).catch(function (error) {
            return Promise.reject(error)
        });
    }
    static RuleDetails(marketId,ruleKey) {
        return http.get(`ruledetails`, {
            params: { marketId,ruleKey },
            headers: { Authorization: `bearer ${AuthService.getToken()}` },
        }).catch(function (error) {
            return Promise.reject(error)
        });
    }
    static addRule(rule) {
        return http.post(`rules`, {
            ...rule,
            headers: { Authorization: `bearer ${AuthService.getToken()}` },
        }).catch(function (error) {
            return Promise.reject(error)
        });
    }

    static updateRule(rule) {
        return http.put(`rules`, {
            ...rule,
            headers: { Authorization: `bearer ${AuthService.getToken()}` },
        }).catch(function (error) {
            return Promise.reject(error)
        });
    }

    static getAffectedProducts(params) {
        return http.get(`rules/affected`, {
            params,
            headers: { Authorization: `bearer ${AuthService.getToken()}` },
        }).catch(function (error) {
            return Promise.reject(error)
        });
    }
}