import React from "react";
import http from "../helper/http-common";
import AuthService from "./authService";
const cachedPromises = {}
export default class AccountService extends React.Component {


  static getAccountmetrics(clientId, marketId) {
    return http
      .get(`accounts/metrics`, {
        params: {
          client: clientId,
          market: marketId
        },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }
  static getAccountImports(clientId, marketId) {
    let user = AuthService.getUser();
    return http
      .get(`accounts/importqueue`, {
        params: {
          userid: user.id,
          client: clientId,
          market: marketId
        },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }
  static getAccountsMarkets() {
    let user = AuthService.getUser();
    return http
      .get(`markets`, { headers: { Authorization: `bearer ${AuthService.getToken()}` }, })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }
  static getAccounts() {
    return http
      .get(`accounts`, { headers: { Authorization: `bearer ${AuthService.getToken()}` }, })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getMarketAccounts(marketId) {
    if (!cachedPromises.getMarketAccounts) {
      cachedPromises.getMarketAccounts = http
        .get(`market/accounts`, {
          params: { market: marketId },
          headers: { Authorization: `bearer ${AuthService.getToken()}` },
        })
        .catch(function (error) {
          return Promise.reject(error)
        });
    }
    return cachedPromises.getMarketAccounts
  }

  static getClientMarkets(client) {
    return http
      .get(`accounts/${client}/markets`, {
        params: { client: client },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getMarketsActivityLog(client) {
    return http
      .get(`accounts/${client}/marketactivitylog`, {
        params: { client: client },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getAccountActivityLog(client) {
    return http
      .get(`accounts/${client}/accountactivitylog`, {
        params: { client: client },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getAccountExports(clientId, marketId) {
    let user = AuthService.getUser();
    return http
      .get(`accounts/exportqueue`, {
        params: {
          userid: user.id,
          client: clientId,
          market: marketId
        },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getAccountHistoricalImports(clientId, marketId) {
    let user = AuthService.getUser();
    return http
      .get(`accounts/historical/imports`, {
        params: {
          userid: user.id,
          client: clientId,
          market: marketId
        },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getAccountHistoricalExports(clientId, marketId) {
    let user = AuthService.getUser();
    return http
      .get(`accounts/historical/exports`, {
        params: {
          userid: user.id,
          client: clientId,
          market: marketId
        },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getAccountsErrors(clientId, marketId) {
    let user = AuthService.getUser();
    return http
      .get(`home/errors`, {
        params: {
          userid: user.id,
          client: clientId,
          market: marketId
        },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }
  static getImportRawProductFields() {
    return http
      .get(`accounts/fcfields`, {
        params: {},
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getVendorFields(id) {
    return http.get(`vendors/${id}/fields`, {
      params: {},
      headers: { Authorization: `bearer ${AuthService.getToken()}` },
    }).catch(function (error) {
      return Promise.reject(error)
    });
  }


  static getProductImport(client, market, startDate, endDate, parameters,importQueueKey, isDownload) {
    return http
      .get(`products-raw`, {
        params: { client, market, startDate, endDate, parameters, importQueueKey,isDownload },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }
  
  static getRawProductsforPreview(client, market, startDate, endDate, parameters) {
    return http
      .get(`products-raw-SuppressedPreview`, {
        params: { client, market, startDate, endDate, parameters },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }
  static getProductTransformed(client, market, startDate, endDate,parameters,importQueueKey, isDownload) {
    return http
      .get(`products-transformed`, {
        params: { client, market, startDate, endDate,parameters,importQueueKey,isDownload },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }
  static getProductHistorical(client, market, startDate, endDate, parameters) {
    return http
      .get(`products-historical`, {
        params: { client, market, startDate, endDate, parameters },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }
  static getProductExport(client, market, vendor, startDate, endDate, parameters,importQueueKey, isDownload) {
    return http
      .get(`products-export`, {
        params: { client, market, vendor, startDate, endDate, parameters, importQueueKey,isDownload },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }
  static GetImportRunTime(clientId, startDate, endDate,stage) {
    return http
      .get(`Products/${clientId}/GetImportRunTime/${startDate}/${endDate}/${stage}`, {
      
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }
  static getManagementAccount(accountId) {
    return http
      .get(`accounts/${accountId}`, {
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static updateAccount(id, name, vendor, client, isActive, dayOfWeek, startTime, cadence, exportFileName) {
    return http
      .put(`accounts`,
        {
          id: id,
          name: name,
          vendor: vendor,
          client: client,
          isActive: isActive,
          dayOfWeek: dayOfWeek,
          startTime: startTime,
          cadence: cadence,
          exportFileName: exportFileName
        },
        { Authorization: `bearer ${AuthService.getToken()}` },
      )
      .catch(function (error) {
        return Promise.reject(error)
      })
  }

  static getGoogleTaxonomy() {
    if (!cachedPromises.taxonomy) {
      cachedPromises.taxonomy = http
        .get(`taxonomy`, { headers: { Authorization: `bearer ${AuthService.getToken()}` }, })
        .catch(function (error) {
          return Promise.reject(error)
        });
    }
    return cachedPromises.taxonomy
  }

  static updateCategoryMapping(clientId, mapping) {
    return http.put(`accounts/${clientId}/categorymapping`,
      {
        mapping
      },
      { Authorization: `bearer ${AuthService.getToken()}` },
    )
      .catch(function (error) {
        return Promise.reject(error)
      })
  }

  static getAffectedCountPreview(clientId, mapping,RunFor) {
    return http.put(`accounts/${clientId}/AffectedCountPreview/${RunFor}`,
      {
        mapping
      },
      { Authorization: `bearer ${AuthService.getToken()}` },
    )
      .catch(function (error) {
        return Promise.reject(error)
      })
  }
  static getMarketProductCount(client, productType = 'Total') {
    return http
      .get(`market/${client}/productcount`, {
        params: { productType },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getMarketLastImport(client) {
    return http
      .get(`market/${client}/lastimport`, {
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getImportCategories(client) {
    return http
      .get(`accounts/${client}/importcategories`, {
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getCategoryMappings(clientId,parameters,searchvalue) {
    return http
        .get(`accounts/${clientId}/GetCategoryMappings`, {
            params: { parameters },
            headers: { Authorization: `bearer ${AuthService.getToken()}` },
        })
        .catch(function (error) {
            return Promise.reject(error)
        });
  }

  static deleteCategoryMappings(marketId, categoryReferenceId) {
    return http
      .delete(`accounts/${marketId}/DeleteCategoryMapping/${categoryReferenceId}`, {
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static CopyLastedImportFiles(client,mappingKey,queueKey) {
    return http
      .get(`market/${client}/CopyLastedImportFiles/${mappingKey}/${queueKey}`, {
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }
}