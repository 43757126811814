
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Modal, Box, Button, Caption, Layout, Textarea } from "@dentsu-ui/components";
import moment from 'moment-timezone'

export default class ViewLogicDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rule: [],
        }

    }


    handleOpenModal = async () => {
        this.setState({ showModal: true });
    }
    handleCloseModal = () => {
        this.setState({ showModal: false });
    }

    render() {

        const moment = require('moment');
        let timestamp = this.props.data.CreatedDate;
        let date = moment.utc(timestamp);
        date = date.tz("America/Chicago");
        let cstDate = date.format("YYYY-MM-DD HH:mm:ss z");
        let vendorKey = this.props.data.VendorKey != null ? this.props.data.VendorKey : "0"

        let dataForm =
            <Layout>
                <Layout.Section>
                    <Layout.Panel hasBorder variant="darker">
                        <Box className="justifyContent center">
                            <div className="flex flex-row space-x-8 mb-5 mt-2">
                                <div className='w-full'>
                                    <Caption>Rule #</Caption>
                                    {this.props.data.RuleKey}
                                </div>
                                <div className='w-full'>
                                    <Caption>Client</Caption>
                                    {this.props.data.ClientId}
                                </div>
                                <div className='w-full'>
                                    <Caption>Partner</Caption>
                                    {vendorKey}
                                </div>

                                <div className='w-full'>
                                    <Caption>Status</Caption>
                                    {this.props.data.IsEnabled ? "Active" : "Inactive"}
                                </div>
                                <div className='w-full'>
                                    <Caption>Sort Index</Caption>
                                    {this.props.data.SortIndex}
                                </div>
                            </div>
                            <hr />
                            <div className="flex flex-row space-x-8 mb-5  mt-2">
                                <div className='w-full'>
                                    <Caption>Task</Caption>
                                    {this.props.data.Task}
                                </div>
                                <div className='w-full'>
                                    <Caption>Type</Caption>
                                    {this.props.data.Type}
                                </div>
                                <div className='w-full'>
                                    <Caption>Ticket ID</Caption>
                                    {this.props.data.TicketId}
                                </div>
                                <div className='w-full'>
                                    <Caption>Target Field</Caption>
                                    {this.props.data.Category}
                                </div>
                            </div>
                            <hr />
                            <div className="flex flex-row space-x-8 mb-5  mt-2">
                                <div className='w-full'>
                                    <Caption>Created Date (CT)</Caption>
                                    {cstDate}
                                </div>
                                <div className='w-full'>
                                    <Caption>Comment</Caption>
                                    {this.props.data.Comment}
                                </div>
                            </div>
                            <hr />
                            <div className="flex flex-row space-x-8 mb-5  mt-2">
                            </div>
                            <hr />
                            <div className='w-full'>
                                <Caption>Sql Statement</Caption>
                                <Textarea isReadOnly isFullWidth resize="None">
                                    {this.props.data.SqlStatement}
                                </Textarea>
                            </div>
                        </Box>
                    </Layout.Panel>
                </Layout.Section>
            </Layout>
        return (
            <Box className="flex items-center justify-start cursor-pointer" height="100%">
                <FontAwesomeIcon size="2x" className="fa-solid fa-eye" icon={faEye} onClick={() => { this.handleOpenModal(); }} onChange={this.onChangeStatus} />
                <Modal isOpen={this.state.showModal} onClose={this.handleCloseModal}>
                    <Modal.Header hasCloseButton={true}
                    />
                    <Modal.Body>
                        <div>
                            {dataForm}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCloseModal}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </Box>
        )
    }
}
