import React from "react";
import http from "../../helper/http-common";
import AuthService from "../authService";

export default class UserService extends React.Component {
    static getAllClients() {
        return http
            .get(`clients/all`, {
                headers: { Authorization: `bearer ${AuthService.getToken()}` },
            })
            .catch(function (error) {
                return Promise.reject(error)
            });
    }
    static getAllClientsAndMarkets() {
        return http
            .get(`markets/all`, { headers: { Authorization: `bearer ${AuthService.getToken()}` }, })
            .catch(function (error) {
                return Promise.reject(error)
            });
    }
    static getAllUsers() {
        return http
            .get(`users`, {
                headers: { Authorization: `bearer ${AuthService.getToken()}` },
            })
            .catch(function (error) {
                return Promise.reject(error)
            });
    }
    static updateUser(id, firstName, lastName, email, team, role, clients, title, networkLogin, channel, isDisabled) {
        return http
            .put(`users`,
                {
                    id, email, firstName, lastName, role, team, isDisabled: isDisabled, clients, title, networkLogin, channel
                },
                { Authorization: `bearer ${AuthService.getToken()}` },
            )
            .catch(function (error) {
                return Promise.reject(error)
            })
    }

    static updateUserstatus(id, isDisabled) {
        return http
            .put(`usersstatus`,
                {
                    id, isDisabled: isDisabled
                },
                { Authorization: `bearer ${AuthService.getToken()}` },
            )
            .catch(function (error) {
                return Promise.reject(error)
            })
    }
    static getUserRoles() {
        return [
            { id: 1, name: "Admin" },
            { id: 2, name: "User" },
            { id: 3, name: "Channel" },
        ]
    }
}