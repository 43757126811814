import React from 'react';
import { FormatNumber } from '@dentsu-ui/components';

const AgGridNumbers = (props) => {
    return (
        <>
            {props.value.map((number, index) => {
                return <span>
                    <FormatNumber value={number} />
                    {index != props.value.length - 1 ? ' / ' : ''}
                </span>
            })}
        </>
    )
}
export default AgGridNumbers