import React from "react";
import { connect } from "react-redux";
import { Select, Loading, Statistic, Stack, Page, Caption, Box, Layout, Skeleton, Divider,Card,Image,thumbnailImage,Link,Chip } from "@dentsu-ui/components";
import AccountService from "../../services/AccountService";
import ClientService from "../../services/clientService";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import thumbnail from "../../assets/images/FC-Logo-Icon.png"
import banner from "../../assets/images/background/dentsu_website_headers_1.jpg"
import ViewRender from "../../components/ViewRender";
import HealthRender from "../../components/HealthRender"
import RefreshContent from "../../components/RefreshContent";
import Date from "../../components/Date";
import AgGridNumbers from "../../components/AgGridNumbers";
import ThemeSwitch from "../../components/ThemeSwitch";
import Metrics from "../../components/account/markets/metrics";
import Health from "../../components/account/markets/health";
import colors from "../../helper/colors";
import { secondaryAction } from '../../helper/secondaryAction'
//import { Col, Container, Row } from 'react-bootstrap';
class Markets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            stageName: "",
            clients: [],
            markets: [],
            activitylog:[],
            rowData: null,
            metadata: [],
            selectedClient: "",
            rowData: [],
            total: 30,
            pageIndex: 0,
            selectedSearch: '',
            selectedFilters: [],
            showEmptyState: false,
            perPage: 0,
            defaultColDef: { flex: 1, resizable: true, sortable: true },
            rowBuffer: 10,
            rowSelection: 'multiple',
            rowModelType: 'infinite',
            cacheBlockSize: 10,
            cacheOverflowSize: 2,
            maxConcurrentDatasourceRequests: 2,
            infiniteInitialRowCount: 2,
            maxBlocksInCache: 2,
            limit: 100,
            paginationPageSize: 100,
            loadingOverlayComponent: Loading,
            loadingOverlayComponentParams: {
                children: 'One moment please...',
            },
            filters: {}
        }
        document.title = 'Markets'
    }
    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        try {
            this.setState({ loading: true });
            let clients = await ClientService.getClients()
            this.setState({ clients: clients.data });
            this.setState({ selectedClients: this.state.clients })
        }
        catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    }

    async onChangeClient(id,clientName) {
        document.title = `Markets (${clientName})`
        await this.fecthData(id)
        await this.fecthActivityLogData(id)
        //setTimeout(() => { this.gridApi.sizeColumnsToFit() }, 500);
    }

    fecthData = async (client) => {
        try {
            this.setState({ loading: true });
            let response = await AccountService.getClientMarkets(client)
            this.setState({ metadata: response.data.metadata });
            this.setState({ markets: response.data.markets });
            this.setRowData()
            return response
        }
        catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    }

    fecthActivityLogData = async (client) => {
      try {
          this.setState({ loading: true });
          let response = await AccountService.getMarketsActivityLog(client)
          this.setState({ activitylog: response.data});
          if(response.data.length>0)
                this.setState({ isActivityDataAvailable: true});
            else
                this.setState({ isActivityDataAvailable: false});
          return response
      }
      catch (error) {
      } finally {
          this.setState({ loading: false });
      }
  }

    setRowData() {
        if (this.state.markets && this.state.markets.length > 0) {
            let rowData = []
            this.state.markets.forEach(market => {
                rowData.push({
                    view: market,
                    market: market.name,
                    health: market.status != 'Active' ? colors.GREY : market.totalAccounts == 0 ? colors.YELLOW : colors.GREEN,
                    accounts: market.activeAccounts + "/" + market.totalAccounts,
                    products: [market.activeProducts, market.totalProducts],
                    lastImportProcessed: market.lastImportProcessed,
                    nextScheduledImport: market.nextScheduledImport,
                    id: market.id
                })
            });
            this.setState({ rowData: rowData })
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <>
                    <Box className="flex items-center justify-center" height="100%">
                        <Loading>Please wait while we are fetching the latest content</Loading>
                    </Box>
                </>
            );
        }
        const clientSelection = <div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center pb-4 pt-4">
            <label>Client </label>
            <Select options={this.state.clients}
                maxMenuHeight="50"
                menuPlacement='bottom'
                width={200}
                customLabelKey="name"
                customValueKey="id"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(selected, event) => {
                    this.onChangeClient(selected.id,selected.clientName)
                    this.setState({ selectedClient: selected });
                }} value={this.state.selectedClient} />
        </div>

        const results = [];
        this.state.activitylog.forEach((activity, index) => {
          //console.log("In Foreach loop - "+JSON.stringify(activity))
        results.push(        
            <Card variant="functional" style={{border:'1px solid black'}}>            
              <Card.Description>
                <div style={{fontWeight:'600',paddingBottom:'6px', marginTop:'-18px'}}>
                  {activity.title} - {activity.logCreationDate}
                </div>
              </Card.Description>
              <hr style={{border:'none',height:'2px',color:'#333',backgroundColor:'#333'}}></hr>
              <Card.Description>
                <div style={{fontWeight:'500', marginTop:'6px'}}>
                  {activity.message}
                </div>
              </Card.Description>            
            </Card>,
          );
        });

        const ActivityLog=
        <div className="bg-white border rounded shadow h-full">
            <div className="border-b p-3 bg-[#60607d]">
                <h5 className="font-bold uppercase text-[#ffffff]">Activity Log</h5>
            </div>
            <div className="w-full p-5" style={{height: '500px', overflowY: 'scroll'}}>   
                  <Card.Group cols={3} spacing={16}>   
                      {results}                 
                  </Card.Group>
                  {
                        this.state.isActivityDataAvailable==false? <div style={{textAlign:'center', marginTop:'200px'}}>No Data To Show</div>:''
                  }  
          </div>
        </div>
           
        const formatter = new Intl.NumberFormat('en-US');
        return (
            <Page title="Markets"
                thumbnail={thumbnail}
                banner={banner}
                availableSpace={
                    <Stack alignItems="center">
                        <Caption as="span" isSecondary className="white">
                            <RefreshContent onClick={() => { this.loadData() }}></RefreshContent>
                        </Caption>
                        <Divider isFlexChild orientation="vertical" />
                        <Caption as="span" isSecondary className="white">
                            <ThemeSwitch>{this.props.theme}</ThemeSwitch>
                        </Caption>
                    </Stack>
                }
                secondaryActions={secondaryAction(this.props)}

            >
                <Layout>
                    <Layout.Section>
                        {clientSelection}
                    </Layout.Section>
                </Layout>

                <Metrics value={{
                    isNew: this.props.theme == 'dentsu',
                    loading: this.state.loading,
                    markets: isNaN(this.state.metadata.markets)?" ":(formatter.format(this.state.metadata.markets)),
                    partners: isNaN(this.state.metadata.partners)?" ":(formatter.format(this.state.metadata.partners)),
                    totalItems: isNaN(this.state.metadata.totalItems)?" ":(formatter.format(this.state.metadata.totalItems)),
                    feeds: isNaN(this.state.metadata.feeds)?" ":(formatter.format(this.state.metadata.feeds)),
                    accounts:isNaN(this.state.metadata.accounts)?" ":(formatter.format(this.state.metadata.accounts)),
                    totalSuppressedItems:isNaN(this.state.metadata.totalSuppressedItems)?" ":(formatter.format(this.state.metadata.totalSuppressedItems)) 
                }}></Metrics>

                <Layout>
                <Layout.Section>
                    <Layout.Panel>
                                {ActivityLog}                                
                            </Layout.Panel>
                        </Layout.Section>
                </Layout>

                <Health value={{
                    isNew: this.props.theme == 'dentsu',
                    loading: this.state.loading,
                    selectedClient: this.state.selectedClient,
                }}
                    data={this.state.rowData}>
                </Health>

            </Page>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme.value
    }
}

export default connect(mapStateToProps)(Markets);