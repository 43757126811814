export const secondaryAction = (props) => {

    function onPageChange(path, temp) {
        temp.history.push(path);
    }
    const secondaryActions = [
        { content: 'My Account', onClick: () => console.log('My Account'), icon: 'user' },
        {
            // content: 'Admin Panel', icon: 'settings', onClick: () => { onPageChange('/admin/user-mgmt', props) }
            content: 'Admin Panel', icon: 'settings', onClick: () => console.log('user management') 
        },
        { isDivider: true },
        {
            content: 'Logout', icon: 'log-out', onClick: () => {
                localStorage.removeItem("token");
                localStorage.removeItem('user');
                window.location.href = '/'
            }
        }
    ]

    return secondaryActions

}













