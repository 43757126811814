import { useEffect, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from 'moment';
import { Page, Layout } from '@dentsu-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import UserService from '../../services/admin/UserService';
import Status from '../../components/Status';
import TooltipClients from '../../components/admin/tooltipClients';
import banner from "../../assets/images/background/dentsu_website_headers_12.jpg"
import thumbnail from "../../assets/images/FC-Logo-Icon.png"
import { secondaryAction } from '../../helper/secondaryAction';
const Users = (props) => {
    const [users, setUsers] = useState([])
    const gridRef = useRef();
    const defaultColDef = { resizable: true, flex: 1, filter: true, sortable: true, autoHeight: true, minWidth: 100, tooltipComponent: TooltipClients };

    useEffect(() => {
        document.title = `Users`
        async function getUsers() {
            gridRef.current.api.showLoadingOverlay();
            let usersData = await UserService.getAllUsers()
            let clientsData = await UserService.getAllClients()
            let userRoles = UserService.getUserRoles()
            usersData.data.forEach(user => {
                user.role = (userRoles.filter(x => x.id == user.role)[0] || { name: user.role }).name
                user.clientNames = clientsData.data.filter(x => user.clients.indexOf(x.id) > -1).map(x => x.name + ': ' + x.id) || []
            });
            setUsers(usersData.data)
            gridRef.current.api.hideOverlay();
        }
        setTimeout(() => { getUsers() }, 1000);
    }, [])

    const getColumns = () => {
        if (users && users.length > 0) {
            let keys = Object.keys(users[0])
            let columns = [];
            ['firstName', 'lastName', 'email', 'title', 'team', 'channel', 'isDisabled', 'role', 'clients'].forEach(key => {
                let column = { field: key, cellClass: 'cell-wrap-text' }
                switch (key) {
                    case "isDisabled":
                        column = { ...column, headerName: 'Status', cellClass: 'capitalize', 
                        cellRenderer: params => {
                            return params.value && (params.value == true || params.value.toLowerCase() == 'true') ? 'Inactive' : 'Active'
                        } };
                        break;
                    case "clients":
                        column = {
                            ...column, minWidth: 250, cellStyle: { 'white-space': 'normal' },
                            tooltipField: 'clientNames',
                        };
                        break;
                    default:
                        break;
                }
                columns.push(column)
            });
            return columns
        }
        return []
    };

    const exportDataAsCsv = () => {
        let fileName = 'Users-' + moment().format('MMDDyyyy') + '.csv'
        if (gridRef) gridRef.current.api.exportDataAsCsv({ fileName });
    }

    return <>
        <Page title="Users"
            thumbnail={thumbnail}
            banner={banner}
            secondaryActions={secondaryAction(props)}
   
        >
            <br />
            <Layout>
                <Layout.Section>
                    <div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center pb-4" onClick={exportDataAsCsv}>
                        <FontAwesomeIcon size="2x" className="cursor-pointer" icon={faDownload} />
                    </div>
                </Layout.Section>
            </Layout>

            <Layout>
                <Layout.Section>
                    <Layout.Panel variant="darker">
                        <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 270px)', width: '100%' }}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={users}
                                columnDefs={getColumns()}
                                defaultColDef={defaultColDef}
                                pagination={true}
                                paginationPageSize={25}
                                tooltipShowDelay={0}
                                tooltipHideDelay={3500}
                            >
                            </AgGridReact>
                        </div>
                    </Layout.Panel>
                </Layout.Section>
            </Layout>
        </Page>
    </>
}
export default Users