import React from "react";
import { FormField, Button, FileInput } from "@dentsu-ui/components";
class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: "",
        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        const files = e.target.files;
        this.setState({ files: files });
    }
    handleOnSubmit(e) {
        if (this.state.files && this.state.files[0]) this.props.handleFile(this.state.files[0]);
    }
    render() {
        return (
            <div>
                <FormField label="Upload a file" className="pb-3">
                    <FileInput onInputChange={this.handleChange} mode="grey" />
                </FormField>
                <Button variant="tertiary" size="medium" onClick={(e) => { this.handleOnSubmit(e); }}>
                    Upload
                </Button>
            </div>
        );
    }
}
export default FileUpload