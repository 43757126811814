import { configureStore } from '@reduxjs/toolkit'
import clientsSlice from './reducers/clientsSlice'
import marketsSlice from './reducers/marketsSlice'
import themeSlice from './reducers/themeSlice'
import vendorsSlice from './reducers/vendorsSlice'

export default configureStore({
    reducer: {
        clients: clientsSlice,
        markets: marketsSlice,
        vendors: vendorsSlice,
        theme : themeSlice
    }
})

