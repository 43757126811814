import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Page, Layout, FileInput, Loading, Box, TextInput, LoadMore, Icon, Button, Spacer, Toast, Select, FormField, SearchInput, Scrollable, FormatNumber } from "@dentsu-ui/components";
import { TreeSelect, Input, AutoComplete } from "antd";
import AccountService from "../../services/AccountService";
import CategoryTemplate from '../../assets/Category.xlsx'
import banner from "../../assets/images/background/dentsu_website_headers_10.jpg"
import noImage from '../../assets/images/no-image.png'
//import TypeAhead from "../../components/typeahead/typeahead";
import { secondaryAction } from "../../helper/secondaryAction";
import DeleteDialog from "../../components/DeleteDialog"
const toast = Toast();

const CategoryMapping = (props) => {
    const [fieldMapping, setFieldMapping] = useState([])
    const [taxonomy, setTaxonomy] = useState([])
    const [importCategories, setImportCategories] = useState([])
    const [importCategoryOptions, setImportCategoryOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [searchval, SetSearch] = useState("")
    const [dialogOpen, setDialogOpen] = useState(false);
    const [pageNo, setpage] = useState(0);
    const [TotalCount, setTotalCount] = useState(0);
    const [isShowCount, setShowCount] = useState(true);

    async function getCategoryMappings() {
        setIsLoading(true)
        let response = await AccountService.getCategoryMappings(props.match.params.id, `{offset:${pageNo},limit:100,filter:[],sort:[]}`, "")
        setFieldMapping(response.data.Mapping)
        setTotalCount(response.data.Total)
        setShowCount(true);
        setIsLoading(false)
    }

    useEffect(() => {
        document.title = `Category Mapping (${props.match.params.id})`
        console.log('useEffect')
        async function getGoogleTaxonomy() {
            let response = await AccountService.getGoogleTaxonomy()
            response.data.forEach(item => {
                item.name = getTaxonomyName(item.cat1, item.cat2, item.cat3, item.cat4, item.cat5, item.cat6, item.cat7)
                item.value = item.name
                item.title = item.name
            });
            setTaxonomy(response.data)
        }
        async function getImportCategories() {
            let response = await AccountService.getImportCategories(props.match.params.id)
            setImportCategories(response.data)
        }


        getGoogleTaxonomy()
        getImportCategories()
        getCategoryMappings()

    }, [])

    const getTaxonomyName = (cat1, cat2, cat3, cat4, cat5, cat6, cat7) => {
        let separator = ' > '
        let category = cat1
        if (cat2) { category += separator + cat2 }
        if (cat3) { category += separator + cat3 }
        if (cat4) { category += separator + cat4 }
        if (cat5) { category += separator + cat5 }
        if (cat6) { category += separator + cat6 }
        if (cat7) { category += separator + cat7 }
        return category
    }

    const addFieldMapping = () => {
        let fm = [{ Category: '', Google: '', AffectedItems: '', IsEdited: false }, ...fieldMapping]
        setFieldMapping(fm)
    }
    const deleteFieldMapping = (e) => {
        setDialogOpen(false)
        const index = e.currentTarget.getAttribute("data-id")
        const categoryReferenceId = fieldMapping[index].CategoryReferenceId;
        const category = fieldMapping[index].Category;
        if (categoryReferenceId != null || categoryReferenceId != undefined) {
            AccountService.deleteCategoryMappings(props.match.params.id, categoryReferenceId)
                .then((response) => {
                    //console.log(response.data)
                    if (response.data == true) {
                        const fm = [...fieldMapping];
                        fm.splice(index, 1)
                        setFieldMapping(fm)
                        //console.log("deleted " + category);
                        setImportCategories([...importCategories, category])
                        toast({ title: "Success", content: "Deleted Successfully", status: "success" })
                    }
                    else {
                        toast({ title: "Error", content: "Error Deleting Category Mapping", status: "error" })
                    }
                })
        }
        else {
            const fm = [...fieldMapping];
            fm.splice(index, 1)
            setFieldMapping(fm)
            toast({ title: "Success", content: "Deleted Successfully", status: "success" })
        }
    }
    const exportDataAsCsv = async () => {
        try {
            const currentDate = new Date();
            const month = (currentDate.getMonth()+1).toString().padStart(2,'0');
            const year = currentDate.getFullYear().toString().slice(-2);
            let response = await AccountService.getCategoryMappings(props.match.params.id, `{offset:${pageNo},limit:999999,filter:[],sort:[]}`, "")
            let mapping = response.data.Mapping
            let csv = "CategoryReferenceId,Category,Google,AffectedItems\n";

            mapping.forEach(item => {
                let googleFields = `"${String(item.Google).replace(/"/g, '""')}"`;
                let formatedAffectedItem = `"${String(item.AffectedItems).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}"`;

                csv += `${item.CategoryReferenceId},${item.Category},${googleFields},${formatedAffectedItem}\n`;

            });
            const blob = new Blob([csv], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = `${month}${year}-FC-${props.match.params.id}-Category-Mapping.CSV`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error exporting CSV:", error);
        }

    }
    const handleSubmit = async () => {
        setIsLoading(true)
        let mapping = fieldMapping.map(x => { return { CategoryReferenceId: x.CategoryReferenceId, Category: ((x.category == undefined) || (x.category == "") ? x.Category : x.category), Google: x.Google, AffectedItems: null, IsEdited: x.IsEdited } })
        try {
            let response = await AccountService.updateCategoryMapping(props.match.params.id, mapping)

            if (response.data.duplicateMappings != undefined) {
                let result = response.data.duplicateMappings.mapping.map(option => option.key);

                if (result.length > 0) {
                    toast({
                        status: 'error', title: "Error", content: 'Following are Duplicate Category mapping. ' + result.toString(), duration: 5000

                    })
                }

            }
            else {

                fieldMapping.forEach(item => {
                    if (item.category != undefined)
                        item.Category = item.category;
                    let affectedItems = response.data.fields.mapping.filter(x => x.key == item.Category)

                    if (affectedItems.length > 0) {
                        item.AffectedItems = affectedItems[0].value;
                        if (item.CategoryReferenceId == null || item.CategoryReferenceId == "")
                            item.CategoryReferenceId = affectedItems[0].categoryReferenceId
                    }
                    item.IsEdited = false;
                });
                await getCategoryMappings();
                toast({
                    status: 'success', title: "Success", content: 'Category mappings updated successfully. ', duration: 5000

                })
            }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }

    const handleCancel = () => {
        window.location.reload(true)
    }

    const handleSearch = async (e) => {
        if (e.key == 'Enter') {
            setIsLoading(true)
            console.log("searchval " + searchval);
            let response = await AccountService.getCategoryMappings(props.match.params.id, `{offset:0,limit:100,filter:[{"value":"${searchval}"}],sort:[]}`, "")
            setFieldMapping(response.data.Mapping)
            setTotalCount(response.data.Total)
            setIsLoading(false)
            setShowCount(true);
            setpage(0);
        }

    }
    const loadNextpageData = async (e) => {

        if (fieldMapping.length < TotalCount) {
            //setIsLoading(true)
            //  const currentScrollPosition = scrollContainerRef.current.scrollTop;
            //  console.log("ggdh "+ currentScrollPosition)

            let offset = (pageNo + 1) * 100;
            let response = await AccountService.getCategoryMappings(props.match.params.id, `{offset:${offset},limit:100,filter:[{"value":"${searchval}"}],sort:[]}`, "")
            let newdata = response.data.Mapping;
            setTotalCount(response.data.Total)
            let fm = [...fieldMapping, ...newdata]

            setFieldMapping(fm)
            setpage((pageNo) => pageNo + 1);
            // scrollContainerRef.current.scrollTop = currentScrollPosition;
            // console.log("nann"+ scrollContainerRef.current.scrollTop)
            //setIsLoading(false)
        }
    }

    const handleFile = (e) => {
        let file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null
        if (!file) return
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = async (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { raw: false });
            //console.log(data)
            let mapping = data.map(x => { return { Category: x.Category, Google: x.Google, AffectedItems: null, IsEdited: x.IsEdited } })
            let response = await AccountService.getAffectedCountPreview(props.match.params.id, mapping, "Bulk")
            setFieldMapping(data)

            data.forEach(item => {
                if (item.category != undefined)
                    item.Category = item.category;
                let affectedItems = response.data.fields.mapping.filter(x => x.key == item.Category)
                if (affectedItems.length > 0) {
                    item.AffectedItems = affectedItems[0].value;
                    if (item.CategoryReferenceId == null || item.CategoryReferenceId == "")
                        item.CategoryReferenceId = affectedItems[0].categoryReferenceId
                }
                item.IsEdited = false;
            });
            setShowCount(false);
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
        e.target.value = null;

    }

    const onSelect = (data) => {
        console.log('onSelect', data);
    };


    const handlePreviewCount = async () => {
        setIsLoading(true)
        let mapping = fieldMapping.map(x => { return { CategoryReferenceId: x.CategoryReferenceId, Category: ((x.category == undefined) || (x.category == "") ? x.Category : x.category), Google: x.Google, AffectedItems: null, IsEdited: x.IsEdited } })
        try {
            let response = await AccountService.getAffectedCountPreview(props.match.params.id, mapping, "Single")

            if (response.data.duplicateMappings != undefined) {
                let result = response.data.duplicateMappings.mapping.map(option => option.key);

                if (result.length > 0) {
                    toast({
                        status: 'error', title: "Error", content: 'Following are Duplicate Category mapping. ' + result.toString(), duration: 5000

                    })
                }

            }
            else {

                fieldMapping.forEach(item => {
                    if (item.category != undefined)
                        item.Category = item.category;
                    let affectedItems = response.data.fields.mapping.filter(x => x.key == item.Category)

                    if (affectedItems.length > 0) {
                        item.AffectedItems = affectedItems[0].value;
                        if (item.CategoryReferenceId == null || item.CategoryReferenceId == "")
                            item.CategoryReferenceId = affectedItems[0].categoryReferenceId
                    }
                    item.IsEdited = false;
                });
            }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }

    const setCategoryDropdowns = (oldCategory, newCategory) => {
        console.log("oldCategory " + oldCategory)
        console.log("newCategory " + newCategory)
        // setImportCategories([...importCategories,oldCategory])
        // setImportCategories(importCategories.filter(x => x ==newCategory))

        // setImportCategoryOptions(
        //     importCategories.filter(x => x ==newCategory).map(x => { return { value: x } }),
        // );
        // importCategories= importCategories.push(oldCategory);
        // setImportCategoryOptions(
        //     importCategories.map(x => { return { value: x } }),
        // );

    }

    const onSearch = (searchText) => {
        setImportCategoryOptions(
            !searchText ? [] : importCategories.filter(x => x.toUpperCase().startsWith(searchText.toUpperCase())).map(x => { return { value: x } }),
        );
    };

    const totalItems = <div>
        {isLoading ? <label></label> : <label> <FormatNumber value={fieldMapping.length} /> out of <FormatNumber value={TotalCount} />  </label>}
    </div>


    let oldLayout = <div className="container w-full mx-auto">
        <div className="w-full px-4 md:px-0 md:mt-8 mb-16 text-gray-800 leading-normal">


            <div className="flex flex-row flex-wrap flex-grow mt-2">
                <div className="w-full">
                    <div className="bg-white border rounded shadow">
                        <div className="p-3 flex sm:space-x-10 xl:space-x-40 justify-around items-end">
                            <div className="w-full flex items-end w-1/12">&nbsp; <Icon className="cursor-pointer" color={'black'} size={25} icon={'add'} onClick={addFieldMapping}></Icon></div>
                            <h5 className="font-bold w-full flex items-start w-10/1 justify-around" style={{ marginLeft: 5 }}>Import Category Value</h5>
                            <h5 className="font-bold w-full flex items-start w-10/1 justify-around" style={{ marginLeft: 5 }}>Google Product Category</h5>
                            <h5 className="font-bold flex items-start w-1/3 justify-around">Affected Items</h5>
                        </div>
                        <Scrollable callbacks={{
                            onScroll: (e) => {

                                if (e.target.scrollHeight - e.target.scrollTop - 5 <= e.target.clientHeight) {
                                    loadNextpageData();
                                }
                            }
                        }} height={700}>
                            {
                                fieldMapping.map((field, index) => {
                                    return (
                                        <div className="p-3 flex sm:space-x-10 xl:space-x-40 justify-around"
                                            key={index + field.Category + 'FieldMapping'}>
                                            <div className="w-full flex items-end w-1/12">
                                                <DeleteDialog index={index} deleteFieldMapping={deleteFieldMapping}></DeleteDialog></div>
                                            <div className="w-full items-end w-10/1 justify-left" style={{ marginLeft: 5 }}>
                                                {/* <Input defaultValue={field.Category}
                                                onChange={(e) => { field.Category = e.target.value }}
                                                style={{ width: '100%' }}
                                            /> */}
                                                <AutoComplete
                                                    defaultValue={(field.category == undefined) || (field.category == "") ? field.Category : field.category}
                                                    options={importCategoryOptions}
                                                    style={{ width: '100%' }}
                                                    onSelect={(e) => {
                                                        field.IsEdited = true;
                                                        // console.log("onSelect"+ field.Category)
                                                        setCategoryDropdowns(field.Category, e)
                                                        field.Category = e;
                                                        handlePreviewCount();

                                                    }}
                                                    onSearch={onSearch}
                                                    onChange={(e) => { field.category = e; field.IsEdited = true; }}
                                                    placeholder="enter product category"
                                                />
                                            </div>
                                            <div className="w-full items-start w-10/2 justify-left overflow-hidden" style={{ marginLeft: 5 }}>
                                                {/* <TextInput defaultValue={field.Google} onChange={(e) => { field.Google = e.target.value }} /> */}
                                                <TreeSelect
                                                    showSearch
                                                    defaultValue={field.Google}
                                                    style={{ width: '100%' }}
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    treeData={taxonomy}
                                                    placeholder="Please select"
                                                    treeLine
                                                    onChange={(value) => {
                                                        console.log(value)
                                                        field.IsEdited = true;
                                                        field.Google = value
                                                    }}

                                                />
                                                {/* <Select
                                                    width={250}
                                                    options={taxonomy}
                                                    customLabelKey="name"
                                                    customValueKey="id"
                                                    getOptionLabel={(option) => option.id + ' - ' + option.name}
                                                    getOptionValue={(option) => option.name}
                                                    onChange={(selected, event) => {
                                                        onOptionChange(selected)
                                                    }} value={field.Google} /> */}
                                            </div>
                                            <div className="w-full flex items-start justify-middle w-1/12">

                                                <FormatNumber value={field.AffectedItems}></FormatNumber>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Scrollable>

                    </div>
                </div>
            </div>

            <div className="flex justify-center p-3">
                <div className="px-5">
                    <Button variant="secondary" onClick={handleSubmit}>Save</Button>
                </div>
                <div className="px-5">
                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                </div>
            </div>
        </div>
    </div>

    let thumbnail = noImage
    try {
        thumbnail = require(`../../assets/images/client-logo/${props.match.params.id}.jpg`)
    } catch (error) {
        thumbnail = noImage
    }

    return (
        <>
            <Page title="Category Mapping"
                thumbnail={thumbnail}
                banner={banner}
                metadata={props.match.params.id}
                secondaryActions={secondaryAction(props)}
            >
                <br />
                <Layout>
                    <Layout.Section>
                        <div className="flex justify-between">
                            <div className="flex">
                                <a href={CategoryTemplate} download="CategoryTemplate.xlsx">
                                    <Button variant="primary" size="small">Download Template</Button><Spacer width={8} />
                                </a>
                            </div>
                            <div className="flex">
                                <FileInput onInputChange={(e) => (handleFile(e))} />
                            </div>
                            <div className="flex">
                                <SearchInput id="searchval" placeholder="Search" onKeyDown={handleSearch} onChange={(e) => { SetSearch(e.target.value) }} />
                            </div>
                        </div>

                    </Layout.Section>
                </Layout>
                <Layout>
                    <Layout.Section>
                        <Layout.Panel title="Category Mapping" variant="darker" actions={isShowCount ? totalItems : ""}>
                            <Layout>
                                <Layout.Section>
                                    <div className="flex flex-row flex-wrap flex-grow justify-end items-center" onClick={exportDataAsCsv} >
                                        <FontAwesomeIcon size="2x" className="cursor-pointer" icon={faDownload} />
                                    </div>
                                </Layout.Section>
                            </Layout>
                            {isLoading ?
                                <>
                                    <Box className="flex items-center justify-center" height="100%">
                                        <Loading>Please wait while we are fetching the content</Loading>
                                    </Box>
                                </> :
                                <div className="ag-theme-alpine" style={{ minHeight: 400, width: '100%' }}>
                                    {oldLayout}
                                </div>}

                        </Layout.Panel>
                    </Layout.Section>
                </Layout>
            </Page>
        </>
    )
}
export default CategoryMapping;