import React from "react";
import { Select, Loading, Statistic, Stack, Page, Caption, Box, Button, DateRangeInput } from "@dentsu-ui/components";
import AccountService from "../../services/AccountService";
import ClientService from "../../services/clientService";
import UtilityService from "../../services/UtilityService";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import { default as FCDate } from "../../components/Date";
import Time from "../../components/Time";
import banner from "../../assets/images/background/dentsu_website_headers_9.jpg"
import thumbnail from "../../assets/images/FC-Logo-Icon.png"
import { secondaryAction } from "../../helper/secondaryAction"
import markets from "../accounts/markets";
import { configConsumerProps } from "antd/lib/config-provider";

class Import extends React.Component {
    constructor(props) {
        super(props);
        this.selectStage = [
            { id: 2001, name: "Import" },
            { id: 2003, name: "Transform" },
            { id: 2002, name: "Export" }
            
        ]
        this.state = {
            loading: false,
            productImport: [],
            product: [],
            productExport: [],
            productTransform: [],
            clients: [],
            markets: [],
            importTimes :[],
            vendor: [],
            accounts: [],
            stageName: this.selectStage[0],
            marketName: "",
            selectedMarkets: [],
            selectedClients: [],
            selectedAccount: '',
            selectedImportQueueRun: '0',
            selectedImportQueKey: '0',
            clientId: 0,
            marketId: 0,
            vendorId: 0,
            totalCounts: "",
            active: "",
            paused: "",
            feedPreview: [],
            total: 30,
            pageIndex: 0,
            selectedSearch: '',
            selectedFilters: [],
            showEmptyState: false,
            perPage: 0,
            params: "",
            defaultColDef: { flex: 1, resizable: true, filter: true, sortable: true, minWidth: 150,filterParams: { filterOptions: ['contains','equals'] } },
            limit: 100,
            loadingOverlayComponentParams: {
                children: 'One moment please...',
            },
            filters: {},
            IsClientChanged: false,
            dateRange: [],
            rowModelType: 'infinite' // 'clientSide'
        }
        this.exportDataAsCsv = this.exportDataAsCsv.bind(this)
        this.searchHistorical = this.searchHistorical.bind(this)
    }

    exportStage() {
        let routeStage = this.props.location.pathname.split('/')[2]
        return routeStage;
    }

    componentDidMount() {
        this.loadData();
        const routeStage = this.props.location.pathname.split('/')[2];
        const selectedStage = this.selectStage.find(stage => stage.name.toLowerCase() === routeStage.toLowerCase());

        this.setState({ stageName: selectedStage }, () => {
            document.title = `${this.state.stageName.name} ` + `Data`;
        });
    }

    fecthAccounts = async () => {
        return Promise.all([
            ClientService.getClients(),
            AccountService.getAccountsMarkets(),
            ClientService.getVendors(),
            AccountService.getAccounts()
        ])
    }

    loadData = async () => {
        try {
            this.setState({ loading: true });
            let [clients, markets, vendors, accounts] = await this.fecthAccounts();

            clients.data.forEach(client => {
                client.label = client.name
                client.options = markets.data.filter(x => x.parentId == client.id)
                client.options.forEach(option => {
                    option.label = option.clientName
                });
            });

            this.setState({ clients: clients.data });

            markets.data.forEach(market => {
                market.name = market.name || market.newName
            });

            this.setState({ markets: markets.data });
            this.setState({ vendor: vendors.data });
            this.setState({ accounts: accounts.data });
            this.setState({ selectedClients: this.state.clients })
        }
        catch (error) {
        } finally {
            this.setState({ loading: false });
            let routeStage = this.props.location.pathname.split('/')[2]
            routeStage = this.selectStage.filter(x => x.name.toLowerCase() == routeStage.toLowerCase())[0]
            this.setState({ stageName: routeStage })
        }
    }

    async onChangeStage(name) {
        this.props.history.push(name.toLowerCase());
        document.title = `${name} `
    }

    async onChangeClient(client, market) {

        if (market) {
                        this.setState({ marketId: this.state.marketId });
            this.setState({ vendorId: market.id })


        } else {
            
            this.setState({ marketId: client.id });
            this.setState({ clientId: client.parentId });

            this.setState({ IsClientChanged: true });
            await this.gridApi?.setFilterModel(null);
            this.setState({ filters: {} });
            

        }

        let response = await ClientService.getClientAccounts(client.parentId, client.id);
        this.setState({ clientAccounts: response.data });
       
    } 
    
    async onChangeAccount(client, market) {

        if (market) {          

            this.setState({ marketId: this.state.marketId });
            this.setState({ vendorId: market.vendorKey })

        } else {           
            this.setState({ marketId: client.id });
            this.setState({ clientId: client.parentId });

            this.setState({ IsClientChanged: true });
            await this.gridApi?.setFilterModel(null);
            this.setState({ filters: {} });
            

        }
    }
        

    async onChangeDateRange(client, date) {

      if(date[0] != null && date[1] && this.state.marketId != 0)
      {
        let imports = await AccountService.GetImportRunTime(this.state.marketId, date[0].toLocaleDateString("es-CL"), date[1].toLocaleDateString("es-CL"),this.state.stageName.name);
        this.setState({ importTimes: imports.data})
      }
    }

    

    async fetchProductData(client, market, vendorId) {
        switch (this.state.stageName.id) {
            case 2001:
                return await this.fecthImportData(client, market)
            case 2002:
                                return await this.fecthExportData(client, market, vendorId)
            case 2003:
                return await this.fecthTransformData(client, market)
            // case 2004: return await this.fecthHistoricalData(client, market)
            default: break;
        }
    }

    fecthImportData = async (client, market) => {
        try {
            let imports = await AccountService.getProductImport(client, market, this.getStartDate(), this.getEndDate(), this.state.filters,this.state.selectedImportQueKey,false)
            this.setState({ productImport: imports.data.products });
            this.setState({ totalCounts: imports.data.total });
            this.setState({ active: imports.data.active });
            this.setState({ paused: imports.data.paused });
            return imports
        }
        catch (error) {
            console.log(error)
        }
    }

    fecthTransformData = async (client, market) => {
        try {

            let transform = await AccountService.getProductTransformed(client, market, this.getStartDate(), this.getEndDate(), this.state.filters,this.state.selectedImportQueKey,false)

            // AccountService.getProductTransformed(client, market, this.getStartDate(), this.getEndDate(), this.state.filters,this.state.selectedImportQueKey,false)
            // .then((res) => {
            //      console.log("Transform product - "+JSON.stringify(res))
            //     // if (res.data.status == true) {
            //     //     // const updatedVendor = { ...this.state.vendor, name: vendrName };
            //     //     // this.setState({ vendor: updatedVendor })
            //     //    //  console.log("after  " + this.state.vendor.name)
            //     //     //toast({ title: "Success", content: res.data.message, status: "success" })
            //     // }

            // })
            // .catch((err) => {
            //     console.log(err)
            // })

            this.setState({ productImport: transform.data.products });
            this.setState({ totalCounts: transform.data.total });
            this.setState({ active: transform.data.active });
            this.setState({ paused: transform.data.paused });
            return transform
        }
        catch (error) {
            console.log(error)
        }
    }

    fecthExportData = async (client, market, vendor) => {
        try {
            if(vendor != null &&  vendor != 0)
            {
            let exports = await AccountService.getProductExport(client, market, vendor, this.getStartDate(), this.getEndDate(), this.state.filters,this.state.selectedImportQueKey,false)
            this.setState({ productImport: exports.data.products });
            this.setState({ totalCounts: exports.data.total });
            this.setState({ active: exports.data.active });
            this.setState({ paused: exports.data.paused });
            return exports
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    getStartDate() {
        if (this.state.dateRange.length == 0) return null
        return this.state.dateRange[0]
    }

    getEndDate() {
        if (this.state.dateRange.length == 0) return null
        return this.state.dateRange[1]
    }

    autoSize() {
        if (this.gridApi) { this.gridApi.rowBuffer = 1000; }
    }

    onGridReady = params => {
        
        if (params.api) {
            params.api.setFilterModel(null);
        }

        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const dataSource = {
            rowCount: undefined,
            getRows: (params) => {

               let filters;
                               if(this.state.IsClientChanged ==false)
                {
                     filters = {
                        offset: params.startRow,
                        limit: this.state.limit,
                        filter: [...UtilityService.ConvertAGGridFilterToApiFilter(params.filterModel), UtilityService.ConvertClientIdsToApiFilter([this.state.marketId])],
                        sort: UtilityService.ConvertAGGridSortToApiSort(params.sortModel)
                    };
                }
                else{

                     filters = {
                        offset: params.startRow,
                        limit: this.state.limit,
                        filter: [UtilityService.ConvertClientIdsToApiFilter([this.state.marketId])],
                        sort: []
                    };


                }
                
                this.setState({ IsClientChanged: false });
                this.setState({ filters: filters })
                this.gridApi.showLoadingOverlay();
                this.fetchProductData(this.state.clientId, this.state.marketId, this.state.selectedAccount.vendorKey).then(() => {
                    params.successCallback(this.state.productImport, this.state.totalCounts);
                    //this.gridApi.sizeColumnsToFit()
                   this.gridApi.hideOverlay();
                    //params.columnApi.autoSizeAllColumns();
                })
            },
        };
        params.api.setDatasource(dataSource);
    }

    getColumns() {
        if (this.state.productImport && this.state.productImport.length > 0) {
            let keys = Object.keys(this.state.productImport[0])
            let columns = [];

            keys.forEach(key => {
                    let column = { field: key, cellClass: 'cell-wrap-text' }
                    // switch (key) {
                    //     case 'productId':
                    //         column = { ...column, headerName: 'SKU' }; break;
                    //     case 'imageUrl':
                    //         column = { ...column, headerName: 'Image Link' }; break;
                    //     case "availability":
                    //         column = { ...column, cellClass: 'capitalize' }; break;


                    //     case "importQueueKey":
                    //         column = { ...column, headerName: "Id", minWidth: 100 }; break;
                    //     case "clientId":
                    //         column = { ...column }; break;
                    //     case "importFileName":
                    //         column = { ...column, minWidth: 350 }; break;
                    //     case "mpn":
                    //         column = { ...column, minWidth: 130 }; break;
                    //     case "historyCreatedDateTime":
                    //     case "importDate":
                    //     case "promoStartDate1":
                    //     case "promoStartDate2":
                    //     case "promoEndDate1":
                    //     case "promoEndDate2":
                    //     case "canceledDate":
                    //     case "createdDate":
                    //     case "reactivatedDate":
                    //         column = { ...column, minWidth: 175, headerName: 'Reactivated Date (CT)', cellRenderer: FCDate }; break;
                    //     case "importTime":
                    //         column = { ...column, minWidth: 150, valueFormatter: this.timeFormatter }; break;
                    //     default:
                    //         break;
                    // }

                    // if (['price', 'salePrice'].indexOf(key) > -1) {
                    //     column.valueFormatter = this.currencyFormatter
                    // }

                    // if (key.toLowerCase().includes('date')) {
                    //     column.cellRenderer = FCDate
                    // }
                    columns.push(column)
                });
            return columns
        }
        return []
    }

    currencyFormatter(params) {
        return params.value
    }

    timeFormatter(params) {
        return params.value ? moment(params.value, 'hh:mm:ss').format('hh:mm:ss a') : ''
    }

    objectsToCSV(arr) {
        const array = [Object.keys(arr[0])].concat(arr)
        return array.map(row => {
            return Object.values(row).map(value => {
                //return typeof value === 'string' ? JSON.stringify(value) : value
                return typeof value === 'string' ? value.replace(/,/g, "-") : value
            }).toString()
        }).join('\n')

    }


    exportDataAsCsv = async () => {
         let fileName = this.getExportFileName(this.state.stageName.id)

        let downloadData;
        switch (this.state.stageName.id) {
            case 2001:
                downloadData = await await AccountService.getProductImport(this.state.clientId, this.state.marketId, this.getStartDate(), this.getEndDate(), this.state.filters,this.state.selectedImportQueKey,true)
                break
            case 2002:
                //downloadData = await AccountService.getProductExport(this.state.clientId, this.state.marketId, this.state.vendorId, this.getStartDate(), this.getEndDate(), this.state.filters,this.state.selectedImportQueKey,true)
                downloadData = await AccountService.getProductExport(this.state.clientId, this.state.marketId, this.state.selectedAccount.vendorKey, this.getStartDate(), this.getEndDate(), this.state.filters,this.state.selectedImportQueKey,true)
                break
            case 2003:
                downloadData = await AccountService.getProductTransformed(this.state.clientId, this.state.marketId, this.getStartDate(), this.getEndDate(), this.state.filters,this.state.selectedImportQueKey,true)
                //console.log("downloadData - "+JSON.stringify(downloadData.data))
                break
            default: break;
        }
        console.log(JSON.stringify(downloadData.data))
        var csv = this.objectsToCSV(downloadData.data.products);

        const url = window.URL.createObjectURL(new Blob([csv]), { type: "data:text/csv;" }) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()    
    }
   
    getExportFileName(stage) {
        switch (stage) {
            case 2001: return this.state.marketId + '-Import-' + moment().format('MMDDyyyy') + '.csv'
            case 2002: return this.state.marketId + '-Export-' + moment().format('MMDDyyyy') + '.csv'
            case 2003: return this.state.marketId + '-Transform-Import-' + moment().format('MMDDyyyy') + '.csv'
            case 2004: return this.state.marketId + '-Historical-' + moment().format('MMDDyyyy') + '.csv'
            default: return this.state.marketId + '-Data-' + moment().format('MMDDyyyy') + '.csv'
        }
    }

    searchHistorical() {
        this.setState({ dateRange: this.state.dateRange })
        if (this.gridApi) this.gridApi.refreshInfiniteCache()
    }

    render() {
        if (this.state.loading) {
            return (
                <>
                    <Box className="flex items-center justify-center" height="100%">
                        <Loading>Please wait while we are fetching the latest content</Loading>
                    </Box>
                </>
            );
        }
        const clients = (this.state.selectedClients)
        //const SelMarkets = (this.state.vendor)
        const SelMarkets = (this.state.clientAccounts)
        const importTimes = (this.state.importTimes)     
        const feedPreview = (this.state.productImport);
        // const exportData = <div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center pb-4" onClick={this.exportDataAsCsv}>
        //     {/* <FontAwesomeIcon size="2x" className="cursor-pointer" icon={faDownload} /> */}
        //     <Button variant="primary" size="small">Download</Button>
        // </div>
        const searchData = <div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center pb-4">
            <Button variant="primary" size="small" onClick={this.searchHistorical}>Search</Button>
            <Button variant="primary" size="small" onClick={this.exportDataAsCsv}>Download</Button>
        </div>
        const accounts = <div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center">
            <label>Account</label>
            <Select
                options={SelMarkets}
                maxMenuHeight="50"
                menuPlacement='bottom'
                width={200}
                customLabelKey="name"
                customValueKey="id"
                getOptionLabel={(option) => option.accountKey+' - '+option.accountName}
                getOptionValue={(option) => option.accountKey}
                onChange={(selected, event) => {
                    this.onChangeAccount(this.state.marketName, selected)
                    this.setState({ selectedAccount: selected });
                }} value={this.state.selectedAccount} />
        </div>
        const dateRange =
            <><div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center pb-4">
                <label>Date Range </label>
                <div>
                    <DateRangeInput
                        parseDate={date => new Date(date)}
                        formatDate={date => date.toLocaleDateString()}
                        onChange={(selected, event) => {
                        this.onChangeDateRange(this.state.clientId, selected)
                        this.setState({ dateRange: selected });
                         }}
                        onBlur={date => { this.setState({ dateRange: date }); } } />
                </div>
            </div><div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center">
                    <label> Import Runs</label>
                    <Select
                        options={importTimes}
                        width={230}
                        customLabelKey="importQueueKey"
                        customValueKey="queueEndDate"
                        getOptionLabel={(option) => option.queueEndDate}
                        getOptionValue={(option) => option.importQueueKey}
                        onChange={(selected, event) => {
                            this.setState({ selectedImportQueueRun: selected });
                            this.setState({ selectedImportQueKey: selected.importQueueKey });
                        }} value={this.state.selectedImportQueueRun} />
                </div>
                </>
            
        const clientMarketSelection = <div>
            <div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center pb-4">
                <label>Stage </label>
                <Select options={this.selectStage}
                    maxMenuHeight="50"
                    menuPlacement='bottom'
                    width={200}
                    customLabelKey="name"
                    customValueKey="id"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    onChange={(selected, event) => { this.onChangeStage(selected.name) }}
                    value={this.state.stageName}
                />
            </div>
            <div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center pb-4">
                <label>Client / Market</label>
                <Select options={clients}
                    width={200}
                    customLabelKey="name"
                    customValueKey="id"
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.id}
                    onChange={(selected, event) => {
                        this.onChangeClient(selected)
                        this.setState({ marketName: selected });
                    }} 
                    value={this.state.marketName} />
            </div>
            {this.state.stageName.id == 2002 ? accounts : ''}
            {dateRange}
            {searchData}
            {/* {this.state.productImport.length > 0 && this.state.stageName.id != 2004 ? exportData : ''} */}
        </div>

        const metrics = <div className="w-full">
            <Stack className="pb-5">
                <Statistic width={240}>
                    <Statistic.Label>
                        Client ID
                    </Statistic.Label>
                    <span style={{ fontWeight: 'var(--dentsu-font-weight-light)', fontSize: 'var(--dentsu-font-size-24)' }}>
                        {this.state.marketId}
                    </span>
                </Statistic>
                <Statistic width={240}>
                    <Statistic.Label>
                    {this.state.stageName.id == 2002 ? "In Stock" : "Active"}
                    </Statistic.Label>
                    <Statistic.Content value={{ current: this.state.active }} />
                </Statistic>
            </Stack>
            <Stack>
                <Statistic width={240}>
                    <Statistic.Label>
                        Total Count
                    </Statistic.Label>
                    <Statistic.Content value={{ current: this.state.totalCounts }} />
                </Statistic>

                <Statistic width={240}>
                    <Statistic.Label>
                    {this.state.stageName.id == 2002 ? "Out of Stock" : "Inactive"}
                    </Statistic.Label>
                    <Statistic.Content value={{ current: this.state.paused }} />
                </Statistic>
            </Stack>
        </div>

        const agGrid = <div style={{ height: '100%', width: '100%' }} className="ag-theme-alpine">
            <AgGridReact
                columnDefs={this.getColumns()}
                defaultColDef={this.state.defaultColDef}
                rowModelType={this.state.rowModelType}
                cacheOverflowSize={2}
                maxConcurrentDatasourceRequests={2}
                infiniteInitialRowCount={2}
                maxBlocksInCache={2}
                onGridReady={this.onGridReady}
                pagination={true}
                paginationPageSize={100}
                loadingOverlayComponent={Loading}
                loadingOverlayComponentParams={
                    this.state.loadingOverlayComponentParams
                }
                // enableRangeSelection = {true}
                // enableClipboard = {true}
            />
        </div>
        return (
            <Page title={ this.state.stageName.name + ' Data'}

                availableSpace={
                    <Stack>
                        <Caption as="span" isSecondary>
                            {/* <RefreshContent onClick={() => { this.loadData() }}></RefreshContent> */}
                        </Caption>
                    </Stack>
                }
                banner={banner}
                thumbnail={thumbnail}
                secondaryActions={secondaryAction(this.props)}

            >
                <div className="container w-full mx-auto">
                    <div className="w-full px-4 md:px-0 md:mt-8 mb-16 text-gray-800 leading-normal relative">
                        <div className="flex justify-between">
                            <div className="flex w-full py-4">
                                {this.state.marketName ? metrics : ''}
                            </div>
                            <div className="flex w-full py-4 justify-end">
                                {clientMarketSelection}
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '650px' }}>
                            {this.state.marketName ? agGrid : ''}
                        </div>
                    </div>
                </div>
            </Page>
        );
    }
}

export default Import