import { Icon, Stack, Statistic, Box } from "@dentsu-ui/components"
const Metrics = (props) => {
    if (props.value.isNew) {
        return (
            <>
                <Box>
                    <Stack justifyContent="center">
                        <Statistic width={240} >
                            <Statistic.Label tooltip={{
                                content: 'Tooltip content',
                                icon: 'help'
                            }}>
                                Clients
                            </Statistic.Label>
                            <Statistic.Content value={{ current: props.value.clients }} />
                        </Statistic>
                        <Statistic width={240} variant="recorded">
                            <Statistic.Label>
                                Active Accounts
                            </Statistic.Label>
                            <Statistic.Content isInline={true}
                                value={{ current: props.value.activeAccounts, }}
                                increment={{ value: props.value.totalAccounts, format: { style: 'decimal', signDisplay: 'never' } }}
                            />
                        </Statistic>
                        <Statistic width={240}>
                            <Statistic.Label>
                                Feed Warnings
                            </Statistic.Label>
                            <Statistic.Content value={{ current: 0 }} />
                        </Statistic>
                    </Stack>
                    <Stack justifyContent="center">
                        <Statistic width={240}>
                            <Statistic.Label>
                                Received Imports
                            </Statistic.Label>
                            <Statistic.Content value={{ current: props.value.receivedImports }} />
                        </Statistic>
                        <Statistic width={240}>
                            <Statistic.Label>
                                Delivered Exports
                            </Statistic.Label>
                            <Statistic.Content value={{ current: props.value.deliveredExports }} />
                        </Statistic>
                        <Statistic width={240}>
                            <Statistic.Label>
                                Feed Errors
                            </Statistic.Label>
                            <Statistic.Content value={{ current: props.value.feedErros }} />
                        </Statistic>
                    </Stack>
                </Box>
            </>
        )
    }
    return (
        <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                <div className="bg-white border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pr-4">
                            <div className="rounded p-3 bg-green-600"><Icon color={'#e2e8f0'} size={35} icon={'person'}></Icon></div>
                        </div>
                        <div className="flex-1 text-right md:text-center">
                            <h5 className="font-bold uppercase text-gray-500">Clients</h5>
                            <h3 className="font-bold text-3xl">{props.value.clients} <span className="text-green-500"><i className="fas fa-caret-up"></i></span></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                <div className="bg-white border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pr-4">
                            <div className="rounded p-3 bg-orange-600"><Icon color={'#e2e8f0'} size={35} icon={'tick'}></Icon></div>
                        </div>
                        <div className="flex-1 text-right md:text-center">
                            <h5 className="font-bold uppercase text-gray-500">Active Accounts</h5>
                            <h3 className="font-bold text-3xl">{props.value.activeAccounts + "/" + props.value.totalAccounts} <span className="text-orange-500"><i className="fas fa-exchange-alt"></i></span></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                <div className="bg-white border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pr-4">
                            <div className="rounded p-3 bg-yellow-600"><Icon color={'#e2e8f0'} size={35} icon={'endorsed'}></Icon></div>
                        </div>
                        <div className="flex-1 text-right md:text-center">
                            <h5 className="font-bold uppercase text-gray-500">Received Imports</h5>
                            <h3 className="font-bold text-3xl">{props.value.receivedImports} <span className="text-yellow-600"><i className="fas fa-caret-up"></i></span></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                <div className="bg-white border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pr-4">
                            <div className="rounded p-3 bg-blue-600"><Icon color={'#e2e8f0'} size={35} icon={'import'}></Icon></div>
                        </div>
                        <div className="flex-1 text-right md:text-center">
                            <h5 className="font-bold uppercase text-gray-500">Delivered Exports</h5>
                            <h3 className="font-bold text-3xl">{props.value.deliveredExports}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                <div className="bg-white border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pr-4">
                            <div className="rounded p-3 bg-indigo-600"><Icon color={'#e2e8f0'} size={35} icon={'high-priority'}></Icon></div>
                        </div>
                        <div className="flex-1 text-right md:text-center">
                            <h5 className="font-bold uppercase text-gray-500">Feed Warnings</h5>
                            <h3 className="font-bold text-3xl">{props.value.feedWarnings}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                <div className="bg-white border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pr-4">
                            <div className="rounded p-3 bg-red-600"><Icon color={'#e2e8f0'} size={35} icon={'error'}></Icon></div>
                        </div>
                        <div className="flex-1 text-right md:text-center">
                            <h5 className="font-bold uppercase text-gray-500">Feed Errors</h5>
                            <h3 className="font-bold text-3xl">{props.value.feedErros}<span className="text-red-500"><i className="fas fa-caret-up"></i></span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Metrics