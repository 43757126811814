import React from "react";
import Moment from 'react-moment';


export default class Date extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let content = (this.props.value != null && this.props.value !='' )
    
      ?  <> <Moment format="MM/DD/YY hh:mm A">{this.props.value}</Moment></>
      : 'N/A'
    return (
      <>
        {content}
      </>
    );
  }
}
