import { createSlice } from '@reduxjs/toolkit'

export const marketsSlice = createSlice({
    name: 'markets',
    initialState: {
        value: [],
    },
    reducers: {
        addMarkets: (state, action) => {
            state.value = action.payload
        }
    },
})

export const { addMarkets } = marketsSlice.actions

export const getMarkets = (state) => state.markets.value

export default marketsSlice.reducer
