import React from "react";
import { connect } from "react-redux";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Loading, Statistic, Stack, Icon, Page, Caption, Layout, Box, Link, Skeleton, Divider,Button,Toast,Card } from "@dentsu-ui/components";
import ThemeSwitch from "../../components/ThemeSwitch";
import AccountsEdit from "../../components/AccountsEdit"
import AccountService from "../../services/AccountService";
import HealthRender from "../../components/HealthRender";
import RefreshContent from "../../components/RefreshContent";
import Date from "../../components/Date";
import Metrics from "../../components/markets/metrics";
import AccountsRowData from "../../components/account/markets/accounts";
import colors from "../../helper/colors";
import banner from "../../assets/images/background/dentsu_website_headers_3.jpg"
import noImage from '../../assets/images/no-image.png'
import { secondaryAction } from "../../helper/secondaryAction"
import ClientService from "../../services/clientService";
import { faL } from "@fortawesome/free-solid-svg-icons";
const toast = Toast();

class Accounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            metadata: {},
            loading: false,
            load:false,
            clients: [],
            markets: [],
            vendors: [],
            accounts: [],
            activitylog:[],
            accountsMetics: [],
            selectedClient: "",
            totalMarkets: "",
            partners: "",
            totalItems: "",
            totalFeeds: "",
            totalAccounts: "",
            totalSuppressedItems: "",
            rowData: [],
            total: 30,
            pageIndex: 0,
            selectedSearch: '',
            selectedFilters: [],
            showEmptyState: false,
            perPage: 0,
            defaultColDef: { flex: 1, resizable: true, sortable: true },
            rowBuffer: 10,
            rowSelection: 'multiple',
            rowModelType: 'infinite',
            cacheBlockSize: 10,
            cacheOverflowSize: 2,
            maxConcurrentDatasourceRequests: 2,
            infiniteInitialRowCount: 2,
            maxBlocksInCache: 2,
            limit: 100,
            paginationPageSize: 100,
            loadingOverlayComponent: Loading,
            loadingOverlayComponentParams: {
                children: 'One moment please...',
            },
            filters: {}
        }
        document.title = `Account (${this.props.match.params.id}) `
    }
    componentDidMount() {
        this.loadData();
    }
    loadData = async () => {
        await this.fetchMarketAccountData(this.props.match.params.id);
        //await this.fecthActivityLogData(this.props.match.params.id);
    }

    fetchMarketAccountData = async (client) => {
        try {
            this.setState({ loading: true });
            let accounts = await AccountService.getMarketAccounts(this.props.match.params.id);
            localStorage.setItem('Accounts_' + this.props.match.params.id, JSON.stringify(accounts.data))
            this.setState({ accounts: accounts.data.accounts });
            this.setState({ metadata: accounts.data.metadata });
            this.setRowData()
            return accounts
        }
        catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    }

    fecthActivityLogData = async (client) => {
        try {
            this.setState({ loading: true });
            let response = await AccountService.getAccountActivityLog(client)
            this.setState({ activitylog: response.data});
            if(response.data.length>0)
                this.setState({ isActivityDataAvailable: true});
            else
            this.setState({ isActivityDataAvailable: false});

            return response
        }
        catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    }

    setRowData() {
        if (this.state.accounts && this.state.accounts.length > 0) {
            let rowData = []
            this.state.accounts.forEach(account => {
                rowData.push({
                    view: account.id,
                    market: account.name,
                    health: account.status && (account.status.toLowerCase() == 'true' || account.status.toLowerCase() == 'active') ? colors.GREEN : colors.GREY,
                    status: account.status,
                    products: account.totalProducts.toLocaleString(),
                    //account.activeProducts.toLocaleString() + '/' + account.totalProducts.toLocaleString(),
                    threshold: account.threshold,
                    lastExportProcessed: account.lastExportProcessed,
                    nextScheduledExport: account.nextScheduledExport,
                    clientId: this.props.match.params.id,
                    vendorId: account.vendorId
                })
            });
            this.setState({ rowData: rowData })
        }
        return []

    }

    render() {
        if (this.state.loading) {
            return (
                <>
                    <Box className="flex items-center justify-center" height="100%">
                        <Loading>Please wait while we are fetching the latest content</Loading>
                    </Box>
                </>
            );
        }
        const rowData = (this.state.rowData);

        let thumbnail = noImage
        try {
            thumbnail = require(`../../assets/images/client-logo/${this.props.match.params.id}.jpg`)
        } catch (error) {
            thumbnail = noImage
        }

        // const results = [];
        // this.state.activitylog.forEach((activity, index) => {
        // results.push(        
        //     <Card variant="functional" style={{border:'1px solid black'}}>            
        //       <Card.Description>
        //         <div style={{fontWeight:'600',paddingBottom:'6px', marginTop:'-18px'}}>
        //           {activity.title} - {activity.logCreationDate}
        //         </div>
        //       </Card.Description>
        //       <hr style={{border:'none',height:'2px',color:'#333',backgroundColor:'#333'}}></hr>
        //       <Card.Description>
        //         <div style={{fontWeight:'500', marginTop:'6px'}}>
        //           {activity.message}
        //         </div>
        //       </Card.Description>            
        //     </Card>,
        //   );
        // });

        // const ActivityLog=
        // <div className="bg-white border rounded shadow h-full">
        //     <div className="border-b p-3 bg-[#60607d]">
        //         <h5 className="font-bold uppercase text-[#ffffff]">Activity Log</h5>
        //     </div>
        //     <div className="w-full p-5" style={{height: '500px', overflowY: 'scroll'}}>  
        //           <Card.Group cols={3} spacing={16}>   
        //               {results}                 
        //           </Card.Group>
        //         {
        //             this.state.isActivityDataAvailable==false? <div style={{textAlign:'center', marginTop:'200px'}}>No Data To Show</div>:''
        //         }                
        //   </div>
        // </div>

        const formatter = new Intl.NumberFormat('en-US');

        const RunAllExport = async () => {
            try {
               this.setState({ load: true });
                let response = await ClientService.allExport(this.props.match.params.id)
                if (response.data.item1 == true) {
                    toast({ title: "Success", content: response.data.item2, status: "success" })
                } else {
                    toast({ title: "Error", content: response.data.item2, status: "error" })
                }
            } catch (error) {
                toast({ title: "Error", content: error, status: "error" })
            }
            finally {
                this.setState({ load: false });
            }
        }

        return (
            <Page title="Accounts"
                thumbnail={thumbnail}
                banner={banner}
                metadata={this.state.metadata.market ? this.state.metadata.market.name + ' - ' + this.state.metadata.market.id : this.props.match.params.id}
                // breadcrumbs={[{ content: 'Back to markets', url: '/accounts/markets' }]}
                availableSpace={
                    <Stack alignItems="center">
                        <Caption as="span" isSecondary className="white">
                            <RefreshContent onClick={() => { this.loadData() }}></RefreshContent>
                        </Caption>
                        <Divider isFlexChild orientation="vertical" />
                        <Caption as="span" isSecondary className="white">
                            <ThemeSwitch>{this.props.theme}</ThemeSwitch>
                        </Caption>
                    </Stack>
                }
                secondaryActions={secondaryAction(this.props)}
        
            >
                <Metrics value={{
                    isNew: this.props.theme == 'dentsu',
                    loading: this.state.loading,
                    accounts: isNaN(this.state.metadata.accounts)?" ":(formatter.format(this.state.metadata.accounts)),
                    partners:  isNaN(this.state.metadata.partners)?" ":(formatter.format(this.state.metadata.partners)),
                    totalItems: this.state.metadata.totalItems,
                    feeds: isNaN(this.state.metadata.feeds)?" ":(formatter.format(this.state.metadata.feeds)),
                    totalSuppressedItems: isNaN(this.state.metadata.totalSuppressedItems)?" ":(formatter.format(this.state.metadata.totalSuppressedItems))
                }}></Metrics>

                {/* <Layout>
                <Layout.Section>
                    <Layout.Panel>
                                {ActivityLog}                                
                            </Layout.Panel>
                        </Layout.Section>
                </Layout>
                <br></br>     */}
                <div>
                    <div className="flex justify-end items-center mx-4">
                        <Button variant="tertiary" size="small" onClick = {() => RunAllExport()} isLoading={this.state.load}>Run All</Button>
                    </div>
                </div>
                <AccountsRowData
                    value={{
                        isNew: this.props.theme == 'dentsu',
                        loading: this.state.loading,
                    }}
                    data={rowData}></AccountsRowData>

            </Page>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme.value
    }
}

export default connect(mapStateToProps)(Accounts);