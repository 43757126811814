import { useSelector } from 'react-redux'
import fallbackImage from '../../assets/images/no-image.png'

const Client = (props) => {
    const clients = useSelector(state => state.clients.value)
    const markets = useSelector(state => state.markets.value)

    const getClientLogo = (id) => {
        let client = markets.filter((market) => market.id == id)[0] || {}
        if (!client.name) return ''
        try {
            return require(`../../assets/images/client-logo/${client.id}.jpg`);
        } catch (error) {
            return fallbackImage
        }
    }

    const getClientName = (id) => {
        let market = markets.filter((market) => market.id == id)[0] || {}
        let clientName = clients.filter((client) => client.id == market.parentId)[0] || {}
        return clientName.name;
    }

    const getClientCode = (id) => {
        let client = markets.filter((market) => market.id == id)[0] || {}
        return client.name + "-" + client.id
    }

    return (
        <>
            <div className="flex items-center py-2">
                <div className="flex-shrink-0 h-10 w-10">
                    <img className="h-10 w-10 rounded-full" src={getClientLogo(props.value)}
                        alt={""} />
                </div>
                <div className="ml-4">
                    <div className="text-sm leading-5 font-medium text-gray-900">{getClientName(props.value)}</div>
                    <div className="text-sm leading-5 text-gray-500">{getClientCode(props.value)}</div>
                </div>
            </div>
        </>
    )
}

export default Client;