import { useEffect, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from 'moment';
import { Page, Layout } from '@dentsu-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import ClientService from '../../services/clientService'
import Date from '../../components/Date';
import Status from '../../components/Status';
import banner from "../../assets/images/background/dentsu_website_headers_15.jpg"
import thumbnail from "../../assets/images/FC-Logo-Icon.png"
import { secondaryAction } from "../../helper/secondaryAction"
const Partners = (props) => {
    const [partners, setPartners] = useState([])
    const gridRef = useRef();
    const defaultColDef = { resizable: true, flex: 1, filter: true, sortable: true, autoHeight: true, minWidth: 100 };

    useEffect(() => {
        document.title = `Partners`
        async function getPartners() {
            gridRef.current.api.showLoadingOverlay();
            let response = await ClientService.getVendors()
            setPartners(response.data)
            gridRef.current.api.hideOverlay();
        }
        setTimeout(() => { getPartners() }, 1000);
    }, [])

    const getColumns = () => {
        if (partners && partners.length > 0) {
            let keys = Object.keys(partners[0])
            let columns = [];
            keys
                .filter(x => ['name', 'createdBy', 'modifiedBy', 'program'].indexOf(x) == -1) //exclude
                .forEach(key => {
                    let column = { field: key, cellClass: 'cell-wrap-text' }
                    switch (key) {
                        case "id":
                            column = { ...column, headerName: "Partner ID", minWidth: 125 }; break;
                        case "displayName":
                            column = { ...column, minWidth: 225 }; break;
                        case "isActive": column = { ...column, headerName: 'Status', cellClass: 'capitalize', cellRenderer: Status }; break;
                        case "modifiedDate":
                            column = { ...column, headerName: 'Modified Date (CT)', cellRenderer: Date, minWidth: 175 };
                            break;
                        case "createdDate": column = { ...column, headerName: 'Created Date (CT)', cellRenderer: Date, minWidth: 175 }
                            break;
                        default:
                            break;
                    }
                    columns.push(column)
                });
            return columns
        }
        return []
    };

    const exportDataAsCsv = () => {
        let fileName = 'Partners-' + moment().format('MMDDyyyy') + '.csv'
        if (gridRef) gridRef.current.api.exportDataAsCsv({ fileName });
    }

    return <>
        <Page title="Partners"
            key="Partners"
            thumbnail={thumbnail}
            banner={banner}
            secondaryActions={secondaryAction(props)}
       
        >
            <br />
            <Layout key={1}>
                <Layout.Section>
                    <div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center pb-4" onClick={exportDataAsCsv}>
                        <FontAwesomeIcon size="2x" className="cursor-pointer" icon={faDownload} />
                    </div>
                </Layout.Section>
            </Layout>
            <Layout key={0}>
                <Layout.Section>
                    <Layout.Panel variant="darker">
                        <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 270px)', width: '100%' }}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={partners}
                                columnDefs={getColumns()}
                                defaultColDef={defaultColDef}
                                pagination={true}
                                paginationPageSize={25}
                            >
                            </AgGridReact>
                        </div>
                    </Layout.Panel>
                </Layout.Section>
            </Layout>
        </Page>
    </>

}
export default Partners