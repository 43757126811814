import { createSlice } from '@reduxjs/toolkit'

export const clientsSlice = createSlice({
    name: 'clients',
    initialState: {
        value: [],
    },
    reducers: {
        addClients: (state, action) => {
            state.value = action.payload
        }
    },
})

export const { addClients } = clientsSlice.actions

export const getClients = (state) => state.clients.value

export default clientsSlice.reducer
