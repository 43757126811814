import React, { useState, useMemo, useRef, useImperativeHandle, forwardRef } from 'react';
import { Loading } from '@dentsu-ui/components';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import DataExplore from '../../pages/data/explorer';
import { Toast } from '@dentsu-ui/components';
import { isDuration } from 'moment';
const toast = Toast();
const FileData = forwardRef((props, ref) => {
    const gridRef = useRef();
    const apiRef = () => {
        let exportData = gridRef.current.api;
        return exportData;
    }
    const paginationPageSize = 100;
    const [rowData] = useState(props.data);
    const defaultColDef = { flex: 1, resizable: true, filter: true, sortable: true }
    const getColumns = () => {
        if (props.data && props.data.length > 0) {
            let keys = Object.keys(props.data[0])
            let columns = []
            keys.forEach(key => {
                let column = { field: key, cellClass: 'cell-wrap-text' }
                columns.push(column)
            });
            return columns
        }
        return []
    };

    useImperativeHandle(ref, () => ({
        download(fileType, fileName) {
            if (!fileType || !fileType.name) {
                toast({
                    status: 'error', title: "Error", content: 'Please select file type', duration: 10000
                })
            }

            if (fileType.name == 'csv') {
                gridRef.current.api.exportDataAsCsv({ fileName });
            } else if (fileType.name == 'tsv') {
                gridRef.current.api.exportDataAsCsv({ fileName: fileName + '.tsv', columnSeparator: '\t' });
            } else if (fileType.name == 'xml') {
                let xml = convertToXML(props.data)
                const a = document.createElement("a");
                a.href = URL.createObjectURL(new Blob([JSON.stringify(xml, null, 2)], { type: "text/xml" }));
                a.setAttribute("download", fileName + ".xml");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                let data = gridRef.current.api.getDataAsCsv({ columnSeparator: ',' });
                const a = document.createElement("a");
                a.href = URL.createObjectURL(new Blob([JSON.stringify(data, null, 2)], { type: "text" }));
                a.setAttribute("download", fileName + ".txt");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        }
    }))

    return (
        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
            <AgGridReact ref={gridRef} rowData={props.data} columnDefs={getColumns()} defaultColDef={defaultColDef}
                pagination={true}
                loadingOverlayComponent={Loading}
                rowSelection={'multiple'}
                paginationPageSize={paginationPageSize} >
            </AgGridReact>
        </div>
    );
});

function convertToXML(obj) {
    var xml = '';
    for (var prop in obj) {
        xml += obj[prop] instanceof Array ? '' : "<" + prop + ">";
        if (obj[prop] instanceof Array) {
            for (var array in obj[prop]) {
                xml += "<" + prop + ">";
                xml += convertToXML(new Object(obj[prop][array]));
                xml += "</" + prop + ">";
            }
        } else if (typeof obj[prop] == "object") {
            xml += convertToXML(new Object(obj[prop]));
        } else {
            xml += obj[prop];
        }
        xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
    }
    var xml = xml.replace(/<\/?[0-9]{1,}>/g, '');
    return xml
}

export default FileData