import React, { useState } from 'react';
import { Modal, Button } from '@dentsu-ui/components';

const AgGridModal = (props) => {
    const [modalOpen, setModalOpen] = useState(false);
    return (
        <>
            <a onClick={() => setModalOpen(true)}>{props.data.status || 'More...'}</a>
            <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                <Modal.Header hasCloseButton={true}
                    title={props.title ? props.data[props.title] : props.data.errorCode}
                    metadata={props.data.id ? (props.data.id + ' - ' + props.data.pipelineId) : ''} />
                <Modal.Body>
                    <div><pre>{JSON.stringify(props.data, null, 2)}</pre></div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalOpen(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AgGridModal