export class FilterModel {
    constructor(field, operator, value, logic, filters) {
        this.field = field
        this.operator = operator
        this.value = value
        this.logic = logic
        this.filters = filters
    }
}

