import { useEffect, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from 'moment';
import { Page, Layout } from '@dentsu-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import UserService from '../../services/admin/UserService';
import Status from '../../components/Status';
import banner from "../../assets/images/background/dentsu_website_headers_14.jpg"
import thumbnail from "../../assets/images/FC-Logo-Icon.png"
import { secondaryAction } from '../../helper/secondaryAction';
const Clients = (props) => {
    const [clients, setClients] = useState([])
    const gridRef = useRef();
    const defaultColDef = { resizable: true, flex: 1, filter: true, sortable: true, autoHeight: true, minWidth: 100 };

    useEffect(() => {
        document.title = `Clients`
        async function getClients() {
            gridRef.current.api.showLoadingOverlay();
            let clientsData = await UserService.getAllClientsAndMarkets()
            setClients(clientsData.data)
            gridRef.current.api.hideOverlay();
        }
        setTimeout(() => { getClients() }, 1000);
    }, [])

    const getColumns = () => {
        if (clients && clients.length > 0) {
            let keys = Object.keys(clients[0])
            let columns = [];
            ['id', 'name', 'clientName', 'clientStatus', 'team', 'pocName', 'pocEmail', 'technicalPocName', 'technicalPocEmail']
                .forEach(key => {
                    let column = { field: key, cellClass: 'cell-wrap-text' }
                    switch (key) {
                        case "id": column = { ...column, cellClass: 'capitalize', minWidth: 125 }; break;
                        case "clientStatus": column = { ...column, headerName: 'Status', cellClass: 'capitalize', minWidth: 100, cellRenderer: Status }; break;
                        case "clientName": column = { ...column, minWidth: 175 }; break;
                        case "name": column = { ...column, headerName: 'Name', minWidth: 225 }; break;
                        case "pocName": column = { ...column, headerName: 'POC Name', cellClass: 'capitalize' }; break;
                        case "team": column = { ...column, cellClass: 'capitalize' }; break;
                        case "pocEmail": column = { ...column, headerName: 'POC Email', cellClass: 'capitalize' }; break;
                        case "technicalPocName": column = { ...column, headerName: 'Technical POC Name', cellClass: 'capitalize' }; break;
                        case "technicalPocEmail": column = { ...column, headerName: 'Technical POC Email', cellClass: 'capitalize' }; break;
                        default:
                            break;
                    }
                    columns.push(column)
                });
            return columns
        }
        return []
    };

    const exportDataAsCsv = () => {
        let fileName = 'Clients-' + moment().format('MMDDyyyy') + '.csv'
        if (gridRef) gridRef.current.api.exportDataAsCsv({ fileName });
    }

    return <>
        <Page title="Clients"
            thumbnail={thumbnail}
            banner={banner}
            secondaryActions={secondaryAction(props)}
   
        >
            <br />
            <Layout>
                <Layout.Section>
                    <div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center pb-4" onClick={exportDataAsCsv}>
                        <FontAwesomeIcon size="2x" className="cursor-pointer" icon={faDownload} />
                    </div>
                </Layout.Section>
            </Layout>
            <Layout>
                <Layout.Section>
                    <Layout.Panel variant="darker">
                        <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 270px)', width: '100%' }}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={clients}
                                columnDefs={getColumns()}
                                defaultColDef={defaultColDef}
                                pagination={true}
                                paginationPageSize={25}
                            >
                            </AgGridReact>
                        </div>
                    </Layout.Panel>
                </Layout.Section>
            </Layout>
        </Page>
    </>

}
export default Clients