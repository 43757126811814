import React from "react";
import { Loading, Page, Layout, Switch, FormField, Image, TextInput, Select, Button, Box, Radio, DateInput, TimeInput, Icon,Dialog } from "@dentsu-ui/components";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import FileInput from "@dentsu-ui/components/dist/esm/components/FileInput"
import FCCheckbox from "../../components/FCCheckbox";
import fallbackImage from '../../assets/images/no-image.png';
import UserService from "../../services/admin/UserService";
import { Toast } from '@dentsu-ui/components';
import banner from "../../assets/images/background/dentsu_website_headers_19.jpg"
import { secondaryAction } from "../../helper/secondaryAction";
const toast = Toast();
class UserManagement extends React.Component {
    constructor(props) {
        super(props);
        this.gridRef = React.createRef();
        this.state = {
            loading: false,
            updating: false,
            showActivityLog: false,
            activeMode: false,        
            dialogOpen: false,
            users: {},
            allUsers: {},
            selectedUser: "",
            user: "",
            userId: "",
            clients: {},
            userClients: {},
            selectedClients: [],
            firstName: "",
            lastName: "",
            email: "",
            image: null,
            role: 0,
            team: "",
            title: "",
            login: "",
            defaultColDef: {
                flex: 1,
                resizable: true,
                minWidth: 100,
                filter: true,
                sortable: true,
            },
            columnDefs: [
                {
                    headerName: "Access", field: 'selected', cellRenderer: FCCheckbox,
                },
                {
                    headerName: "Client", field: 'name',
                },
                {
                    headerName: "Role",
                },
            ],
            activityColumnDefs: [
                {
                    headerName: "IP", field: 'ip', width: '350%'
                },
                {
                    headerName: "Task", field: 'task', width: '550%'
                },
                {
                    headerName: "Date and Time (CT)", field: 'date', width: '378%', cellRenderer: Date
                },
            ],
            ActivityrowData: [
                {
                    "ip": "152.235.126.213",
                    "task": "Task CID 10292 - FTP Credentails",
                },
                {
                    "ip": "152.235.126.213",
                    "task": "Login",
                },
                {
                    "ip": "152.235.126.213",
                    "task": "Updated CID 10781 - Password",
                },
                {
                    "ip": "152.235.126.213",
                    "task": "Logged Out",
                },
                {
                    "ip": "152.235.126.213",
                    "task": "Login",
                },
                {
                    "ip": "152.235.126.213",
                    "task": "Login",
                },
            ],
            userRoles: [
                { value: 1, label: "Admin" },
                { value: 2, label: "User" },
                { value: 3, label: "Channel" },
            ]
        }
        this.updateUserInfo = this.updateUserInfo.bind(this)
        document.title = `UserManagement`
    }
    componentDidMount() {
        this.loadData();
    }
    fetchUsers = async () => {
        return Promise.all([
            UserService.getAllUsers(),
            UserService.getAllClients(),
        ])
    }
    loadData = async () => {
        try {
            this.setState({ loading: true });
            let [usersData, clients] = await this.fetchUsers()
            this.setState({ allUsers: usersData.data });
            this.setState({ users: usersData.data });
            this.state.users.forEach(object => {
                object.isActive = object.isDisabled
                delete object['isDisabled'];
            });
            this.setState({ clients: clients.data });
        }
        catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    }
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    loadUpdatedData = async () => {
        try {
          
            let [usersData, clients] = await this.fetchUsers()
            this.setState({ allUsers: usersData.data });
            this.setState({ users: usersData.data });
            this.state.users.forEach(object => {
                object.isActive = object.isDisabled
                delete object['isDisabled'];
            });
            this.setState({ clients: clients.data });
        }
        catch (error) {
        } finally {
          
        }
    }

    handleSwitchChange = () => {
        this.setState({ dialogOpen: true });
      };

      handleDialogClose = (confirmed) => {
        console.log(confirmed)
        this.setState({ dialogOpen: false });
        if (confirmed) {
          this.updateStatus();
        } else {
          // Revert the switch state if not confirmed
          //this.setState((prevState) => ({ activeMode: !prevState.activeMode }));
        }
      };


    updateStatus = () => {
        const { userId, activeMode } = this.state;
        UserService.updateUserstatus(userId, activeMode)
          .then((res) => {
            console.log(res);
            this.setState({ activeMode: !activeMode, dialogOpen: false });
            this.loadUpdatedData();
          })
          .catch((err) => {
            console.log(err);
            this.setState({ dialogOpen: false });
          });
    };

    // ActiveOrInactive = () => {
    //     this.setState(
    //         prevState => {
    //             const activeMode = !prevState.activeMode ? true : false;
    //             return {
    //                 activeMode: !prevState.activeMode,
    //                 activeMode,
    //             };
    //         }
    //     )
    // }
    showActivityLog = () => {
        this.setState({ showActivityLog: true });
    }
  
    onSelectedUser = (id) => {
        this.setState({ userId: id });
        this.state.user = this.state.allUsers.filter((user) => user.id == id);
        let disable = this.state.user[0].isActive
        console.log(this.state.user[0].isActive)
        this.setState({ activeMode: !disable });
        this.state.firstName = this.state.user[0].firstName;
        this.state.lastName = this.state.user[0].lastName;
        this.state.channel = this.state.user[0].channel;
        this.state.title = this.state.user[0].title;
        this.state.login = this.state.user[0].networkLogin;
        this.state.email = this.state.user[0].email;
        this.state.team = this.state.user[0].team;
        this.state.userClients = this.state.user[0].clients;
        this.setState({ role: this.state.userRoles.filter(x => x.value == this.state.user[0].role)[0] })
        this.selectedClients(this.state.userClients);
    }
    selectedClients = (userClients) => {
        let clients = this.state.clients
        clients.forEach(client => { client.selected = false })
        userClients.forEach(userClientId => {
            let selectedClient = clients.filter(x => x.id == userClientId)
            selectedClient[0].selected = true
        }, this);
        this.setState({ clients: clients })
        this.gridApi.setRowData(this.state.clients);
    }
    updateFirstName = (event) => {
        this.setState({
            firstName: event.target.value,
        });
    };
    updateLastName = (event) => {
        this.setState({
            lastName: event.target.value,
        });
    };
    updateEmail = (event) => {
        this.setState({
            email: event.target.value,
        });
    };
    updateTeam = (event) => {
        this.setState({
            team: event.target.value,
        })
    }
    async updateUserInfo() {
        this.updateClients();
        try {
            this.setState({ updating: true })
            await UserService.updateUser(
                this.state.userId,
                this.state.firstName,
                this.state.lastName,
                this.state.email,
                this.state.team,
                this.state.role.value,
                this.state.selectedClients,
                this.state.title,
                this.state.login,
                this.state.channel,
                !this.state.activeMode
            )
        } catch (error) {
        } finally {
            await this.loadData()
            this.onSelectedUser(this.state.userId)
            this.setState({ updating: false });
            toast({
                status: 'success', title: "Success", content: 'Details updated!', duration: 5000
            })
        }
    };
    updateClients = () => {
        this.state.clients.forEach((x) => {
            if (x.selected)
                this.state.selectedClients.push(x.id);
        });
    }
    getUserLogo = (id) => {
        try {
            return require(`../../assets/images/user-logo/${id}.jpg`);
        } catch (error) {
            return fallbackImage
        }
    }
    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                image: URL.createObjectURL(img)
            });
        }
    };

    render() {
        if (this.state.loading || this.state.updating) {
            return (
                <>
                    <Box className="flex items-center justify-center" height="100%">
                        <Loading>
                            {this.state.loading ? 'Please wait while we are fetching the latest content' : 'Updating...'}
                        </Loading>
                    </Box>
                </>
            );
        }
        const listofUsers = <div>
            <div className="flex flex-row flex-wrap flex-grow justify-end items-center">
                <FormField label="User">
                    <Select options={this.state.users}
                        maxMenuHeight="50"
                        menuPlacement='bottom'
                        width={250}
                        customLabelKey="firstName"
                        customValueKey="id"
                        getOptionLabel={(option) => option.firstName + " " + option.lastName}
                        getOptionValue={(option) => option.id}
                        onChange={(selected, event) => {
                            this.onSelectedUser(selected.id)
                            this.setState({ selectedUser: selected });
                        }}
                        value={this.state.selected}
                    />
                </FormField>
            </div>
        </div>

        const dialogTitle = "Status Change Alert";
        const dialogMessage = "Do you want to change, Please confirm?";
        const userInfo = <div>
            <div className="flex justify-end items-start gap-8 justify-items-end">
                <div className="flex-auto w-32"></div>
                <div className="flex-auto w-64">
                    <FormField label="User Status">
                        {/* <Switch isChecked={this.state.activeMode} onChange={this.ActiveOrInactive} /> */}
                        
                        <Switch isChecked={this.state.activeMode} onChange={this.handleSwitchChange} />

                        <Dialog
                            title={dialogTitle}
                            isOpen={this.state.dialogOpen}
                            onClose={() => this.handleDialogClose(false)}
                        >
                            <Dialog.Body>
                                {dialogMessage}
                            </Dialog.Body>
                            <Dialog.Footer>
                                <Button onClick={() => this.handleDialogClose(true)}>OK</Button>
                                <Button variant="secondary" onClick={() => this.handleDialogClose(false)}>
                                    Cancel
                                </Button>

                            </Dialog.Footer>
                        </Dialog>

                    </FormField> 
                </div>
                <div className="flex-auto w-64">
                </div>
            </div>
            <div className="flex justify-between items-start gap-8">
                <div className="flex-auto w-32">
                    <FormField label="User Picture">
                        <Image className="h-20 w-25" src={this.state.image} fallbackSrc={fallbackImage}></Image>

                        <div className="h-20">
                            <FileInput name="myImage" onChange={this.onImageChange} isFullWidth />
                        </div>
                    </FormField>
                </div>
                <div className="flex-auto w-64">
                    <FormField label="First Name">
                        <TextInput value={this.state.firstName || ''} onChange={this.updateFirstName} isFullWidth />
                    </FormField>
                    <FormField label="Last Name">
                        <TextInput value={this.state.lastName || ''} onChange={this.updateLastName} isFullWidth />
                    </FormField>
                    <FormField label="Email">
                        <TextInput value={this.state.email || ''} onChange={this.updateEmail} isFullWidth />
                    </FormField>
                    <FormField label="Title">
                        <TextInput value={this.state.title || ''} onChange={(e) => { this.setState({ title: e.target.value }) }} isFullWidth />
                    </FormField>
                </div>
                <div className="flex-auto w-64">
                    <FormField label="Team">
                        <TextInput value={this.state.team || ''} onChange={this.updateTeam} isFullWidth />
                    </FormField>
                    <FormField label="Channel">
                        <TextInput value={this.state.channel || ''} onChange={(e) => { this.setState({ channel: e.target.value }) }} isFullWidth />
                    </FormField>
                    <FormField label="Network Login">
                        <TextInput value={this.state.login || ''} onChange={(e) => { this.setState({ login: e.target.value }) }} isFullWidth />
                    </FormField>
                    <FormField label="Role">
                        <Select
                            customLabelKey="label"
                            customValueKey="value"
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            onChange={(option) => {
                                this.setState({ role: option })
                            }}
                            options={this.state.userRoles}
                            value={this.state.role}
                            isFullWidth
                        />
                    </FormField>
                </div>
            </div>
        </div>
        const clientInfo =
            <div style={{ height: '300px', width: '100%' }} className="ag-theme-alpine">
                <AgGridReact
                    ref={this.gridRef}
                    columnDefs={this.state.columnDefs}
                    rowData={this.state.clients}
                    defaultColDef={this.state.defaultColDef}
                    onGridReady={this.onGridReady}
                ></AgGridReact>
            </div>
        const saveButton = <div className="flex flex-row flex-wrap flex-grow justify-center items-center">
            <Button variant="tertiary" size="medium" onClick={this.updateUserInfo} >Save</Button>
        </div>
        const activityLogGrid = <div style={{ height: '300px', width: '100%' }} className="ag-theme-alpine">
            <AgGridReact
                columnDefs={this.state.activityColumnDefs}
                rowData={this.state.ActivityrowData}
                defaultColDef={this.state.defaultColDef}
            ></AgGridReact>
        </div>

        return (
            <>
                <Page title="User Mangement"
                    banner={banner}
                    secondaryActions={secondaryAction(this.props)}
            
                >
                    {listofUsers}
                    <Layout>
                        <Layout.Section>
                            <Layout.Panel title="" variant="darker">
                                {userInfo}
                            </Layout.Panel>
                        </Layout.Section>
                    </Layout>
                    <Layout>
                        <Layout.Section>
                            <Layout.Panel title="Client Access" variant="darker">
                                {clientInfo}
                            </Layout.Panel>
                        </Layout.Section>
                    </Layout>
                    {this.state.showActivityLog ? (<div>
                        <Layout>
                            <Layout.Section isScrollable>
                                <Layout.Panel title="Activity Log" variant="darker">
                                    {activityLogGrid}
                                </Layout.Panel>
                            </Layout.Section>
                        </Layout>
                    </div>) : (<div></div>)}

                    <Layout>
                        <Layout.Section>
                            <Layout.Panel variant="darker">
                                {saveButton}
                            </Layout.Panel>
                        </Layout.Section>
                    </Layout>
                </Page>
            </>
        )
    }
}

export default UserManagement
