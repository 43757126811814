import React from "react";

import Navigation from "./Navigation";
import Footer from "./Footer";
import classes from "./Layout.module.css";

export default class Layout extends React.Component {
  render() {
    return (
      <div className={classes.container}>
        <Navigation />
        <main className={classes.main}>{this.props.children}</main>
        <Footer />
      </div>
    );
  }
}
