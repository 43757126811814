import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Box } from "@dentsu-ui/components";

export default class ViewRender extends React.Component {
    constructor(props) {
        super(props);
    }

    navigateToAccount() {
        window.location.href = `/markets/${Number(this.props.value.id)}/accounts`
    }

    render() {
        return (
            <Box className="flex items-center justify-start cursor-pointer" height="100%">
                <FontAwesomeIcon size="2x" className="fa-solid fa-eye" icon={faEye} onClick={() => { this.navigateToAccount() }} />
            </Box>
        )
    }
}