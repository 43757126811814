import React from "react";
import { Button, TextInput, Dropdown } from "@dentsu-ui/components";

import AuthService from "../services/authService";

export default class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: { firstName: "", lastName: " " },
            clientNames: {},
            selectedClients: { Client: [] },
        };

    }

    async getUser() {
        try {
            const user = AuthService.getUser();
            this.setState({ user: user });
        } catch (error) { }
    }

    componentDidMount() {
        this.getUser();

    }

    updateFirstName = (event) => {
        this.setState({
            firstName: event.target.value,
        });
    };
    updateLastName = (event) => {
        this.setState({
            lastName: event.target.value,
        });
    };

    handleChanges = (event) => {
        event.preventDefault();
        try {

        } catch (error) {
            console.log(error)
        }

    }

    render() {
        return (
            <div className="container w-full flex flex-wrap mx-auto px-2 pt-8 lg:pt-16 mt-16">
                <div className="w-full lg:w-1/5 px-6 text-xl text-gray-800 leading-normal">
                    <div className="block lg:hidden sticky inset-0">
                    </div>
                    <div className="w-full sticky inset-0 hidden max-h-64 lg:h-auto overflow-x-show overflow-y-auto lg:overflow-y-hidden lg:block mt-0 my-2 lg:my-0 border border-gray-400 lg:border-transparent bg-white shadow lg:shadow-none lg:bg-transparent z-20"
                        style={{ top: "6em" }} id="menu-content">
                        <ul className="list-reset py-2 md:py-0">
                            <li
                                className="py-1 md:my-2 hover:bg-orange-100 lg:hover:bg-transparent border-l-4 border-transparent font-bold border-orange-600">
                                <a href='#userprofile'
                                    className="block pl-4 align-middle text-gray-700 no-underline hover:text-orange-600">
                                    <span className="pb-1 md:pb-0 text-sm">User Profile</span>
                                </a>
                            </li>
                            <li className="py-1 md:my-2 hover:bg-orange-100 lg:hover:bg-transparent border-l-4 border-transparent">
                                <a href='#profilemanagement'
                                    className="block pl-4 align-middle text-gray-700 no-underline hover:text-orange-600">
                                    <span className="pb-1 md:pb-0 text-sm">Password Management</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <section className="w-full lg:w-4/5">
                    <div className="md:flex mb-6">
                        <div className="md:w-1/3">
                            <section>
                                <div className="md:w-2/3">
                                    <label className="block text-gray-600 font-bold md:text-left mb-3 md:mb-0 pr-4"></label>
                                </div>
                            </section>
                        </div>
                    </div>
                    <h2 className="font-sans font-bold break-normal text-gray-700 px-2 pb-8 text-xl">User Profile</h2>
                    <div id='userprofile' className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
                        <section>
                            <div className="md:flex mb-6">
                                <div className="md:w-1/3">
                                    <label className="block text-gray-600 font-bold md:text-left mb-3 md:mb-0 pr-4">
                                        First Name
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <TextInput onChange={this.updateFirstName} className="form-input block w-full" id="my-textfield" type="text" value={this.state.user.firstName} />
                                </div>
                            </div>
                            <div className="md:flex mb-6">
                                <div className="md:w-1/3">
                                    <label className="block text-gray-600 font-bold md:text-left mb-3 md:mb-0 pr-4">
                                        Last Name
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <TextInput className="form-input block w-full" id="my-textfield" type="text" value={this.state.user.lastName} onChange={this.updateLastName} />
                                </div>
                            </div>
                            <div className="md:flex mb-6">
                                <div className="md:w-1/3">
                                    <label className="block text-gray-600 font-bold md:text-left mb-3 md:mb-0 pr-4">
                                        Role
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <select name="" className="form-select block w-full focus:bg-white" id="my-select" >
                                        <Dropdown.Option value="administrator">Administrator</Dropdown.Option>
                                        <Dropdown.Option value="user">user</Dropdown.Option>
                                    </select>
                                </div>
                            </div>

                            <div className="md:flex mb-6">
                                <div className="md:w-1/3">
                                    <label className="block text-gray-600 font-bold md:text-left mb-3 md:mb-0 pr-4">
                                        Assigned Clients
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <select className="form-multiselect block w-full" multiple id="my-multiselect">
                                        <Dropdown.Option>Abercrombie & Fitch</Dropdown.Option>
                                        <Dropdown.Option>Hollister</Dropdown.Option>
                                        <Dropdown.Option>J.C Penney</Dropdown.Option>
                                        <Dropdown.Option>Microsoft Stores</Dropdown.Option>
                                        <Dropdown.Option>Urban Outfitters Inc.</Dropdown.Option>
                                    </select>
                                    <p className="py-2 text-sm text-gray-600">Assigned clients are managed by your administrator</p>
                                </div>
                            </div>

                            <div className="md:flex md:items-center">
                                <div className="md:w-1/3"></div>
                                <div className="md:w-2/3">
                                    <Button onClick={this.handleChanges} className="shadow bg-orange-700 hover:bg-orange-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                                        type="button">
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </section>
                    </div>
                    <hr className="bg-gray-300 my-12" />

                    <h2 className="font-sans font-bold break-normal text-gray-700 px-2 pb-8 text-xl">Password Management</h2>
                    <div id='profilemanagement' className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
                        <div>
                            <div className="md:flex mb-6">
                                <div className="md:w-1/3">
                                    <label className="block text-gray-600 font-bold md:text-left mb-3 md:mb-0 pr-4">
                                        New Password
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <TextInput className="form-input block w-full focus:bg-white" id="my-textfield" type="text" onChange={this.updateFirstName} />
                                </div>
                            </div>
                            <div className="md:flex mb-6">
                                <div className="md:w-1/3">
                                    <label className="block text-gray-600 font-bold md:text-left mb-3 md:mb-0 pr-4">
                                        Confirm Password
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <TextInput className="form-input block w-full focus:bg-white" id="my-textfield" type="text" onChange={this.updateFirstName} />
                                </div>
                            </div>
                            <div className="md:flex md:items-center">
                                <div className="md:w-1/3"></div>
                                <div className="md:w-2/3">
                                    <Button
                                        className="shadow bg-orange-700 hover:bg-orange-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                                        type="button">
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </div>


                    </div>
                </section>
            </div>
        );
    }
}