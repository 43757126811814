import React from "react";

export default class ProductData extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div>
    Product Data Page
  </div>
  }
}
