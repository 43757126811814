import React from "react";
import { Select, Loading, Icon, Legends, FormatNumber, Page, Stack, Spacer, Image, Caption, Box } from "@dentsu-ui/components";
import { PieChart, LineChart } from '@dentsu-ui/charts';
import RefreshContent from "../../components/RefreshContent";
import ClientService from "../../services/clientService";
import AccountService from "../../services/AccountService";
import { connect } from "react-redux";
import chartsData from "../../services/chartsData.json";
import fallbackImage from '../../assets/images/no-image.png'
import Date from "../../components/Date";
import banner from "../../assets/images/background/dentsu_website_headers_18.jpg"
import { secondaryAction } from "../../helper/secondaryAction"
class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      clientSelected: true,
      clients: [],
      accountsMetics: [],
      imports: [],
      exports: [],
      historicalExports: [],
      historicalImports: [],
      historicalDataImports: [],
      showMoreImport: false,
      showMoreExport: false,
      importText: "show more",
      exportText: "show more",
      markets: [],
      vendors: [],
      marketId: [],
      selectedMarkets: [],
      errors: [],
      clientName: "",
      marketName: "",
    }
  }
  componentDidMount() {
    this.loadData();
  }
  fecthAccounts = async () => {
    return Promise.all([
      ClientService.getClients(),
      AccountService.getAccountsMarkets(),
      ClientService.getVendors(),
    ])
  }
  loadData = async () => {
    try {
      this.setState({ loading: true });
      let [clients, market, vendors] = await this.fecthAccounts();
      this.setState({ clients: clients.data });
      this.setState({ markets: market.data });
      this.setState({ vendors: vendors.data });
    }
    catch (error) {
    } finally {
      this.setState({ loading: false });
    }
  }
  fecthAccountsData = async (market, client) => {
    return Promise.all([
      AccountService.getAccountmetrics(market, client),
      AccountService.getAccountImports(market, client),
      AccountService.getAccountExports(market, client),
      AccountService.getAccountHistoricalImports(market, client),
      AccountService.getAccountHistoricalExports(market, client),
      AccountService.getAccountsErrors(market, client),
    ]
    )
  }
  async onChangeClient(id) {
    await this.fecthData(id, null)
    let markets = this.state.markets.filter((market) => market.parentId == id) || {}
    this.setState({ selectedMarkets: markets })
  }
  async onChangeMarket(id) {
    let market = this.state.markets.filter((market) => market.id == id)[0] || {}
    let client = this.state.clients.filter((client) => client.id == market.parentId)[0] || {}
    await this.fecthData(client.id, id)
  }
  fecthData = async (market, client) => {
    try {
      this.setState({ loading: true });
      this.setState({ selected: client })
      this.setState({ clientSelected: false })
      let [metrics, imports, exports, historicalImports, historicalExports, errors] = await this.fecthAccountsData(market, client)
      this.setState({ accountsMetics: metrics.data[0] });
      this.setState({ imports: imports.data });
      this.setState({ exports: exports.data });
      this.setState({ historicalImports: historicalImports.data });
      this.setState({ historicalExports: historicalExports.data });
      errors.data.forEach(element => {
        if (element.errorType == 'import') {
          element.id = this.clientName(element.id) || element.id
        } else {
          element.id = this.getVendorName(element.id) || element.id
        }
      });
      this.setState({ errors: errors.data });

    }
    catch (error) {
    } finally {
      this.setState({ loading: false });
    }
  }

  clientName = (id) => {
    let market = this.state.markets.filter((market) => market.id == id)[0] || {}
    let clientName = this.state.clients.filter((client) => client.id == market.parentId)[0] || {}
    return clientName.name
  }
  clientCode = (id) => {
    let client = this.state.markets.filter((market) => market.id == id)[0] || {}
    return client.name + "-" + client.id
  }
  getClientLogo = (id) => {
    // let client = this.state.markets.filter((market) => market.id == id)[0] || {}
    // if (!client.name) return ''
    try {
      return require(`../../assets/images/client-logo/${id}.jpg`);
    } catch (error) {
      return fallbackImage
    }
  }
  getVendorLogo = (id) => {
    // let vendor = this.state.vendors.filter((x) => x.id == id)[0] || {}
    // if (!vendor.displayName) return ''
    try {
      return require(`../../assets/images/vendor-logo/${id}.jpg`);
    } catch (error) {
      return fallbackImage
    }
  }
  getVendorName(id) {
    let vendor = this.state.vendors.filter(x => x.id == id)[0] || {}
    return vendor.displayName
  }
  getVendorDisplayName(id) {
    let vendor = this.state.vendors.filter(x => x.id == id)[0] || {}
    return vendor.name
  }

  showMoreImport = () => {
    this.setState(
      prevState => {
        const importText = !prevState.showMoreImport ? "show less" : "show more";
        return {
          showMoreImport: !prevState.showMoreImport,
          importText,
        };
      }
    );
  };
  showMoreExport = () => {
    this.setState(
      prevState => {
        const exportText = !prevState.showMoreExport ? "show less" : "show more";
        return {
          showMoreExport: !prevState.showMoreExport,
          exportText,
        };
      }
    );
  };
  render() {
    if (this.state.loading) {
      return (
        <>
          <Box className="flex items-center justify-center" height="100%">
            <Loading>Please wait while we are fetching the latest content</Loading>
          </Box>
        </>
      );
    }
    const clients = (this.state.clients);
    const markets = (this.state.selectedMarkets)
    const clientMarketSelection =
      <div className="p-3">
        <div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center pb-4">
          <label>Clients</label>
          <Select options={clients}
            maxMenuHeight="50"
            menuPlacement='bottom'
            width={200}
            customLabelKey="name"
            customValueKey="id"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={(selected, event) => {
              this.onChangeClient(selected.id)
              this.setState({ clientName: selected });
            }} value={this.state.clientName}
          />
        </div>
        <div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center">
          <label>Markets</label>
          <Select options={markets}
            maxMenuHeight="50"
            menuPlacement='bottom'
            width={200}
            customLabelKey="name"
            customValueKey="id"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={(selected, event) => {
              this.onChangeMarket(selected.id)
              this.setState({ marketName: selected });
            }} value={this.state.marketName} />

        </div>
      </div>
    if (true && this.state.clientSelected) {
      return (
        <div className="absolute top-[105px] right-0">
          {clientMarketSelection}
        </div>
      )
    }
    const errors = (this.state.errors);
    const importdata = (this.state.imports)
    const exportdata = (this.state.exports)
    const historicalExports = (this.state.historicalExports);
    const historicalImports = (this.state.historicalImports);
    const piedata = chartsData.PiechartData;
    const linedata = chartsData.linechartData;
    const errorsData = errors.map((error, index) => {
      return (
        <tr key={index + 'ErrorsSection' + error.id}>
          <td className="px-6 py-1 whitespace-no-wrap capitalize">
            {error.errorType}
          </td>
          <td className="px-6 py-1 whitespace-no-wrap">
            {error.id}
          </td>
          <td className="px-6 py-1 whitespace-no-wrap" title={error.errorMessage}>
            <p className="py-1 truncate"><a href="#">{error.errorCode}</a></p>
          </td>
        </tr>
      );
    })
    const exportedDataSection = exportdata.map((exportData, index) => {
      const delivered = exportData.delivered !== null ? exportData.delivered : 'null'
      return (
        <tr key={'exportDataSection ' + index}>
          <td className="px-6 py-4 whitespace-no-wrap">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <Image className="h-10 w-10 rounded-full" src={this.getVendorLogo(exportData.vendor)} fallbackSrc={fallbackImage}></Image>
              </div>
              <div className="ml-4">
                <div className="text-sm leading-5 font-medium text-gray-900">{this.getVendorName(exportData.vendor)}</div>
                <div className="text-sm leading-5 text-gray-500">{this.getVendorDisplayName(exportData.vendor)}</div>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-no-wrap">
            <span
              className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              {exportData.type}
            </span>
          </td>
          <td className="px-6 py-4 whitespace-no-wrap">
            <span
              className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 capitalize">
              {exportData.status}
            </span>
          </td>
          <td className="px-6 py-4 whitespace-no-wrap">
            <span
              className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              <FormatNumber value={exportData.count} />
            </span>
          </td>
          <td className="px-6 py-4 whitespace-no-wrap">
            <span
              className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              <Date value={delivered} />
            </span>
          </td>
          <td className="px-6 py-4 whitespace-no-wrap">
            <span
              className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              {exportData.deliveryMethod}
            </span>
          </td>
        </tr>
      );
    })
    const importedDataSection = importdata.map((importData, index) => {
      const received = importData.received !== null ? importData.received : 'null'
      const Processed = importData.processed !== null ? importData.processed : 'null'
      return (
        <tr key={'importDataSection' + index}>
          <td className="px-6 py-4 whitespace-no-wrap">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <Image className="h-10 w-10 rounded-full" src={this.getClientLogo(importData.clientId)} fallbackSrc={fallbackImage}></Image>
              </div>
              <div className="ml-4">
                <div className="text-sm leading-5 font-medium text-gray-900">{this.clientName(importData.clientId)}</div>
                <div className="text-sm leading-5 text-gray-500">{this.clientCode(importData.clientId)}</div>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-no-wrap">
            <span
              className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              {importData.type}
            </span>
          </td>
          <td className="px-6 py-4 whitespace-no-wrap">
            <span
              className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 capitalize">
              {importData.status}
            </span>
          </td>
          <td className="px-6 py-4 whitespace-no-wrap">
            <span
              className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              <FormatNumber value={importData.count} />
            </span>
          </td>
          <td className="px-6 py-4 whitespace-no-wrap">
            <span
              className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              <Date value={received} ></Date>
            </span>
          </td>

          <td className="px-6 py-4 whitespace-no-wrap">
            <span
              className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              <Date value={Processed} ></Date>
            </span>
          </td>
        </tr>
      );
    })
    const historicalImportsData = historicalImports.map((historicalImports, index) => {
      const fileName = historicalImports.fileName !== null ? historicalImports.fileName : 'null'
      const count = historicalImports.count !== null ? historicalImports.count : 'null'
      const startTime = historicalImports.startTime !== null ? historicalImports.startTime : 'null'
      const endTime = historicalImports.endTime !== null ? historicalImports.endTime : 'null'
      const elapsedTime = historicalImports.elapsedTime !== null ? historicalImports.elapsedTime : 'null'
      return (
        <tr key={'historicalImportsData' + index}>
          <td>{fileName}</td>
          <td><FormatNumber value={count} /></td>
          <td><Date value={startTime}></Date></td>
          <td><Date value={endTime}></Date></td>
          <td><Date value={elapsedTime}></Date></td>
          <td>{historicalImports.report}</td>
        </tr>
      );
    })
    const historicalExportsData = historicalExports.map((historicalExports, index) => {
      return (
        <tr key={'historicalImportData' + index}>
          <td><Date value={historicalExports.date}></Date></td>
          <td>{historicalExports.partnerId}</td>
          <td><FormatNumber value={historicalExports.count} /></td>
          <td><FormatNumber value={historicalExports.reactivated} /></td>
          <td><FormatNumber value={historicalExports.cancelled} /></td>
        </tr>
      );
    })
    return (
      <Page metadata="Metadata" title="Title" subtitle="Subtitle"
        availableSpace={
          <Stack>
            <Caption as="span" isSecondary>
              <RefreshContent onClick={() => { this.loadData() }}></RefreshContent>
            </Caption>
          </Stack>
        }
        banner={banner}
        secondaryActions={secondaryAction(this.props)}
 
      >
        <div className="container w-full mx-auto">
          <div className="w-full px-4 md:px-0 md:mt-8 mb-16 text-gray-800 leading-normal">
            {clientMarketSelection}
            <div className="flex flex-wrap">
              <div className="flex flex-row flex-wrap flex-grow">
                <div className="w-full md:w-2/3">
                  <div className="w-full">
                    <div className="flex flex-wrap insert-x-0 top-0">
                      <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                        <div className="bg-white border rounded shadow p-2">
                          <div className="flex flex-row items-center">
                            <div className="flex-shrink pr-4">
                              <div className="rounded p-3 bg-green-600"><Icon color={'#e2e8f0'} size={35} icon={'briefcase'}></Icon></div>
                            </div>
                            <div className="flex-1 text-right md:text-center">
                              <h5 className="font-bold uppercase text-lg text-gray-400">Total Items</h5>
                              <h3 className="font-bold text-3xl"><FormatNumber value={this.state.accountsMetics.total} /><span className="text-green-500"><i
                                className="fas fa-caret-up"></i></span></h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                        <div className="bg-white border rounded shadow p-2">
                          <div className="flex flex-row items-center">
                            <div className="flex-shrink pr-4">
                              <div className="rounded p-3 bg-orange-600"><Icon color={'#e2e8f0'} size={35} icon={'properties'}></Icon></div>
                            </div>
                            <div className="flex-1 text-right md:text-center">
                              <h5 className="font-bold uppercase text-lg text-gray-400">New </h5>
                              <h3 className="font-bold text-3xl"><FormatNumber value={this.state.accountsMetics.new} /> <span className="text-orange-500"><i
                                className="fas fa-exchange-alt"></i></span></h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                        <div className="bg-white border rounded shadow p-2 ">
                          <div className="flex flex-row items-center">
                            <div className="flex-shrink pr-4">
                              <div className="rounded p-3 bg-yellow-600"><Icon color={'#e2e8f0'} size={35} icon={'tick'}></Icon></div>
                            </div>
                            <div className="flex-1 text-right md:text-center">
                              <h5 className="font-bold uppercase text-lg text-gray-400">Active</h5>
                              <h3 className="font-bold text-3xl"><FormatNumber value={this.state.accountsMetics.active} /><span className="text-yellow-600"><i className="fas fa-caret-up"></i></span>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                        <div className="bg-white border rounded shadow p-2">
                          <div className="flex flex-row items-center">
                            <div className="flex-shrink pr-4">
                              <div className="rounded p-3 bg-indigo-500"><Icon color={'#e2e8f0'} size={35} icon={'arrow-down'}></Icon></div>
                            </div>
                            <div className="flex-1 text-right md:text-center">
                              <h5 className="font-bold uppercase text-lg text-gray-400">In Stock</h5>
                              <h3 className="font-bold text-3xl"><FormatNumber value={this.state.accountsMetics.inStock} /></h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                        <div className="bg-white border rounded shadow p-2">
                          <div className="flex flex-row items-center">
                            <div className="flex-shrink pr-4">
                              <div className="rounded p-3 bg-indigo-800"><Icon color={'#e2e8f0'} size={35} icon={'small-cross'}></Icon></div>
                            </div>
                            <div className="flex-1 text-right md:text-center">
                              <h5 className="font-bold uppercase text-lg text-gray-400">Out of Stock</h5>
                              <h3 className="font-bold text-3xl"><FormatNumber value={this.state.accountsMetics.outOfStock} /></h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                        <div className="bg-white border rounded shadow p-2">
                          <div className="flex flex-row items-center">
                            <div className="flex-shrink pr-4">
                              <div className="rounded p-3 bg-red-600"><Icon color={'#e2e8f0'} size={35} icon={'high-priority'}></Icon></div>
                            </div>
                            <div className="flex-1 text-right md:text-center">
                              <h5 className="font-bold uppercase text-lg text-gray-400">Paused</h5>
                              <h3 className="font-bold text-3xl"><FormatNumber value={this.state.accountsMetics.logic} /><span className="text-red-500"><i className="fas fa-caret-up"></i></span></h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/3 p-3">
                  <div className="bg-white rounded shadow">
                    <div className="p-3 bg-[#60607d] h-10">
                      <h5 className="font-bold uppercase text-[#ffffff]">Errors</h5>
                    </div>
                    <div className="p-5">
                      <div className="flex flex-col">
                        <div className="-my-2 py-1 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                          <div
                            className="align-middle min-w-full shadow overflow-auto sm:rounded-lg border-b border-gray-200 xl:h-[115px] md:h-[222px] overflow-y-auto">
                            <table className="min-w-full">
                              <thead>
                                <tr>
                                  <th
                                    className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Type
                                  </th>
                                  <th
                                    className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Vendor
                                  </th>
                                  <th
                                    className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Details
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-white">
                                {errorsData}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="border-b-2 border-gray-400 my-8 mx-4" />

            <div className="flex flex-row flex-wrap flex-grow mt-2">
              <div className="w-full p-3">
                <div className="bg-white border rounded shadow">
                  <div className="border-b p-3 bg-[#60607d]">
                    <h5 className="font-bold uppercase text-[#ffffff]">Imports Queue</h5>
                  </div>
                  <div className="p-5">
                    <div className="flex flex-col">
                      <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div
                          className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200 h-96 overflow-y-auto">
                          <table className="min-w-full">
                            <thead>
                              <tr>
                                <th
                                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Client
                                </th>
                                <th
                                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Type
                                </th>
                                <th
                                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Status
                                </th>
                                <th
                                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Count
                                </th>
                                <th
                                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Received Time
                                </th>
                                <th
                                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Processed Time
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white overflow-y-auto" >
                              {importedDataSection}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row flex-wrap flex-grow mt-2">
              <div className="w-full p-3">
                <div className="bg-white border rounded shadow">
                  <div className="border-b p-3 bg-[#60607d]">
                    <h5 className="font-bold uppercase text-[#ffffff]">Exports Queue</h5>
                  </div>
                  <div className="p-5">
                    <div className="flex flex-col">
                      <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div
                          className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200 h-96 overflow-y-auto">
                          <table className="min-w-full">
                            <thead>
                              <tr>
                                <th
                                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Vendor
                                </th>
                                <th
                                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Type
                                </th>
                                <th
                                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Status
                                </th>
                                <th
                                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Count
                                </th>
                                <th
                                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Delivered
                                </th>
                                <th
                                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Delivered Method
                                </th>
                              </tr>
                            </thead>
                            <tbody style={{ height: "40px", overflow: "auto" }}>
                              {exportedDataSection}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="flex flex-wrap items-stretch"> */}
              <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                <div className="bg-white border rounded shadow h-full">
                  <div className="border-b p-3 bg-[#60607d]">
                    <h5 className="font-bold uppercase text-[#ffffff]">Import Timeline</h5>
                  </div>
                  <div className="p-5">
                    <LineChart height="280px" colors="pink" data={linedata}
                      margin={{ top: 10, right: 20, bottom: 25, left: 25, }}
                      axisBottom={{
                        legend: 'JCP',
                        legendOffset: 50,
                        legendPosition: 'middle'
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                <div className="bg-white border rounded shadow h-full">
                  <div className="border-b p-3 bg-[#60607d]">
                    <h5 className="font-bold uppercase text-[#ffffff]">Optimizations Needed</h5>
                  </div>
                  <div style={{ height: 280 }} className="p-5">
                    <Legends.Item color="#6AA6EB">Active &nbsp;</Legends.Item>
                    <Legends.Item color="#8C5ED5">Cancelled &nbsp;</Legends.Item>
                    <Legends.Item color="#A32DC1">Reactivated &nbsp;</Legends.Item>
                    <Legends.Item color="#00A1A5">New</Legends.Item>
                    <PieChart innerRadius={0.5} data={piedata} margin={{ top: 25, right: 25, bottom: 10, left: 25 }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                <div className="bg-white border rounded shadow h-full">
                  <div className="border-b p-3 bg-[#60607d]">
                    <h5 className="font-bold uppercase text-[#ffffff]">Export Timeline</h5>
                  </div>
                  <div className="p-5">
                    <LineChart height="280px" colors="pink" data={linedata}
                      margin={{ top: 10, right: 20, bottom: 25, left: 25, }}
                      axisBottom={{
                        legend: 'JCP',
                        legendOffset: 50,
                        legendPosition: 'middle'
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full p-3">
                <div className="bg-white border rounded shadow">
                  <div className="border-b p-3 bg-[#60607d]">
                    <h5 className="font-bold uppercase text-[#ffffff]"> Historical Imports</h5>
                  </div>
                  <div className="p-5">
                    <table className="w-full p-5 text-gray-700 min-w-full">
                      <thead className="px-6 py-3 border-b border-gray-300 bg-gray-50 text-left text-xs leading-4 font-medium">
                        <tr>
                          <th className="px-6 py-3 border-b border-gray-300 bg-gray-50 text-left text-xs leading-4 font-bold uppercase text-left text-black-900">File Name</th>
                          <th className="font-bold uppercase text-left text-black-900">Total Feed Count</th>
                          <th className="font-bold uppercase text-left text-black-900">Start Time</th>
                          <th className="font-bold uppercase text-left text-black-900">End Time</th>
                          <th className="font-bold uppercase text-left text-black-900">Elapsed Time</th>
                          <th className="font-bold uppercase text-left text-black-900">Report</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.showMoreImport ? historicalImportsData : historicalImportsData.slice(0, 5)}
                      </tbody>
                    </table>
                    <a variant="ghost" onClick={this.showMoreImport}>{this.state.importText}</a><Spacer width={8} />
                  </div>
                </div>
              </div>
              <div className="w-full p-3">
                <div className="bg-white border rounded shadow">
                  <div className="border-b p-3 bg-[#60607d]">
                    <h5 className="font-bold uppercase text-[#ffffff]">Historical Exports</h5>
                  </div>
                  <div className="p-5">
                    <table className="w-full p-4 text-gray-700">
                      <thead className="px-6 py-3 border-b border-gray-300 bg-gray-50 text-left text-xs leading-4 font-medium">
                        <tr>
                          <th className="px-6 py-3 border-b border-gray-300 bg-gray-50 text-left text-xs leading-4 font-bold uppercase text-left text-black-900">Date </th>
                          <th className="font-bold uppercase text-left text-black-900">Parter ID</th>
                          <th className="font-bold uppercase text-left text-black-900">Partner</th>
                          <th className="font-bold uppercase text-left text-black-900">Reactivated Items</th>
                          <th className="font-bold uppercase text-left text-black-900">Cancelled Items</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.showMoreExport ? historicalExportsData : historicalExportsData.slice(0, 5)}
                      </tbody>
                    </table>
                    <div style={{
                      marginBottom: 8
                    }}>
                      <a variant="ghost" onClick={this.showMoreExport}>{this.state.exportText}</a><Spacer width={10} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients.value
  }
}
export default connect(mapStateToProps)(Accounts);
