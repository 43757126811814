import { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { Tooltip } from "@dentsu-ui/components";
import moment from "moment";

const Timeline = (props) => {
    const markets = useSelector(state => state.markets.value)
    const [showTimeline, setShowTimeline] = useState(false)
    const [missingImports, setMissingImports] = useState([])
    const totalFeeds = props.value.length

    function round(date, duration) {
        return moment(Math.ceil((+date) / (+duration)) * (+duration)).format('h:mma')
    }

    const getClientCode = (id) => {
        let client = markets.filter((market) => market.id == id)[0] || {}
        return client.name + "-" + client.id
    }

    useEffect(() => {
        let missingImports = []
        props.value.forEach(expectedImport => {
            let expectedTime = moment(expectedImport.averagetimeOfImportReceivedOnFtp)
            let extendedTime = round(expectedTime, moment.duration(30, 'minutes'))
            expectedTime = expectedTime.format('h:mma')

            let status = ''
            if (props.imports.filter(x => x.clientId == expectedImport.clientId).length == 0) {
                //Completely missing
                status = 'MISSING'
                missingImports.push({ clientId: expectedImport.clientId, time: extendedTime })
            } else if (props.imports.filter(x => x.clientId == expectedImport.clientId).length > 0 &&
                props.imports.filter(x => (moment(moment(x.receivedTime).format('h:mma'), 'h:mma')).isBefore(moment(extendedTime, 'h:mma')))
            ) {
                status = 'OK'
            } else {
                status = 'NOT OK'
                missingImports.push({ clientId: expectedImport.clientId, time: extendedTime })
            }
            // console.log('expectedTime',
            //     expectedImport.clientId,
            //     expectedTime,
            //     extendedTime,
            //     props.imports.filter(x => x.clientId == expectedImport.clientId).length,
            //     status)
        });
        missingImports = missingImports.reduce((r, a) => {
            r[a.time] = r[a.time] || []
            r[a.time].push(a)
            return r;
        }, Object.create(null))

        let groupedImports = []
        for (const key in missingImports) {
            if (Object.hasOwnProperty.call(missingImports, key)) {
                const element = missingImports[key];
                groupedImports.push({
                    key,
                    value: [... new Set(element.map(x => getClientCode(x.clientId)))],
                    percentage: Math.min(100 * moment(key, 'h:mma').diff(moment().startOf('day')) / moment().endOf('day').diff(moment().startOf('day')), 100)
                })
            }
        }
        setMissingImports(groupedImports)
    }, [])

    return (
        <>
            <div className="w-full bg-gray-200 mb-3 mt-3 mx-3 relative" onMouseEnter={e => { setShowTimeline(true) }}
                onMouseLeave={e => { setShowTimeline(false) }}>
                {missingImports.map((x, index) => {
                    let content = x.key + ' Missing Import for \r\n ' + x.value.join('\r\n')
                    let position = index % 2 == 0 ? 'top' : 'bottom'
                    return <>
                        <div className="bg-blue-600 h-3 p-0.5 absolute text-right" style={{ width: x.percentage + "%" }}>
                            <Tooltip size="medium" content={content} position={position}
                                visible={showTimeline} className="text-center p-0 m-0 whitespace-pre">
                            </Tooltip>
                        </div>
                    </>
                })}

                <div className="bg-blue-600 h-3 p-0.5 absolute text-right"
                    style={{ width: Math.min(props.imports.length * 100 / totalFeeds, 100) + '%' }}>
                    <Tooltip position="bottom" size="medium"
                        content={props.imports.length + "/" + totalFeeds + " Feeds Received"}
                        visible={showTimeline}
                        className="text-center hover:visibility">
                    </Tooltip>
                </div>
                <div className="bg-blue-400 h-3 p-0.5" style={{ width: "80%" }}></div>
            </div>
        </>
    )
}
export default Timeline