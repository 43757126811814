import React from "react";
import { Page, Box, Link, Loading, Layout, Select, FormField, Button, TextInput, RadioGroup, Toast, DateInput, FormatNumber, Divider } from "@dentsu-ui/components";
import * as XLSX from "xlsx";
import FileInput from "@dentsu-ui/components/dist/esm/components/FileInput"
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import ViewSupressionDetails from "../../../components/ViewSupressionDetails"
import SwitchRender from "../../../components/SwitchRender";
import Date from "../../../components/Date";
import AccountService from "../../../services/AccountService";
import ClientService from "../../../services/clientService";
import UtilityService from "../../../services/UtilityService";
import RuleService from "../../../services/ruleService";
import banner from "../../../assets/images/background/dentsu_website_headers_11.jpg"
import noImage from '../../../assets/images/no-image.png'
import { secondaryAction } from "../../../helper/secondaryAction";
import moment from 'moment'
const toast = Toast();

class Suppression extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectedSuppression: 'single',
            selectedType: 'IMPORT',
            previewData: false,
            vendors: [],
            rules: [],
            markets: [],
            vendor: [],
            accounts: [],
            clientId: 0,
            marketId: 0,
            vendorId: "",
            productImport: [],
            totalCounts: "",
            total: 30,
            pageIndex: 0,
            selectedSearch: '',
            selectedFilters: [],
            showEmptyState: false,
            perPage: 0,
            params: "",
            logicId: "JCP-21-0001",
            columnDefs: [
                {
                    headerName: "View",
                    cellRenderer: ViewSupressionDetails,
                    maxWidth: 85
                },
                {
                    headerName: "Status",
                    field: 'IsEnabled',
                    cellRenderer: SwitchRender,
                    cellRendererParams: {
                        onChange: this.onChangeStatus
                    },
                    maxWidth: 95
                },
                {
                    headerName: "ID",
                    field: "RuleKey",
                    maxWidth: 100
                },
                {
                    headerName: "Logic Title",
                    field: "Title",
                    minWidth: 810
                },
                {
                    headerName: "Field",
                    field: "Field",
                    minWidth: 200
                },
                {
                    headerName: "Field Value",
                    field: "Value",
                    minWidth: 150
                },
                {
                    headerName: "Items Affected",
                    field: "ItemsAffected",
                    maxWidth: 150
                },
                {
                    headerName: "Date Created (CT)",
                    cellRenderer: Date,
                    field: "CreatedDate",
                    minWidth: 175,
                    maxWidth: 175
                },

            ],
            defaultColDef: {
                flex: 1,
                resizable: true,
                minWidth: 100,
                filter: true,
                sortable: true,
                wrapText: true,
                autoHeight: true,
                menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
            },
            rowBuffer: 10,
            rowSelection: 'multiple',
            rowModelType: 'infinite',
            cacheBlockSize: 10,
            cacheOverflowSize: 2,
            maxConcurrentDatasourceRequests: 2,
            infiniteInitialRowCount: 2,
            maxBlocksInCache: 2,
            limit: 100,
            paginationPageSize: 100,
            loadingOverlayComponent: Loading,
            loadingOverlayComponentParams: {
                children: 'One moment please...',
            },
            filters: {},
            dateRange: [],
            suppressionField: '',
            suppressionVendor: '',
            suppressionCondition: '',
            suppressionValue: '',
            fieldOptions: [],
            selectedField: ''

        }
        this.onChangeStatus = this.onChangeStatus.bind(this)
        this.suppressionPreviewRef = React.createRef()
        document.title = `Logic Supression`
    }
    componentDidMount() {
        this.loadData();
    }

    fecthAccounts = async () => {
        return Promise.all([
            RuleService.getRules(this.props.match.params.id, 'Suppress'),
            ClientService.getVendors(),
            ClientService.getMarkets(),
            AccountService.getImportRawProductFields()
        ])
    }

    loadData = async () => {
        try {
            this.setState({ loading: true });
            let [rules, vendors, markets, importFields] = await this.fecthAccounts();
            let suppressionRules = rules.data.suppressionRules
            // rules.data.customRules.forEach(rule => {
            //     suppressionRules.push({
            //         CustomRule: rule,
            //         ClientId: rule.ClientId,
            //         Condition: 'N/A',
            //         EndDate: null,
            //         Field: rule.Category,
            //         Id: rule.RuleKey,
            //         ItemsAffected: null,
            //         RuleKey: rule.RuleKey,
            //         StartDate: null,
            //         Title: rule.Comment,
            //         Value: 'N/A'
            //     })
            // });

            this.setState({ rules: suppressionRules })
            this.setState({ vendors: vendors.data })
            this.setState({ markets: markets.data })
            //this.setState({ importFields: importFields.data.map(x => { return { label: x.replace(/_/g, ' '), value: x } }) })
            this.setState({
                importFields: importFields.data.map(x => {
                    return { label: x.displayName.replace(/_/g, ' '), value: x.name, type: x.type };
                })
            });

            this.setState({ marketId: this.props.match.params.id })
            this.setState({ clientId: markets.data.filter(x => x.id == this.props.match.params.id)[0].parentId })
            this.setState({ fieldOptions: this.state.importFields })
        }
        catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    }
    async fetchProductData(client, market) {
        return await this.fecthImportData(client, market)
    }
    fecthImportData = async (client, market) => {
        try {
            let imports = await AccountService.getRawProductsforPreview(client, market, null, null, this.state.filters)
            this.setState({ productImport: imports.data.products });
            this.setState({ totalCounts: imports.data.total });
            return imports
        }
        catch (error) {
            console.log(error)
        }
    }
    onViewportChanged = params => { this.autoSize() }
    onPaginationChanged = params => { this.autoSize() }

    autoSize() {
        if (this.gridApi) { this.gridApi.rowBuffer = 1000; }
    }

    getColumns() {
        if (this.state.productImport && this.state.productImport.length > 0) {
            let keys = Object.keys(this.state.productImport[0])
            let columns = []
            keys.forEach(key => {
                let column = { field: key, cellClass: 'cell-wrap-text' }
                if (['price', 'salePrice'].indexOf(key) > -1) {
                    column.valueFormatter = this.currencyFormatter
                }
                columns.push(column)
            });
            return columns
        }
        return []
    }

    onGridReady = params => {
        this.gridParams = params
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        const dataSource = {
            rowCount: undefined,
            getRows: (params) => {

                let subFilters = [
                    ...UtilityService.ConvertAGGridFilterToApiFilter(params.filterModel),
                    { field: 'ClientId', operator: 'eq', value: this.state.marketId, logic: 'AND' }
                ]

                if (this.state.selectedSuppression === 'bulk') {
                    let filters = []
                    this.state.suppressionValue.forEach(selection => {
                        filters.push({
                            field: this.state.suppressionField,
                            operator: 'contains',
                            value: selection,
                            logic: 'OR',
                        })
                    });

                    subFilters = [...subFilters,
                    {
                        field: this.state.suppressionField,
                        operator: '',
                        value: '',
                        logic: 'OR',
                        filters: filters,
                    }]
                } else {
                    subFilters = [...subFilters,
                    {
                        field: this.state.suppressionField,
                        operator: this.state.suppressionCondition,
                        value: this.state.suppressionValue,
                        logic: 'AND'
                    }]
                }

                let filters = {
                    offset: params.startRow,
                    limit: this.state.limit,
                    filter: subFilters,
                    sort: UtilityService.ConvertAGGridSortToApiSort(params.sortModel)
                };
                this.setState({ filters: filters })
                this.gridApi.showLoadingOverlay();
                this.fetchProductData(this.state.clientId, this.state.marketId).then(() => {
                    params.successCallback(this.state.productImport, this.state.totalCounts);
                    this.gridApi.sizeColumnsToFit()
                    this.gridApi.hideOverlay();
                })
            },
        };
        params.api.setDatasource(dataSource);
    }

    currencyFormatter(params) {
        return params.value
    }
    previewSupression = () => {
        if (this.validateSuppression()) {
            if (this.state.previewData) {
                this.onGridReady(this.gridParams)
            } else {
                this.setState({ previewData: true });
            }
            setTimeout(() => {
                this.suppressionPreviewRef.current.scrollIntoView()
            }, 500);
        }
    }

    validateSuppression = () => {
        if (!this.state.name) {
            toast({ title: "Missing", content: "Logic Title", status: "error" })
            return false
        }

        if (this.state.selectedType == 'EXPORT' && !this.state.suppressionVendor) {
            toast({ title: "Missing", content: "Select Vendor", status: "error" })
            return false
        }

        if (!this.state.suppressionField) {
            toast({ title: "Missing", content: "Select Field", status: "error" })
            return false
        }

        if (this.state.selectedSuppression === 'bulk') {
            if (!this.state.suppressionValue || this.state.suppressionValue.length == 0) {
                toast({ title: "Missing", content: "Please upload data", status: "error" })
                return false
            }

            if (!this.state.suppressionValue[0]) {
                toast({ title: "Missing", content: "Please upload correct data", status: "error" })
                return false
            }

        } else {
            if (!this.state.suppressionCondition) {
                toast({ title: "Missing", content: "Select Condition", status: "error" })
                return false
            }

            if (!this.state.suppressionValue) {
                toast({ title: "Missing", content: "Select Value", status: "error" })
                return false
            }
        }

        return true
    }

    saveSuppression = () => {
        let payload = {
            clientId: this.state.marketId,
            vendorKey: this.state.selectedType == 'EXPORT' ? this.state.suppressionVendor : 0,
            accountKey: 0,
            sqlStatement: '',
            type: this.state.selectedType,
            title: this.state.name,
            field: this.state.suppressionField,
            condition: this.state.selectedSuppression === 'bulk' ? 'contains' : this.state.suppressionCondition,
            value: `${this.state.suppressionValue}`,
            startDate: this.state.startDate == undefined ? null : moment(this.state.startDate).format('YYYY/MM/DD'),
            endDate: this.state.endDate == undefined ? null : moment(this.state.endDate).format('YYYY/MM/DD'),
            itemsAffected: this.state.totalCounts
        }
        console.log(payload)
        if (this.validateSuppression()) {
            RuleService.addRule(payload)
                .then(() => {
                    toast({ title: "Success", content: "Suppression Added", status: "success" })
                    this.loadData()
                })
                .catch((err) => {
                    toast({ title: "Error", content: err, status: "error" })
                })
        } else {
            toast({ title: "Error", content: 'err', status: "error" })
        }
    }

    onChangeStatus(rule, value) {
        rule.isEnabled = value
        RuleService.updateRule(rule)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onChangeField(name, value) {
        this.setState({ [name]: value })
    }

    async onChangeVendor(id) {
        try {
            let fields = await AccountService.getVendorFields(id)
            this.setState({ fieldOptions: fields.data.map(x => { return { label: x.displayName.replace(/_/g, ' '), value: x.name, type: x.type } }) })
        } catch (error) {
            this.setState({ fieldOptions: [] })
        }
    }

    suppressionOptions = [{
        label: 'Single Field Supression',
        value: 'single',
    }, {
        label: 'Bulk Supression',
        value: 'bulk',
        description: 'Upload file for bulk Supression'
    }]

    conditionOptions1 = [
        // { label: 'Contains', value: 'contains' },
        { label: 'Equals', value: 'eq' },
        { label: '>', value: 'gt' },
        { label: '>=', value: 'gte' },
        { label: '<', value: 'lt' },
        { label: '<=', value: 'lte' },
    ]
    conditionOptions2 = [
        { label: 'Contains', value: 'contains' },
        { label: 'Equals', value: 'eq' },
    ]

    getMarketName = (id) => {
        let client = this.state.markets.filter((market) => market.id == id)[0] || {}
        return client.name
    }

    handleBulkSuppressionFile = (e) => {
        let file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null
        if (!file) return
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { raw: false });
            console.log(data, data.map(x => x[this.state.suppressionField]))
            this.setState({ suppressionValue: data.map(x => x[this.state.suppressionField]) })
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    }

    downloadSuppressionTemplate = () => {
        console.log(this.state.suppressionField)
        if (!this.state.suppressionField) {
            toast({ title: "Error", content: 'Please select field', status: "error" })
            return
        }
        const rows = [[this.state.suppressionField], ["Value 1"], ["Value 2"], ["Value 3"]];

        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n");

        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "bulk-suppression-template.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
    }

    fieldCondition = () => {
        let result = this.state.selectedField.type
        let name = this.state.selectedField.name

        if (this.state.selectedType == 'EXPORT') {
            let name = this.state.selectedField.name
            if (name == 'Price' || name == 'SalePrice') {
                return this.conditionOptions1
            }
        }
        if (name == 'import_queue_key') {
            return this.conditionOptions2
        }
        if (result == 'String') {
            return this.conditionOptions2
        }
        else {
            return this.conditionOptions1
        }
    }
    render() {
        let thumbnail = noImage
        try {
            thumbnail = require(`../../../assets/images/client-logo/${this.props.match.params.id}.jpg`)
        } catch (error) {
            thumbnail = noImage
        }
        if (this.state.loading) {
            return (
                <>
                    <Box className="flex items-center justify-center" height="100%">
                        <Loading>Please wait while we are fetching the latest content</Loading>
                    </Box>
                </>
            );
        }
        let surpress;
        if (this.state.selectedSuppression === 'bulk') {
            surpress = <div>
                <div className="flex flex-row flex-wrap flex-grow justify-center items-center gap-4">
                    {
                        this.state.selectedType == 'EXPORT' ? <div>
                            <FormField label="Vendor" >
                                <Select width={250} options={this.state.vendors} customLabelKey="displayName"
                                    customValueKey="id" getOptionLabel={(option) => option.id + '-' + option.displayName}
                                    getOptionValue={(option) => option.id} onChange={(s, e) => {
                                        this.onChangeField('suppressionVendor', s.id)
                                        this.onChangeVendor(s.id)
                                    }} />
                            </FormField>
                        </div> : ''
                    }
                    <div>
                        <FormField label="Field">
                            <Select width={250} options={this.state.fieldOptions} onChange={(s, e) => {
                                this.onChangeField('suppressionField', s.value)
                            }} />
                        </FormField>
                    </div>
                    <div className="px-3">
                        <Link onClick={this.downloadSuppressionTemplate} download="Bulk-Suppression-Template.xlsx">
                            <Button variant="primary" size="small">Download Template</Button>
                        </Link>
                    </div>
                </div>
                <div className="flex flex-row flex-wrap flex-grow justify-around items-center">
                    <FormField label="Upload Suppression File ">
                        <FileInput onInputChange={(e) => (this.handleBulkSuppressionFile(e))} />
                    </FormField>
                </div>
            </div>
        }
        else {
            surpress = <div className="flex flex-row flex-wrap flex-grow justify-between items-center">
                {
                    this.state.selectedType == 'EXPORT' ? <div>
                        <FormField label="Vendor" >
                            <Select width={250} options={this.state.vendors} customLabelKey="displayName"
                                customValueKey="id" getOptionLabel={(option) => option.id + '-' + option.displayName}
                                getOptionValue={(option) => option.id} onChange={(s, e) => {
                                    this.onChangeField('suppressionVendor', s.id)
                                    this.onChangeVendor(s.id)
                                }} />
                        </FormField>
                    </div> : ''
                }
                <div>
                    <FormField label="Field" >
                        <Select width={250} options={this.state.fieldOptions} onChange={(s, e) => {
                            this.onChangeField('suppressionField', s.value, s.type)
                            this.setState({ selectedField: { name: s.value, type: s.type } })
                        }} />
                    </FormField>
                </div>
                <div>
                    <FormField label="Condition">
                        <Select width={250} options={this.fieldCondition()} onChange={(s, e) => {
                            this.onChangeField('suppressionCondition', s.value)
                        }} />
                    </FormField>
                </div>
                <div>
                    <FormField label="Value">
                        <TextInput width={250} onChange={(e) => {
                            this.onChangeField('suppressionValue', e.target.value)
                        }} />
                    </FormField>
                </div>
            </div>

        }
        const ItemSupression = <div>
            <div>
                <FormField label="Title">
                    <TextInput id={this.state.name} placeholder="Title" value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }) }} />
                </FormField>
            </div>
            <Layout.Panel title="Suppression Setup" variant="darker">
                <Layout>
                    <Layout.Section>
                        <Layout.Panel title="Suppression Type" hasBorder variant="darker">
                            <div className="h-24">
                                <RadioGroup isInline name="groupOne"
                                    selectedValue={this.state.selectedSuppression}
                                    options={this.suppressionOptions}
                                    onChange={(event, value) => {
                                        this.setState({ selectedSuppression: value })
                                        this.setState({ suppressionField: '' })
                                    }} /> <br />
                                <RadioGroup isInline name="groupTwo" selectedValue={this.state.selectedType}
                                    options={[{ label: 'Import', value: 'IMPORT' }, { label: 'Export', value: 'EXPORT' }]}
                                    onChange={(event, value) => {
                                        console.log(value)
                                        this.setState({ selectedType: value })
                                        this.setState({ fieldOptions: value == "IMPORT" ? this.state.importFields : [] })

                                    }}
                                />
                            </div>
                        </Layout.Panel>
                    </Layout.Section>
                    <Layout.Section>
                        <Layout.Panel title="Time Based" hasBorder variant="darker">
                            <div className="flex h-24">
                                <FormField label="Start Time">
                                    <DateInput placeholder="DD/MM/YYYY" value={this.state.startDate} parseDate={date => new Date(date)} formatDate={date => date.toLocaleDateString()} onChange={date => {
                                        console.log('onChange date', date);
                                        this.setState({ startDate: date });
                                    }} onBlur={date => {
                                        console.log('onBlur date', date);
                                    }} />
                                </FormField>
                                &nbsp;&nbsp;
                                <FormField label="End Time">
                                    <DateInput placeholder="DD/MM/YYYY" value={this.state.endDate} parseDate={date => new Date(date)} formatDate={date => date.toLocaleDateString()} onChange={date => {
                                        console.log('onChange date', date);
                                        this.setState({ endDate: date });
                                    }} onBlur={date => {
                                        console.log('onBlur date', date);
                                    }} />
                                </FormField>
                            </div>
                        </Layout.Panel>
                    </Layout.Section>
                </Layout>
                <br />
                {surpress}
                <div className="flex justify-end items-center">
                    <Button variant="tertiary" size="small" onClick={this.previewSupression}>Preview</Button>
                </div>
            </Layout.Panel>
        </div>
        const existingItemSupression = <div style={{ height: '250px', width: '100%' }} className="ag-theme-alpine">
            <AgGridReact
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={this.state.rules}
            >

            </AgGridReact>
        </div>
        const gridSupression = <div style={{ height: '300px', width: '100%' }} className="ag-theme-alpine" ref={this.suppressionPreviewRef}>
            <AgGridReact
                columnDefs={this.getColumns()}
                defaultColDef={this.state.defaultColDef}
                rowBuffer={this.state.rowBuffer}
                rowSelection={this.state.rowSelection}
                rowModelType={this.state.rowModelType}
                cacheOverflowSize={this.state.cacheOverflowSize}
                maxConcurrentDatasourceRequests={this.state.maxConcurrentDatasourceRequests}
                infiniteInitialRowCount={this.state.infiniteInitialRowCount}
                maxBlocksInCache={this.state.maxBlocksInCache}
                onGridReady={this.onGridReady}
                pagination={true}
                paginationPageSize={this.state.paginationPageSize}
                onPaginationChanged={this.onPaginationChanged}
                onViewportChanged={this.onViewportChanged}
                loadingOverlayComponent={this.state.loadingOverlayComponent}
                loadingOverlayComponentParams={
                    this.state.loadingOverlayComponentParams
                }
            />
        </div>
        const logicId = <div>
            <label>Account</label> &nbsp;&nbsp;
            {this.getMarketName(this.props.match.params.id)}
        </div>
        const totalItems = <div>
            <label>Total Items Affected</label> &nbsp;&nbsp;
            <FormatNumber value={this.state.totalCounts} />
        </div>
        return (
            <>
                <Page title="Logic Supression"
                    thumbnail={thumbnail}
                    banner={banner}
                    secondaryActions={secondaryAction(this.props)}

                >
                    <Layout>
                        <Layout.Section>
                            <Layout.Panel title="Item Suppression" hasBorder variant="darker" actions={logicId}>
                                {ItemSupression}
                            </Layout.Panel>
                        </Layout.Section>
                    </Layout>

                    <Layout>
                        <Layout.Section>
                            <Layout.Panel title="Existing Item Suppressions" hasBorder variant="darker">
                                {existingItemSupression}
                            </Layout.Panel>
                        </Layout.Section>
                    </Layout>

                    {this.state.previewData ?
                        <Layout>
                            <Layout.Section>
                                <Layout.Panel title="Suppression Preview" hasBorder variant="darker" actions={totalItems}>
                                    {gridSupression}
                                    <div className="flex justify-end items-center pt-10">
                                        <Button variant="tertiary" size="small" onClick={this.saveSuppression}>Save</Button>
                                    </div>
                                </Layout.Panel>
                            </Layout.Section>
                        </Layout>
                        :
                        ''
                    }
                </Page>
            </>
        )
    }
}

export default Suppression;