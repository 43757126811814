import { Icon, Stack, Statistic, Box, FormatNumber } from "@dentsu-ui/components"
const fileDetails = (props) => {
    if (props.value.isNew) {
        return (
            <>
                {/* <Box>
                    <Stack justifyContent="center">
                        <Statistic width={240} >
                            <Statistic.Label>
                                FileName
                            </Statistic.Label>
                            <Statistic.Content isInline={true}
                                value={{ current: props.value.fileName, }}
                            />
                        </Statistic>
                        <Statistic width={240} variant="recorded">
                            <Statistic.Label>
                                Total Column Count
                            </Statistic.Label>
                            <Statistic.Content isInline={true}
                                value={{ current: props.value.totalColumnCount, }}
                            />
                        </Statistic>
                        <Statistic width={240}>
                            <Statistic.Label>
                                File Type
                            </Statistic.Label>
                            <Statistic.Content value={{ current: props.value.fileType }} />
                        </Statistic>
                    </Stack>
                    <Stack justifyContent="center">
                        <Statistic width={240}>
                            <Statistic.Label>
                                File Size
                            </Statistic.Label>
                            <Statistic.Content value={{ current: props.value.fileSize }} />
                        </Statistic>
                        <Statistic width={240}>
                            <Statistic.Label>
                                Total Row Count
                            </Statistic.Label>
                            <Statistic.Content value={{ current: props.value.totalRowCount }} />
                        </Statistic>
                        <Statistic width={240}>
                            <Statistic.Label>
                                Delimiter
                            </Statistic.Label>
                            <Statistic.Content value={{ current: props.value.delimiter }} />
                        </Statistic>
                    </Stack>
                </Box> */}
                <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                        <div className="bg-white border rounded shadow p-2">
                            <div className="flex flex-row items-center">
                                <div className="flex-1 text-right md:text-center">
                                    <h5 className="font-bold text-gray-500">File Name</h5>
                                    <h3 className="text-3xl">{props.value.fileName} &nbsp;<span className="text-green-500"><i className="fas fa-caret-up"></i></span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                        <div className="bg-white border rounded shadow p-2">
                            <div className="flex flex-row items-center">
                                <div className="flex-1 text-right md:text-center">
                                    <h5 className="font-bold text-gray-500">Total column Count</h5>
                                    <h3 className="text-3xl"><FormatNumber value={props.value.totalColumnCount} /> &nbsp;<span className="text-orange-500"><i className="fas fa-exchange-alt"></i></span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                        <div className="bg-white border rounded shadow p-2">
                            <div className="flex flex-row items-center">
                                <div className="flex-1 text-right md:text-center">
                                    <h5 className="font-bold text-gray-500">File Type</h5>
                                    <h3 className="text-3xl">{props.value.fileType} &nbsp;<span className="text-yellow-600"><i className="fas fa-caret-up"></i></span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                        <div className="bg-white border rounded shadow p-2">
                            <div className="flex flex-row items-center">
                                <div className="flex-1 text-right md:text-center">
                                    <h5 className="font-bold text-gray-500">File Size</h5>
                                    <h3 className="text-3xl">{props.value.filesize}&nbsp;</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                        <div className="bg-white border rounded shadow p-2">
                            <div className="flex flex-row items-center">
                                <div className="flex-1 text-right md:text-center">
                                    <h5 className="font-bold text-gray-500">Total Row Count</h5>
                                    <h3 className="text-3xl"><FormatNumber value={props.value.totalRowCount} />&nbsp;</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                        <div className="bg-white border rounded shadow p-2">
                            <div className="flex flex-row items-center">
                                <div className="flex-1 text-right md:text-center">
                                    <h5 className="font-bold text-gray-500">Delimiter</h5>
                                    <h3 className="text-3xl">{props.value.delimiter}&nbsp;<span className="text-red-500"><i className="fas fa-caret-up"></i></span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default fileDetails