import http from "../helper/http-common";
export default class AuthService {

  static login(username, password) {
    return http
      .get("/auth/login", {
        params: { UserName: username, PassWord: password, }
      })
      .then(function (response) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getToken() {
    return localStorage.getItem("token");
  }

  static getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  static loginFTP(host, username, password,protocol) {
    return http.get("/auth/ftp", {
      params: { host, username, password,protocol, }
    })
  }
}
