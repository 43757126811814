
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Modal, Box, Button, FormField, TextInput, Caption, Layout, Textarea } from "@dentsu-ui/components";
import RuleService from "../../src/services/ruleService";
import moment from 'moment-timezone'

export default class ViewRender extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rule: [],
        }
        //console.log("abc" + JSON.stringify(this.props.data))
    }

    fecthRule = async () => {
        return Promise.all([
            RuleService.RuleDetails(this.props.data.ClientId, this.props.data.RuleKey)

        ])
    }

    handleOpenModal = async () => {
        let [rule] = await this.fecthRule();
        let ruledetails = rule.data
        this.setState({ rule: ruledetails })
        this.setState({ showModal: true });
        // let [rule] = await this.fecthRule();
        // let ruledetails = rule.data                   
        // this.setState({ rule: ruledetails }) 
        console.log(rule)
    }
    handleCloseModal = () => {
        this.setState({ showModal: false });
    }

    render() {

        const moment = require('moment');
        let timestamp = this.state.rule.CreatedDate;
        let date = moment.utc(timestamp);
        date = date.tz("America/Chicago");
        let cstDate = date.format("YYYY-MM-DD HH:mm:ss z");
        let vendorKey = this.state.rule.VendorKey != null ? this.state.rule.VendorKey : "0"

        let dataForm =
            <Layout>
                <Layout.Section>
                    <Layout.Panel hasBorder variant="darker">
                        <Box className="justifyContent center">
                            <div className="flex flex-row space-x-8 mb-5 mt-2">
                                <div className='w-full'>
                                    <Caption>Rule #</Caption>
                                    {this.state.rule.RuleKey}
                                </div>
                                <div className='w-full'>
                                    <Caption>Client</Caption>
                                    {this.state.rule.ClientId}
                                </div>
                                <div className='w-full'>
                                    <Caption>Partner</Caption>
                                    {vendorKey}
                                </div>
                                <div className='w-full'>
                                    <Caption>Items Affected</Caption>
                                    {this.state.rule.ItemsAffected}
                                </div>
                            </div>
                            <hr />
                            <div className="flex flex-row space-x-8 mb-5  mt-2">
                                <div className='w-full'>
                                    <Caption>Type</Caption>
                                    {this.state.rule.Type}
                                </div>
                                <div className='w-full'>
                                    <Caption>Task</Caption>
                                    {this.state.rule.Task}
                                </div>
                                <div className='w-full'>
                                    <Caption>Status</Caption>
                                    {this.state.rule.IsEnabled ? "Active" : "Inactive"}
                                </div>
                                <div className='w-full'>
                                    <Caption>Sort Index</Caption>
                                    {this.state.rule.SortIndex}
                                </div>
                            </div>
                            <hr />
                            <div className="flex flex-row space-x-8 mb-5  mt-2">
                                <div className='w-full'>
                                    <Caption>Category</Caption>
                                    {this.state.rule.Category}
                                </div>
                                <div className='w-full'>
                                    <Caption>Ticket ID</Caption>
                                    {this.state.rule.TicketId}
                                </div>
                                <div className='w-full'>
                                    <Caption>Field Value</Caption>
                                    {this.state.rule.FieldValue}
                                </div>
                            </div>
                            <hr />
                            <div className="flex flex-row space-x-8 mb-5  mt-2">
                                <div className='w-full'>
                                    <Caption>Created Date</Caption>
                                    {cstDate}
                                </div>
                                <div className='w-full'>
                                    <Caption>Comment</Caption>
                                    {this.state.rule.Title}
                                </div>
                            </div>
                            <hr />
                            <div className='w-full'>
                                <Caption>Sql Statement</Caption>
                                <Textarea isReadOnly isFullWidth resize="None">
                                    {this.state.rule.SqlStatement}
                                </Textarea>
                            </div>
                        </Box>
                    </Layout.Panel>
                </Layout.Section>
            </Layout>
        return (
            <Box className="flex items-center justify-start cursor-pointer" height="100%">
                <FontAwesomeIcon size="2x" className="fa-solid fa-eye" icon={faEye} onClick={() => { this.handleOpenModal(); }} onChange={this.onChangeStatus} />
                <Modal isOpen={this.state.showModal} onClose={this.handleCloseModal}>
                    <Modal.Header hasCloseButton={true}
                    />
                    <Modal.Body>
                        <div>
                            {dataForm}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCloseModal}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </Box>
        )
    }
}
