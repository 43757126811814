import React, { useState, useMemo, useRef, useCallback } from 'react';
import { Layout, Loading, Link } from "@dentsu-ui/components"
import ViewRender from '../../ViewRender';
import HealthRender from '../../HealthRender';
import AgGridNumbers from '../../AgGridNumbers';
import { AgGridReact } from 'ag-grid-react';
import AgGridModal from '../../AgGridModal';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import AccountsEdit from '../../AccountsEdit';
import Date from '../../Date';
import Status from '../../Status';

const Account = (props) => {
    const gridRef = useRef();
    const paginationPageSize = 100;
    const [rowData] = useState(props.data);
    const cellStyle = {
        'height': '100%',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center'
    }
    const defaultColDef = useMemo(() => { return { resizable: true, flex: 1, autoHeight: true, cellStyle: cellStyle }; }, []);
    const getColumns = () => {
        if (props.data && props.data.length > 0) {
            let keys = Object.keys(props.data[0])
            let columns = []
            keys.filter(x => ["view", "market", "health", "status", "products", "threshold", "lastExportProcessed", "nextScheduledExport"].includes(x))
                .forEach(key => {
                    let column = { field: key, cellClass: 'cell-wrap-text' }
                    switch (key) {
                        case "view":
                            column = { ...column, cellRenderer: AccountsEdit, field: 'view', cellStyle: cellStyle, minWidth: 180 }; break;
                        case "market":
                            column = { ...column, headerName: "Account", field: 'market', minWidth: 150, filter: true,sortable: true, }; break;
                        case "health":
                            column = { ...column, headerName: "Health", field: 'health', cellRenderer: HealthRender, maxWidth: 100, minWidth: 100, cellStyle: cellStyle }; break;
                        case "status":
                            column = { ...column, headerName: 'Status', field: 'status', maxWidth: 100, minWidth: 100, cellRenderer: Status ,sortable: true,}; break;
                        case "products":
                            column = { ...column, headerName: 'Item Count', field: 'products', maxWidth: 150, minWidth: 150 , filter: true,sortable: true,}; break;
                        case "threshold":
                            column = { ...column, headerName: 'Threshold', field: 'threshold', maxWidth: 100, minWidth: 100 }; break;
                        case "lastExportProcessed":
                            column = { ...column, headerName: 'Last Export Processed (CT)', field: 'lastExportProcessed', cellRenderer: Date, minWidth: 175 , filter: true,sortable: true,}; break;
                        case "nextScheduledExport":
                            column = { ...column, headerName: 'Next Scheduled Export (CT)', field: 'nextScheduledExport', cellRenderer: Date, minWidth: 175 , filter: true,sortable: true,}; break;
                        default:
                            break;
                    }
                    columns.push(column)
                });
            return columns
        }
        return []
    };

    const agGrid = <div style={{ height: 400, width: '100%' }} className="ag-theme-alpine">
        <AgGridReact ref={gridRef} rowData={rowData}
            columnDefs={getColumns()} defaultColDef={defaultColDef}
            pagination={true}
            loadingOverlayComponent={Loading}
            rowSelection={'multiple'}
            paginationPageSize={paginationPageSize}
        >
        </AgGridReact>
    </div>
    if (props.value.isNew) {
        return <>
            <Layout>
                <Layout.Section>
                    <Layout.Panel title="Account Health" description="Account Health Description" variant="darker">
                        <div style={{ width: '100%', height: '450px' }}>
                            {agGrid}
                        </div>
                    </Layout.Panel>
                </Layout.Section>
            </Layout>
        </>
    }
    return <>
        <div className="w-full p-3 markets-accounts">
            <div className="bg-white border rounded shadow h-full">
                <div className="border-b p-3 bg-[#60607d]">
                    <h5 className="font-bold uppercase text-[#ffffff]">Account Health</h5>
                </div>
                <div className="w-full p-5">
                    <div style={{ width: '100%', height: '450px' }}>
                        {agGrid}
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default Account
