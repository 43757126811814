import React from "react";
import { Circle, Box } from "@dentsu-ui/components";

const HealthRender = (props) => {
    return (
        <Box className="flex items-center justify-center" height="100%">
            <Circle size={25} bgColor={props.value} color={'#00FF00'} />
        </Box>
    )
}

export default HealthRender