import React, { useState } from "react";
import { Switch, Button, Dialog } from "@dentsu-ui/components";

const SwitchRender = (props) => {
    const [activeMode, setActiveMode] = useState(props.value);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleToggle = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = (confirmed) => {
        if (confirmed) {
            setActiveMode((prevActiveMode) => {
                const newActiveMode = !prevActiveMode;
                props.onChange(props.data, newActiveMode);
                return newActiveMode;
            });
        }
        setDialogOpen(false);
    };

    const dialogTitle = "Status Change Alert";
    const dialogMessage = "Do you want to change, Please confirm?";

    return (
        <div>
            <Switch isChecked={activeMode} onChange={handleToggle} />
            <br />

            <Dialog title={dialogTitle} isOpen={dialogOpen} onClose={() => handleDialogClose(false)}>
                <Dialog.Body>
                    <p>{dialogMessage}</p>
                </Dialog.Body>
                <Dialog.Footer> 
                <Button  onClick={() => handleDialogClose(true)}>OK</Button>
                    <Button  variant="secondary" onClick={() => handleDialogClose(false)}>
                        Cancel
                    </Button>                 
                </Dialog.Footer>
            </Dialog>
        </div>
    );
};

export default SwitchRender;