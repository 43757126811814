import React from "react";
import { AgGridReact } from 'ag-grid-react';
import AuthService from "../services/authService";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { TextInput, Image, FormField, Layout, Page, Frame } from "@dentsu-ui/components";
import fallbackImage from '../assets/images/no-image.png'
import ClientService from "../services/clientService";
import FCCheckbox from "../components/FCCheckbox";
import AccountsEdit from "../components/AccountsEdit";
import Date from "../components/Date";
import banner from "../../src/assets/images/background/dentsu_website_headers_20.jpg"
import { secondaryAction } from "../helper/secondaryAction"
class UserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            clientNames: {},
            roles: {},
            firstName: "",
            lastName: "",
            title: "",
            email: "",
            channel: "",
            networkLogin: "",
            columnDefs: [
                {
                    headerName: "Access", width: '350%', cellRenderer: FCCheckbox
                },
                {
                    headerName: "Client", field: 'client', width: '550%'
                },
                {
                    headerName: "Role", field: 'role', width: '378%'
                },
            ],
            rowData: [
                {
                    "client": "Abercrombie & Fitc",
                    "role": "Admin",
                },
                {
                    "client": "Ann Taylor",
                    "role": "Admin",
                },
                {
                    "client": "Abercrombie & Fitc",
                },
                {
                    "client": "Allergro Medical",
                },
                {
                    "client": "Free People",
                },
            ],
            activityColumnDefs: [
                {
                    headerName: "IP", field: 'ip', width: '350%'
                },
                {
                    headerName: "Task", field: 'task', width: '550%'
                },
                {
                    headerName: "Date and Time (CT)", field: 'date', width: '378%', cellRenderer: Date
                },
            ],
            ActivityrowData: [
                {
                    "ip": "152.235.126.213",
                    "task": "Task CID 10292 - FTP Credentails",
                },
                {
                    "ip": "152.235.126.213",
                    "task": "Login",
                },
                {
                    "ip": "152.235.126.213",
                    "task": "Updated CID 10781 - Password",
                },
                {
                    "ip": "152.235.126.213",
                    "task": "Logged Out",
                },
                {
                    "ip": "152.235.126.213",
                    "task": "Login",
                },
                {
                    "ip": "152.235.126.213",
                    "task": "Login",
                },
            ]
        }
    }
    async getUser() {
        try {
            const user = AuthService.getUser();
            this.setState({ user: user });
        } catch (error) { }
    }
    componentDidMount() {
        this.getUser();
    }

   

    render() {
        const userDetails =
            <div className="px-5 p-5 flex flex-row flex-wrap flex-grow justify-between items-center">
                <div>
                    <FormField label="First Name">
                        <TextInput value={this.state.user.firstName || ''} onChange={(e) => { this.setState({ firstName: e.target.value }) }} />
                    </FormField>
                    <FormField label="Last Name">
                        <TextInput value={this.state.user.lastName || ''} onChange={(e) => { this.setState({ lastName: e.target.value }) }} />
                    </FormField>
                    <FormField label="Title">
                        <TextInput value={this.state.user.title || ''} onChange={(e) => { this.setState({ title: e.target.value }) }} />
                    </FormField>
                </div>
                <div>
                    <FormField label="Email">
                        <TextInput value={this.state.user.email || ''} onChange={(e) => { this.setState({ email: e.target.value }) }} />
                    </FormField>
                    <FormField label="Channel">
                        <TextInput value={this.state.user.channel || ''} onChange={(e) => { this.setState({ channel: e.target.value }) }} />
                    </FormField>
                    <FormField label="Network Login">
                        <TextInput value={this.state.user.login || ''} onChange={(e) => { this.setState({ networkLogin: e.target.value }) }} />
                    </FormField>
                </div>
                <div>
                    <FormField label="User Picture">
                        <Image className="h-28 w-25" src={undefined} fallbackSrc={fallbackImage} onChange={(e) => { this.setState({ name: e.target.value }) }}></Image>
                    </FormField>
                </div>
            </div>

        const clientsagGrid = <div style={{ height: '300px', width: '100%' }} className="ag-theme-alpine">
            <AgGridReact
                columnDefs={this.state.columnDefs}
                rowData={this.state.rowData}
            ></AgGridReact>
        </div>
        const activityLogGrid = <div style={{ height: '300px', width: '100%' }} className="ag-theme-alpine">
            <AgGridReact
                columnDefs={this.state.activityColumnDefs}
                rowData={this.state.ActivityrowData}
            ></AgGridReact>
        </div>
        return (
            <>
                <Page title="User Profile"
                    banner={banner}
                    secondaryActions={secondaryAction(this.props)}
           
                >
                    <div className="container w-full mx-auto">
                        <div className="w-full px-4 md:px-0 md:mt-8 mb-16 text-gray-800 leading-normal">
                            <Layout>
                                <Layout.Section>
                                    <Layout.Panel >
                                        {userDetails}
                                    </Layout.Panel>
                                </Layout.Section>
                            </Layout>

                            <Layout>
                                <Layout.Section isScrollable>
                                    <Layout.Panel title="Clients" variant="darker">
                                        {clientsagGrid}
                                    </Layout.Panel>
                                </Layout.Section>
                            </Layout>

                            <Layout>
                                <Layout.Section isScrollable>
                                    <Layout.Panel title="Activity Log" variant="darker">
                                        {activityLogGrid}
                                    </Layout.Panel>
                                </Layout.Section>
                            </Layout>
                        </div>
                    </div>
                </Page>
            </>
        )
    }
}

export default UserProfile