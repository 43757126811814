import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import Date from "./Date"


const RefreshContent = (props) => {
    const [date, setDate] = useState(new window.Date())
    const refresh = () => {
        setDate(new window.Date())
        props.onClick()
    }
    
    return (
        <>
            <i className='select-none banner'>
                <span>
                    Updated <Date value={date}></Date>
                </span>
                <span>
                    &nbsp; <FontAwesomeIcon className="cursor-pointer" icon={faRefresh} onClick={() => { refresh() }} />
                </span>
            </i>
        </>
    )
}
export default RefreshContent