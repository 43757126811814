import { Layout, Switch, FormField, Select, TextInput, Button, Toast, Checkbox, Box, Loading,IconButton } from "@dentsu-ui/components"
import { useState } from "react"
import ClientService from "../../services/clientService"
import AuthService from "../../services/authService";

const toast = Toast();

export default (props) => {

    const [loading, setLoading] = useState(false)
    const [testingConnection, settestingConnection] = useState(false)
    const [isExplisitUpload, setisExplisitUpload] = useState(props.value.isExplisitUpload)
    const [selectedStage, setSelectedStage] = useState({ name: props.value.isInternalCopy==true ?'InternalCopy' :(props.value.runThrough=='API'?'API':'FTP')})
    const [show, setShow] = useState(false);
    
   

    let protocols = [
        { name: 'FTP', id: "FTP" },
        { name: 'SFTP', id: 'SFTP' }
    ]

    let method = {
        hostUrl: props.value.hostUrl,
        userName: props.value.userName,
        password: props.value.password,
        protocol: props.value.isExplisitUpload ? protocols[1].id : protocols[0].id,
        isExplisitUpload: props.value.isExplisitUpload,
        port: props.value.port,
        ftpUploadKey: props.value.ftpUploadKey,
        isEnabled: props.value.isEnabled,
        apiToken: props.value.apiToken,
        directory: props.value.directory,
        internalLocationPath:props.value.internalLocationPath,

        apiClientId:props.value.api_client_id,
        apiClientSecret:props.value.api_client_secret,
        refreshToken:props.value.refresh_token,
        merchantId:props.value.merchant_id,
        recordId:props.value.recordId

    }
    const [newProps, setnewProps] = useState({ data:method});
    const [selectedProtocol, setselectedProtocol] = useState({ name:props.value.port=="21"?"FTP":"SFTP",id:props.value.port=="21"?"FTP":"SFTP" })
    
    let stages = [
        { id: 2001, name: "FTP" },
        { id: 2002, name: "API" },
        { id: 2003, name: "InternalCopy" }
    ]

    const testConnection = async () => {
        settestingConnection(true)
        let response = await AuthService.loginFTP(newProps.data.hostUrl, newProps.data.userName, newProps.data.password,selectedProtocol.name)
        
        if (!response.data) {
            toast({
                title: "Failed",
                content: "Unable to verify account information.",
                status: "error"
            })
        } else {
            toast({
                title: "Verified",
                content: "We've verified your account.",
                status: "success"
            })
        }
        settestingConnection(false)
    }

    const saveDeliveryMethod = async () => {        

        let payload = {
            id: newProps.data.ftpUploadKey,
            accountKey: props.vendor.id,
            clientId: props.client,
            vendorKey: props.vendor.vendorId,
            userName: newProps.data.userName,
            port: newProps.data.port,
            isExplisitUpload: isExplisitUpload,
            password: newProps.data.password,
            hostUrl: newProps.data.hostUrl,
            apiToken: newProps.data.apiToken,
            isEnabled: newProps.data.isEnabled,
            directory: newProps.data.directory,
            internalLocationPath:newProps.data.internalLocationPath,
            isInternalCopy:selectedStage.name=="InternalCopy"?1:0,
            RunThrough:selectedStage.name,

            apiClientId:newProps.data.apiClientId,
            apiClientSecret:newProps.data.apiClientSecret,
            refreshToken:newProps.data.refreshToken,
            merchantId:newProps.data.merchantId,
            recordId:newProps.data.recordId
        }

                
        setLoading(true)
        
        if (newProps.data.ftpUploadKey > 0) {
            console.log('update')
            try {
                await ClientService.updateDeliveryMethod(payload)
                setselectedProtocol({name:payload.port=="21"?"FTP":"SFTP",id:payload.port=="21"?"FTP":"SFTP"})
                toast({ title: "Success", content: "Updated delivery method", status: "success" })

            } catch (error) {
                toast({ title: "Error", content: error, status: "error" })
            } finally {
                setLoading(false)
            }
        } else {
            console.log('save')
            try {
                let response = await ClientService.addDeliveryMethod(payload)
                setnewProps({ data:response.data});                  
                toast({ title: "Success", content: "Created delivery method", status: "success" })


            } catch (error) {
                toast({ title: "Error", content: error, status: "error" })
            } finally {
                setLoading(false)
            }
        }
    }

    if (loading) {
        return (
            <>
                <Box className="flex items-center justify-center">
                    <Loading>Please wait...</Loading>
                </Box>
            </>
        );
    }

    return <>
        <Layout.Panel title="Delivery Method" variant="darker">
            <div>
                <div className="px-5 flex flex-row flex-wrap flex-grow justify-between items-center" key={'deliveryMethod'}>
                    <div>
                        {
                            selectedStage.name != 'API' ?
                            <Switch
                                defaultIsChecked={newProps.data.isEnabled}
                                onChange={e => newProps.data.isEnabled = e.target.checked}
                            /> 
                            :'' 
                        } 
                        <br />                         
                    </div>
                    <div>
                        <FormField label="Delivery Method">
                            <div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center pb-4">
                                <Select options={stages}
                                    maxMenuHeight="50"
                                    menuPlacement='bottom'
                                    width={200}
                                    customLabelKey="name"
                                    customValueKey="id"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.name}
                                    onChange={(selected, event) => {
                                        method.selectedMethod = selected
                                        setSelectedStage(selected)
                                        if (selected.name == 'API') {
                                            newProps.data.port = null
                                        } else {
                                            newProps.data.apiToken = null
                                        }
                                    }}
                                    value={selectedStage}
                                />
                            </div>
                        </FormField>
                    </div>
                </div>
                <div className="px-10">
                    <Layout.Panel title="Setup">
                        <div>
                            <div>
                                {
                                    selectedStage.name != 'API' && selectedStage.name != 'InternalCopy'
                                        ? <div className="px-10 flex flex-row flex-wrap flex-grow justify-between items-center">
                                            <div>
                                                <FormField label="Protocol" >
                                                    <Select width={200}
                                                        options={protocols}
                                                        customLabelKey="name"
                                                        customValueKey="id"
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.name}
                                                        onChange={(selected) => {
                                                            newProps.data.protocol = selected
                                                            setselectedProtocol(selected)
                                                        }}
                                                        value={selectedProtocol}
                                                        
                                                    />
                                                </FormField>
                                            </div>
                                            <div>
                                                <FormField label="Port">
                                                    <TextInput
                                                        id={newProps.data.port}
                                                        placeholder="port"
                                                        defaultValue={newProps.data.port}
                                                        onChange={(e) => {
                                                            newProps.data.port = e.target.value
                                                        }} />
                                                </FormField>
                                            </div>
                                            <div>
                                                <FormField>
                                                    <Checkbox size="small"
                                                        isChecked={isExplisitUpload}
                                                        onChange={event => setisExplisitUpload(!isExplisitUpload)}>
                                                        Explicit Upload
                                                    </Checkbox>
                                                </FormField>
                                            </div>
                                        </div>
                                        : ''
                                }
                            </div>
                            <div className="px-10 flex flex-row flex-wrap flex-grow justify-between items-center">
                                <div>
                                    {
                                      selectedStage.name != 'InternalCopy' && selectedStage.name != 'API' 
                                      ? <FormField label="Host Name">
                                        <TextInput
                                            placeholder="Host name"
                                            defaultValue={newProps.data.hostUrl}
                                            onChange={(e) => {
                                                newProps.data.hostUrl = e.target.value
                                            }} />
                                    </FormField>
                                    : selectedStage.name == 'API'
                                    ?<FormField label="Merchant Id">
                                    <TextInput
                                        placeholder="Merchant Id"
                                        defaultValue={newProps.data.merchantId}
                                        onChange={(e) => {
                                            newProps.data.merchantId = e.target.value
                                        }} />
                                    </FormField>
                                    :''
                                    }
                                </div>
                                <div>
                                    {
                                        selectedStage.name != 'API' && selectedStage.name != 'InternalCopy' ?
                                            <FormField label="Directory">
                                                <TextInput
                                                    placeholder="Remote Directory"
                                                    defaultValue={newProps.data.directory}
                                                    onChange={(e) => {
                                                        newProps.data.directory = e.target.value
                                                    }} />
                                            </FormField> : selectedStage.name == 'API'
                                            ? <FormField label="API Client Id">
                                                <TextInput
                                                placeholder="API Client Id"
                                                defaultValue={newProps.data.apiClientId}
                                                onChange={(e) => {
                                                    newProps.data.apiClientId = e.target.value
                                                }} />
                                            </FormField>                                            
                                            :''
                                    }
                                </div>
                                
                            </div>
                        </div>
                        <div>
                            {
                                selectedStage.name == 'API'
                                    ? <div className="px-10 flex flex-row flex-wrap flex-grow justify-between items-center">
                                        <div>
                                        <FormField label="API Client Secret">                                            
                                            <TextInput 
                                                placeholder="API Client Secret"
                                                type={show ? 'text' : 'password'} 
                                                defaultValue={newProps.data.apiClientSecret}
                                                onChange={(e) => {
                                                    newProps.data.apiClientSecret = e.target.value
                                                }}
                                                rightElement={<IconButton variant="ghost" 
                                                    size="small"
                                                    onClick={() => setShow(!show)}                                                    
                                                    icon={show ? 'eye-open' : 'eye-off'} />
                                                } />                                            
                                        </FormField>
                                        </div>

                                        <div>
                                        <FormField label="Refresh Token">
                                            <TextInput
                                                placeholder="Refresh Token"
                                                defaultValue={newProps.data.refreshToken}
                                                onChange={(e) => {
                                                    newProps.data.refreshToken = e.target.value
                                                }} />
                                        </FormField>
                                        </div>

                                    </div>
                                    : ''
                            }
                        </div>
                        <div>
                            {
                                selectedStage.name != 'InternalCopy' && selectedStage.name != 'API' 
                                ? <div className="px-10 flex flex-row flex-wrap flex-grow justify-between items-center">
                           
                                <div>
                                    <FormField label="User Name">
                                        <TextInput
                                            placeholder="username"
                                            defaultValue={newProps.data.userName}
                                            onChange={(e) => {
                                                newProps.data.userName = e.target.value
                                            }} />
                                    </FormField>
                                </div>
                                <div>
                                    <FormField label="Password">
                                            <TextInput 
                                                placeholder="Password"
                                                type={show ? 'text' : 'password'} 
                                                defaultValue={newProps.data.password}
                                                onChange={(e) => {
                                                    newProps.data.password = e.target.value
                                                }}
                                                rightElement={<IconButton variant="ghost" 
                                                    size="small"
                                                    onClick={() => setShow(!show)}                                                    
                                                    icon={show ? 'eye-open' : 'eye-off'} />
                                                } />
                                    </FormField>
                                </div>
                                </div>
                            :''
                            }
                        </div>
                        
                        
                        <div>
                            {
                                selectedStage.name == 'InternalCopy'
                                ? <div className="px-10 flex flex-row flex-wrap flex-grow justify-between items-center">
                                <div>
                                    <FormField label="Internal Location Path">
                                        <TextInput
                                            placeholder="Internal Location Path"
                                            defaultValue={newProps.data.internalLocationPath}
                                            width={200}
                                            onChange={(e) => {
                                                newProps.data.internalLocationPath = e.target.value
                                            }} />
                                    </FormField>
                                </div>
                                <div>                               
                                </div>
                            </div>
                                :''
                            }
                        </div>

                       
                    </Layout.Panel>
                </div>
                <br />
                <div className="px-5 flex flex-row flex-wrap flex-grow justify-end items-center">
                    <Button variant="tertiary" size="small" onClick={testConnection}
                        isLoading={testingConnection}
                        isDisabled={selectedStage.name == 'API' || selectedStage.name == 'InternalCopy'}
                        title="Test FTP/SFTP Connection"
                    >Test Connection</Button> &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="tertiary" size="small" onClick={saveDeliveryMethod}>Save</Button>
                </div>
            </div>
        </Layout.Panel>
    </>
}