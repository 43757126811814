import React from "react";
import { Page, Stack, Caption, Divider } from "@dentsu-ui/components";
import RefreshContent from "../../components/RefreshContent";
import ThemeSwitch from "../../components/ThemeSwitch";
import banner from "../../assets/images/background/dentsu_website_headers_4.jpg"
import thumbnail from "../../assets/images/FC-Logo-Icon.png"
import { secondaryAction } from "../../helper/secondaryAction"
class Health extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <Page title="Health"
                banner={banner}
                thumbnail={thumbnail}
                availableSpace={
                    <Stack alignItems="center">
                        <Caption as="span" isSecondary className="white">
                            <RefreshContent onClick={() => { "" }}></RefreshContent>
                        </Caption>
                        <Divider isFlexChild orientation="vertical" />
                        <Caption as="span" isSecondary className="white">
                            <ThemeSwitch>{this.props.theme}</ThemeSwitch>
                        </Caption>
                    </Stack>
                }
                secondaryActions={secondaryAction(this.props)}
            >
                <br />
                Account Health
            </Page>
        )
    }
}

export default Health