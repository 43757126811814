import React, { useState, useMemo, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import AgGridModal from '../AgGridModal';
import { FormatNumber } from '@dentsu-ui/components';
import Date from '../Date';
import Vendor from './vendor';
import ExportVendorDetail from './exportvendorDetail';                                                  

const Exports = (props) => {
    const markets = useSelector(state => state.markets.value)
    const gridRef = useRef();
    const [rowData] = useState(props.data);

    const defaultColDef = { resizable: true, flex: 1, autoHeight: true, filter: true, sortable: true }

    const getColumns = () => {
        if (props.data && props.data.length > 0) {
            let keys = Object.keys(props.data[0])
            let columns = []
            keys.filter(x => !['fileName', 'account'].includes(x)).forEach(key => {
                let column = { field: key, cellClass: 'cell-wrap-text' }
                switch (key) {
                    case "vendor": column = { ...column, headerName: 'Partner', cellRenderer: ExportVendorDetail, minWidth: 250 }; break;
                    case "deliveredCount": column = { ...column, cellRenderer: FormatNumber }; break;
                    case "deliveredTime": column = { ...column, headerName: 'Delivered Time (CT)', cellRenderer: Date, minWidth: 180 }; break;
                    case "type": column = { ...column, cellClass: 'capitalize', maxWidth: 100 }; break;
                    case "status":
                        column = {
                            ...column, cellClass: 'capitalize', cellRenderer: AgGridModal,
                            cellRendererParams: { title: 'status', type: '' }
                        }; break;
                    case "dodChange": column = { ...column, headerName: 'DoD Change' }; break;
                    case "client": column = {
                        ...column, cellClass: 'capitalize',
                        cellRenderer: params => {
                            return params.value + ' - ' + getClientName(params.value)
                        }
                    }; break;
                    default:
                        break;
                }
                columns.push(column)
            });
            return columns
        }
        return []
    };

    const getClientName = (id) => {
        let client = markets.filter((market) => market.id == id)[0] || {}
        return client.name
    }

    const onViewportChanged = useCallback((skipHeader = false) => {
        const allColumnIds = [];
        gridRef.current.columnApi.getAllColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }, []);

    return (
        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
            <AgGridReact ref={gridRef} rowData={rowData} columnDefs={getColumns()} defaultColDef={defaultColDef}
                maintainColumnOrder={true}>
            </AgGridReact>
        </div>
    );
};

export default Exports