import { Page, Layout, Select } from "@dentsu-ui/components";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState, useRef } from "react";
import Status from "../../components/Status";
import AccountService from "../../services/AccountService";
import ClientService from "../../services/clientService";
import banner from "../../assets/images/background/dentsu_website_headers_17.jpg"
import thumbnail from "../../assets/images/FC-Logo-Icon.png"
import { secondaryAction } from "../../helper/secondaryAction";
const ClientProfile = (props) => {
    const importGridRef = useRef();
    const importMappingGridRef = useRef();

    const exportGridRef = useRef();
    const exportMappingGridRef = useRef();

    const [clients, setClients] = useState([])
    const [accounts, setAccounts] = useState([])
    const [selectedMarket, setSelectedMarket] = useState({ id: 0 })
    const [selectedAccount, setSelectedAccount] = useState()
    const [importMapping, setImportMapping] = useState([])
    const [importConfig, setImportConfig] = useState([])
    const [exportMapping, setExportMapping] = useState([])
    const [exportConfig, setExportConfig] = useState([])
    const importMappingColumns = [
        { headerName: 'Client Columns', field: 'sourceField' },
        { headerName: 'FeedConnect Columns', field: 'destinationField' }
    ]

    const importConfigColumns = [
        { headerName: 'Client ID', field: 'clientId' },
        { field: 'feedType', cellClass: 'capitalize' },
        { field: 'fileName' },
        { field: 'cadence' }
    ]

    const exportConfigColumns = [
        { field: 'accountName' },
        { field: 'accountKey', headerName: 'Account ID' },
        { field: 'vendorKey', headerName: ' Partner ID' },
        { field: 'exportFileName', headerName: 'File Name' },
        { field: 'delimiter', headerName: 'Delimiter' },
        { field: 'encoding', headerName: 'Encoding' },
        //{ field: 'Delivery Method', headerName: 'Delivery Method' },
        { field: 'isActive', headerName: 'Status', cellRenderer: Status }
    ]

    const exportMappingColumns = [
        { headerName: 'FeedConnect Columns', field: 'sourceField' },
        { headerName: 'Partner Columns', field: 'destinationField' }
    ]
    const defaultColDef = { resizable: true, flex: 1, filter: true, sortable: true, autoHeight: true };

    useEffect(() => {
        async function getClients() {
            let clients = await ClientService.getClients()
            let markets = await ClientService.getMarkets()
            let accounts = await AccountService.getAccounts()
            setAccounts(accounts.data)

            clients.data.forEach(client => {
                client.label = client.name
                client.options = markets.data.filter(x => x.parentId == client.id)
                client.options.forEach(option => {
                    option.label = option.name
                });
            });
            setClients(clients.data)
        }
        getClients()
    }, [])

    useEffect(() => {
        if(selectedMarket.id == 0)
        {
            document.title = `Client Profile`
        }
        else{
            document.title = `Client Profile (${selectedMarket.id}) `
        }
       
        async function getClientMapping() {
            if (selectedMarket && selectedMarket.id) {
                importMappingGridRef.current.api.showLoadingOverlay();
                exportGridRef.current.api.showLoadingOverlay();
                importGridRef.current.api.showLoadingOverlay();
                let importResponse = await ClientService.getImportDetails(selectedMarket.id)
                setImportMapping(importResponse.data.mapping)
                setImportConfig(importResponse.data.config)
                setExportConfig(importResponse.data.exportConfig)
                importGridRef.current.api.hideOverlay();
                importMappingGridRef.current.api.hideOverlay();
                exportGridRef.current.api.hideOverlay();
            }
        }
        //setSelectedAccount({})
        getClientMapping()
    }, [selectedMarket])

    useEffect(() => {
        async function getExportMappings() {
            if (selectedAccount && selectedAccount.id) {
                exportMappingGridRef.current.api.showLoadingOverlay();
                let exportResponse = await ClientService.getExportMappings(selectedAccount.id, selectedAccount.vendor)
                setExportMapping(exportResponse.data)
                exportMappingGridRef.current.api.hideOverlay();
            }
        }
        getExportMappings()
    }, [selectedAccount])

    return (
        <>
            <Page title="Client Profile"
                banner={banner}
                thumbnail={thumbnail}
                secondaryActions={secondaryAction(props)}
            >
                <br />
                <Layout>
                    <Layout.Section>
                        <div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center pb-4">
                            <label>Client </label>
                            <Select
                                width={250}
                                options={clients}
                                customLabelKey="name"
                                customValueKey="id"
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(selected, event) => {
                                    setSelectedMarket(selected)
                                }} value={selectedMarket} />
                        </div>
                    </Layout.Section>
                </Layout>

                <Layout>
                    <Layout.Section>
                        <Layout.Panel title="Import Details" variant="darker">
                            <label>General Import Information</label>
                            <div className="ag-theme-alpine" style={{ height: '400px', width: '100%' }}>
                                <AgGridReact ref={importGridRef}
                                    rowData={importConfig}
                                    columnDefs={importConfigColumns}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    paginationPageSize={25}
                                >
                                </AgGridReact>
                            </div>

                            <br />
                            <label>Import Mapping</label>
                            <div className="ag-theme-alpine" style={{ height: '400px', width: '100%' }}>
                                <AgGridReact ref={importMappingGridRef}
                                    rowData={importMapping}
                                    columnDefs={importMappingColumns}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    paginationPageSize={25}
                                >
                                </AgGridReact>
                            </div>
                        </Layout.Panel>
                    </Layout.Section>
                </Layout>

                <Layout>
                    <Layout.Section>
                        <Layout.Panel title="Export Details" variant="darker">
                            <label>Export File Details</label>
                            <div className="ag-theme-alpine" style={{ height: '400px', width: '100%' }}>
                                <AgGridReact ref={exportGridRef}
                                    rowData={exportConfig}
                                    columnDefs={exportConfigColumns}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    paginationPageSize={25}
                                >
                                </AgGridReact>
                            </div>

                            <div className="flex flex-row flex-wrap flex-grow justify-end space-x-4 items-center pb-4 pt-4">
                                <label>Partner </label>
                                <Select
                                    width={250}
                                    options={accounts.filter(x => x.client == selectedMarket.id)}
                                    customLabelKey="name"
                                    customValueKey="id"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    onChange={(selected, event) => {
                                        setSelectedAccount(selected)
                                    }} value={selectedAccount} />
                            </div>

                            <label>Export Mappings</label>
                            <div className="ag-theme-alpine" style={{ height: '400px', width: '100%' }}>
                                <AgGridReact ref={exportMappingGridRef}
                                    rowData={exportMapping}
                                    columnDefs={exportMappingColumns}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    paginationPageSize={25}
                                >
                                </AgGridReact>
                            </div>
                        </Layout.Panel>
                    </Layout.Section>
                </Layout>
            </Page>
        </>
    )
}

export default ClientProfile