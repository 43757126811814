import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box ,Toast} from "@dentsu-ui/components";
import { faDownload, faHeartbeat, faPaperPlane, faRuler, faPencil } from "@fortawesome/free-solid-svg-icons";
import ClientService from "../services/clientService";

const toast = Toast();

export default class AccountsEdit extends React.Component {
    constructor(props) {
        super(props);
    }

    navigateToHealth() {
        window.location.href = `/accounts/${Number(this.props.value)}/health`
    }
    navigateToManangement() {
        window.location.href = `/accounts/${this.props.data.clientId}/management/${Number(this.props.value)}`
        //window.location.href = `/accounts/${Number(this.props.value)}/management`
    }
    navigateToDelivery() {
        window.location.href = `/accounts/${this.props.data.clientId}/management/${Number(this.props.value)}`
    }
    navigateToLogic() {
        window.location.href = `/accounts/${this.props.data.clientId}/logic/${Number(this.props.value)}`
    }
    render() {

       const downloadLatestFeed = () => {
            ClientService.getLatestExport(this.props.data.clientId, this.props.value)
                .then((response) => {
                    if (response.data != null && response.data != "") {
                        let filepath;
                        filepath = response.data.filePath
                        window.open(filepath, '_blank', 'noopener,noreferrer');
                    } else {
                        toast({ title: "Error", content: "File is missing", status: "error" })
                    }
                }
                )
        }
        return (
            <div>
                <Box className="flex items-center justify-start cursor-pointer" height="100%">
                    <FontAwesomeIcon size='2x' className="fa-solid fa-heart" title="Health" icon={faHeartbeat} onClick={() => { this.navigateToHealth() }} /> &nbsp;
                    <FontAwesomeIcon size='2x' className="fa-solid fa-edit" title="Management" icon={faPencil} onClick={() => { this.navigateToManangement() }} /> &nbsp;
                    <FontAwesomeIcon size='2x' className="fa-solid fa-download" title="Download" icon={faDownload} onClick={() => downloadLatestFeed()} /> &nbsp;
                    {/* <FontAwesomeIcon size='2x' className="fa-solid fa-send" title="Delivery" icon={faPaperPlane} onClick={() => { this.navigateToDelivery() }} /> &nbsp; */}
                    <FontAwesomeIcon size='2x' className="fa-solid fa-ruler" title="Logic" icon={faRuler} onClick={() => { this.navigateToLogic() }} />
                </Box>
            </div>
        )
    }
}