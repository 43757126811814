import Client from './client';
import Vendor from './vendor';

const ClientVendor = (props) => {
    return (
        <>
            {
                props.data.errorType == 'import' ? <Client value={props.data.id}></Client> : <Vendor value={props.data}></Vendor>
            }
        </>
    )
}

export default ClientVendor;