import React, { useState, useMemo, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Date from '../Date';
import { FormatNumber } from '@dentsu-ui/components';
import Client from './client';
import AgGridModal from '../AgGridModal';

const Imports = (props) => {
    const gridRef = useRef();
    const [rowData] = useState(props.data);

    const defaultColDef = { resizable: true, flex: 1, autoHeight: true, filter: true, sortable: true };

    const getColumns = () => {
        if (props.data && props.data.length > 0) {
            let keys = Object.keys(props.data[0])
            let columns = []
            keys.filter(x => ['queueKey', 'error', 'fileName'].indexOf(x) == -1).forEach(key => {
                let column = { field: key, cellClass: 'cell-wrap-text' }
                switch (key) {
                    case "clientId": column = { ...column, cellRenderer: Client, headerName: 'Client', minWidth: 250 }; break;
                    case "receivedTime": column = { ...column, headerName: 'Received Time (CT)', cellRenderer: Date, minWidth: 180 }; break;
                    case "importCount": column = { ...column, cellRenderer: FormatNumber }; break;
                    case "type": column = { ...column, sortable: true, cellClass: 'capitalize', maxWidth: 150 }; break;
                    case "dodChange": column = { ...column, headerName: 'DoD Change' }; break;
                    case "status":
                        column = {
                            ...column, cellClass: 'capitalize', cellRenderer: AgGridModal,
                            cellRendererParams: { title: 'status', type: '' },
                            width: 100
                        }; break;
                    default:
                        break;
                }
                columns.push(column)
            });
            return columns
        }
        return []
    };

    const onViewportChanged = useCallback((skipHeader = false) => {
        const allColumnIds = [];
        // gridRef.current.columnApi.getAllColumns().forEach((column) => {
        //     allColumnIds.push(column.getId());
        // });
        // gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }, []);

    return (
        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
            <AgGridReact ref={gridRef} rowData={rowData} columnDefs={getColumns()} defaultColDef={defaultColDef} onViewportChanged={onViewportChanged}>
            </AgGridReact>
        </div>
    );
};

export default Imports