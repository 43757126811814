import http from "../helper/http-common";
import AuthService from "./authService";

export default class zenDeskService {
    static getTickets(parameters) {
        return http
            .get(`GetAllTickets`, {
                params: { parameters },
                headers: { Authorization: `bearer ${AuthService.getToken()}` },
            })
            .catch(function (error) {
                return Promise.reject(error)
            });
    }

    static getTicketDetails(id) {
        return http
            .get(`tickets/${id}`, { headers: { Authorization: `bearer ${AuthService.getToken()}` }, })
            .catch(function (error) { return Promise.reject(error) });
    }
}