import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";

import AuthService from "./services/authService";

import Layout from "./components/layout/Layout";
import Accounts from "./pages/accounts/index_old";
import ProductData from "./pages/ProductData";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import AccountsManagement from "./pages/accounts/setup";
import Import from "./pages/data";
import ClientOnboarding from "./pages/admin/ClientOnboarding"
import CategoryMapping from "./pages/admin/CategoryMapping";
import AccountMarket from "./pages/accounts/markets";
import Accounts1 from "./pages/accounts/Accounts";
import UserProfile from "./pages/Userprofile";
import Delivery from "./pages/accounts/Delivery";
import DataExplorer from "./pages/data/explorer";
import "@dentsu-ui/components/styles.css";
import "./App.css";
import { Frame, Navigation, Page,Loading} from "@dentsu-ui/components";
import UserManagement from "./pages/admin/UserManagement";
import ItemLookUp from "./pages/data/ItemLookUp";
import Logic from "./pages/accounts/logic/Logic";
import Health from "./pages/accounts/Health";
import ClientProfile from "./pages/accounts/ClientProfile";
import Users from "./pages/admin/Users";
import Clients from "./pages/admin/Clients";
import Partners from "./pages/admin/Partners";
import Suppression from "./pages/accounts/logic/supressionLogic";
import Zendeskdetails from "./pages/Zendesk-Details/Zendesk-details";
import TicketDetailsReference from "./components/zendesk/zendeskTicketReference";

const App = (props) => {
  const [isUserAuthenticated, setisUserAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  console.log(window.location.pathname)
  const loadData = async () => {
    const user = await AuthService.getUser();
    if (user === null)
      setisUserAuthenticated(false);
    else
      setisUserAuthenticated(true);

    setIsLoading(false);
  }

  const getDataSubMenus = () => {
    let pathname = window.location.pathname
    let dataMenu = {
      label: 'Data',
      //url: '/data/import',
      items: [
        { label: 'Item Lookup', url: '/data/item-lookup' },
        { label: 'Item Dataset', url: '/data/import' },
        { label: 'Data Explorer', url: '/data/explorer' },
      ]
    }
    return dataMenu
    let clientId = 0
    switch (pathname) {
      case '/':
        return dataMenu
      case pathname.match(/^\/data\/import$/)?.input:
        return {
          ...dataMenu,
          items: [
            { label: 'Item Lookup', url: '/data/item-lookup' },
            { label: 'Item Dataset', url: '/data/import' },
            { label: 'Data Explorer', url: '/data/explorer' },
            { label: 'GTIN Lookup' }
          ]
        }
      default:
        return {
          ...dataMenu
        }
    }
  }

  const getAccountSubMenus = () => {
    let pathname = window.location.pathname
    let accountMenu = { label: 'Accounts', url: '/accounts/markets' }
    let clientId = 0
    switch (pathname) {
      case '/':
      case '/accounts/markets':
        return accountMenu
      case pathname.match(/^\/markets\/[0-9]*\/accounts$/)?.input:
      case pathname.match(/^\/accounts\/[0-9]*\/logic*/)?.input:
      case pathname.match(/^\/accounts\/[0-9]*\/category-mapping*/)?.input:
        clientId = pathname.split('/')[2]
        return {
          ...{ label: 'Accounts', url: '' },
          items: [
            { label: 'Health', url: `/accounts/${clientId}/health` },
            { label: 'Logic', url: `/accounts/${clientId}/logic`, },

            { label: '> Populate Logic' },
            { label: '> Suppression Logic', url: `/accounts/${clientId}/logic/supression` },
            { label: '> Custom Label' },
            { label: '> Item Threshold' },
            { label: '> URL Optimization' },
            { label: '> Title Optimization' },
            { label: '> Product Type' },

            { label: 'Management', url: `/accounts/${clientId}/setup` },
            { label: 'Client Profile', url: '/accounts/client-profile' },
            { label: 'Category Mapping', url: `/accounts/${clientId}/category-mapping` }
          ]
        }
      // case pathname.match(/^\/accounts\/[0-9]*\/logic*/)?.input:
      //   clientId = pathname.split('/')[2]
      //   return {
      //     ...accountMenu,
      //     items: [
      //       { label: 'All Logic', url: `/accounts/${clientId}/logic` },
      //       { label: 'Populate Logic' },
      //       { label: 'Suppression Logic', url: `/accounts/${clientId}/logic/supression` },
      //       { label: 'Custom Label' },
      //       { label: 'Item Threshold' },
      //       { label: 'URL Optimization' },
      //       { label: 'Title Optimization' },
      //       { label: 'Product Type' },
      //       { label: 'Category Mapping', url: `/accounts/${clientId}/category-mapping` },
      //     ]
      //   }
      default:
        return {
          ...accountMenu
        }
    }
  }

  // {
  //   ...accountMenu,
  //   items:
  //     [
  //       { label: 'Delivery', url: '/accounts/delivery' },
  //       { label: 'Health', url: '/accounts/health' },
  //       {
  //         label: 'Logic', url: '/accounts/all/logic',
  //         label: 'Suppression Logic', url: '/accounts/all/logic/supression',
  //         items: [
  //           { label: 'Custom Label' },
  //           { label: 'Item Threshold', url: '/data/explore' },
  //           { label: 'URL Optimization' },
  //           { label: 'Time Optimization' },
  //           { label: 'Product Type' },
  //           { label: 'Category Mapping' }
  //         ]
  //       },
  //       { label: 'Management', url: '/accounts/management' },
  //       { label: 'Client Profile', url: '/accounts/client-profile' },
  //       { label: 'Category Mapping', url: '/accounts/10801/category-mapping' }
  //     ]
  // }

  useEffect(() => {
    loadData()
  }, [])

  if (isLoading) {
    return  <Loading>One Moment Please...</Loading>;
  }

  const classes = "App font-sans leading-normal tracking-normal" + (isUserAuthenticated ? ' bg-gray-100' : '')
  const login =
    <Switch>
      <Login />
    </Switch>

  if (!isUserAuthenticated) {
    return (
      <div className={classes}>
        <BrowserRouter>
          {login}
        </BrowserRouter>
      </div>
    );
  }

  const navigation = <Navigation>
    <Navigation.Section
      items={[
       // { label: 'Home', url: '/' },
      //  getAccountSubMenus(),
      //  getDataSubMenus(),
        // { label: 'Reports', url: '/reports', isDisabled: true },
        // { label: 'Analytics', url: '/analytics', isDisabled: true },
        // {
        //   label: 'Admin',
        //   items: [
        //     { label: 'Users', url: '/admin/users' },
        //     { label: 'Clients', url: '/admin/clients' },
        //     { label: 'Partners', url: '/admin/partners' },
        //   ]
        // },
        { label: 'Zendesk', url: '/' },
      ]} />
  </Navigation>;
  return (
    <Frame navigation={navigation}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Zendeskdetails} />
          <Route exact path="/accounts/markets" key="AccountMarket" component={AccountMarket} />
          <Route exact path="/accounts/client-profile" component={ClientProfile} />
          <Route exact path="/markets/:id/accounts" component={Accounts1} />
          {/* <Route exact path="/accounts" component={Accounts} /> */}
          <Route path="/profile" component={Profile} />
          {/* <Route path='/admin/user-mgmt' component={UserManagement} />ss */}
          <Route exact path="/accounts/:id/setup" component={AccountsManagement} />
          <Route exact path="/accounts/:id/management/:account" component={Delivery} />
          <Route exact path="/accounts/:id/health" component={Health} />
          <Route exact key="accounts-logic-supression" path="/accounts/:id/logic/supression" component={Suppression} />
          <Route exact path="/accounts/:id/logic/:account?" component={Logic} />
          <Route exact path="/accounts/:id/category-mapping" component={CategoryMapping} />
          <Route key="data-import" exact path="/data/import" component={Import} />
          <Route key="data-export" exact path="/data/export" component={Import} />
          <Route key="data-transform" exact path="/data/transform" component={Import} />
          <Route key="data-historical" exact path="/data/historical" component={Import} />
          <Route key="data-itemLookup" exact path="/data/item-lookup" component={ItemLookUp} />
          <Route exact key="data-explore" path="/data/explorer" component={DataExplorer} />
          <Route path="/admin/client-onbording" component={ClientOnboarding} />

          <Route path="/admin/users" component={Users} />
          <Route path="/admin/clients" component={Clients} />
          <Route path="/admin/partners" component={Partners} key="Partners" />
          <Route path="/user-profile" component={UserProfile} />
          {/* <Route path="/tickets" component={Zendeskdetails} /> */}
          <Route path="/ticketdetails" component={TicketDetailsReference} />

        </Switch>
      </BrowserRouter>
    </Frame>
  );
}

export default App;
