import React, { useState, useEffect, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Page, Layout, List2, Box, Stack, Checkbox, Spacer, EmptyState, Paragraph, Avatar, Chip, Caption, Divider, Loading } from '@dentsu-ui/components';
import { TableNext, Link, Modal, Button } from '@dentsu-ui/components';
import RefreshContent from '../../components/RefreshContent'
import ThemeSwitch from '../../components/ThemeSwitch'
import thumbnail from "../../assets/images/FC-Logo-Icon.png"
import banner from "../../assets/images/background/dentsu_website_headers_16.jpg"
import ZendeskModal from './ZendeskModal';
import zenDeskService from '../../services/zenDeskService';
import UtilityService from '../../services/UtilityService';
import Date from '../../components/Date';
import AgGridModal from '../../components/AgGridModal';
import tooltip from '../../components/tooltip';
import TicketDetails from '../../components/zendesk/ticketDetails';
import { secondaryAction } from "../../helper/secondaryAction"

function Zendeskdetails(props) {
  const gridRef = useRef();
  let gridApi = {}
  let gridColumnApi = {}
  const [showGrid, setshowGrid] = useState(false)
  const [filters, setFilters] = useState({})
  const [tickets, setTickets] = useState([])
  const [users, setUsers] = useState([])
  const [totalTickets, setTotalTickets] = useState(0)
  const defaultColDef = {
    resizable: true, flex: 1, filter: true, sortable: true, autoHeight: true,
    tooltipComponent: tooltip,
    minWidth: 100
  };

  const loadingOverlayComponentParams = {
    children: 'One moment please...',
  }


  useEffect(() => {
    document.title = `Zendesk Tickets`
    setshowGrid(true)
  }, [])

  const getColumns = () => {   
    let keys = ['id', 'subject', 'requesterId', 'client', 'partner',
      //'status',
      'type', 'description', 'priority', 'completionDate', 'sourceOfRequest',
      'assigneeId']
    //Object.keys(tickets[0])
    let columns = []
    keys
      .forEach(key => {
        let column = { field: 'ticket.' + key, cellClass: 'cell-wrap-text' }
        switch (key) {
          case "id": column = { ...column, headerName: 'ID', cellClass: 'capitalize', maxWidth: 90, minWidth: 90, cellRenderer: TicketDetails,filter: true ,filterParams: { filterOptions: ['equals'] }}; break;
          case "subject": column = { ...column, headerName: 'Title', cellClass: 'capitalize' }; break;
          case "requesterId": column = { ...column, field: 'requester.name', headerName: 'Requestor', minWidth: 125 }; break;
          case "client": column = { ...column, field: 'client.value', headerName: 'Client(s)', minWidth: 100 }; break;
          case "partner": column = { ...column, field: 'partner.value', headerName: 'Partner(s)', minWidth: 100 }; break;
          case "type": column = { ...column, headerName: 'Type', cellClass: 'capitalize', maxWidth: 100 }; break;
          case "description": column = { ...column, headerName: 'Description', minWidth: 100, cellStyle: { 'display': 'block' }, }; break;
          case "priority": column = { ...column, headerName: 'Priority', maxWidth: 100, cellClass: 'capitalize' }; break;
          case "completionDate": column = { ...column, field: 'completionDate.value', headerName: 'Closed Date (CT)', cellRenderer: Date, minWidth: 175}; break;
          case "sourceOfRequest": column = { ...column, field: 'sourceOfRequest.value', headerName: 'Source of Request', minWidth: 100 }; break;
          case "assigneeId": column = { ...column, field: 'assignee.name', headerName: 'Assignee', minWidth: 125 }; break;
          case "status": column = { ...column, headerName: 'Status', cellClass: 'capitalize', maxWidth: 100 }; break;
          default: break;
        }
        columns.push(column)
      });
    return columns
  };

  const onGridReady = params => {
    gridApi = params.api;
    gridColumnApi = params.columnApi;

    const dataSource = {
      rowCount: undefined,
      getRows: (params) => {
        let filters = {
          offset: params.startRow,
          limit: 100,
          filter: [...UtilityService.ConvertAGGridFilterToApiFilter(params.filterModel)],
          sort: UtilityService.ConvertAGGridSortToApiSort(params.sortModel)
        };
        console.log(filters)
        setFilters(filters)
        gridApi.showLoadingOverlay();
        fetchTickets(filters).then((res) => {
          params.successCallback(res.data.tickets, res.data.total);
          gridApi.sizeColumnsToFit()
          gridApi.hideOverlay();
          //params.columnApi.autoSizeAllColumns();
        })
      },
    };
    params.api.setDatasource(dataSource);
  }

  const fetchTickets = async (filters) => {
    let ticketsResponse = await zenDeskService.getTickets(filters)
    ticketsResponse.data.tickets.forEach(x => {
      x = { ...x, ...x.ticket }
    })
    setTickets(ticketsResponse.data.tickets)
    setUsers(ticketsResponse.data.users)
    setTotalTickets(ticketsResponse.data.total)
    return ticketsResponse
  }

  return <>
    <Page title="Zendesk"
      thumbnail={thumbnail}
      banner={banner}
      availableSpace={
        <Stack alignItems="center">
          <Caption as="span" isSecondary className="white">
            <RefreshContent onClick={() => { this.loadData() }}></RefreshContent>
          </Caption>
          <Divider isFlexChild orientation="vertical" />
          <Caption as="span" isSecondary className="white">
            <ThemeSwitch>{props.theme}</ThemeSwitch>
          </Caption>
        </Stack>
      }
      secondaryActions={secondaryAction(props)}
  
    >
      <br />
      <Layout>
        <Layout.Section>
          <Layout.Panel title="View All Tickets" variant="darker">
            <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 290px)', width: '100%' }}>
              {showGrid ?
                <AgGridReact
                  columnDefs={getColumns()}
                  defaultColDef={defaultColDef}
                  rowModelType={'infinite'}
                  cacheOverflowSize={2}
                  maxConcurrentDatasourceRequests={2}
                  infiniteInitialRowCount={2}
                  maxBlocksInCache={2}
                  onGridReady={onGridReady}
                  pagination={true}
                  paginationPageSize={100}
                  loadingOverlayComponent={Loading}
                  loadingOverlayComponentParams={
                    loadingOverlayComponentParams
                  }
                  tooltipShowDelay={0}
                  tooltipHideDelay={3500}
                />
                : ''}
            </div>

            {/* <TableNext title="Zendesk Details" columns={mockColumns} data={mockData} defaultPageSize={20} pageSizeOptions={[10, 20, 30, 50, 100]} hasTotal // isExpandable
              isFilterable isSearchable isSortable hasPagination filters={selectedFilters} // search={selectedSearch}
              // isSelectable
              //selectedRows={selectedRows}
              // onSearchChange={setSelectedSearch}
              onFiltersChange={setSelectedFilters} onSelectedRowsChange={setSelectedRows} /> */}
          </Layout.Panel>
        </Layout.Section>
      </Layout>
    </Page>
  </>;
}

export default Zendeskdetails
