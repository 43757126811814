import React, { useState, useEffect, useRef } from "react";
import { Page, Loading, Image, Layout, TextInput, Textarea, Select, FormField, Button, SearchInput, Toast, Box, FormatCurrency } from "@dentsu-ui/components";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import fallbackImage from '../../assets/images/no-image.png'
import Date from '../../components/Date'
import ClientService from "../../services/clientService";
import ProductService from "../../services/productService";
import banner from "../../assets/images/background/dentsu_website_headers_8.jpg"
import thumbnail from "../../assets/images/FC-Logo-Icon.png"
import { secondaryAction } from "../../helper/secondaryAction";
const ItemLookUp = (props) => {
    const priceGridRef = useRef();
    const availabilityGridRef = useRef();
    const [loading, setLoading] = useState(false)
    const [product, setProduct] = useState({})
    const [productHistory, setProductHistory] = useState([])
    const [productKey, setProductKey] = useState('')
    const [clients, setClients] = useState([])
    const [selectedMarket, setSelectedMarket] = useState({ id: 0 })
    const toast = Toast()
    const pricingColumnDefs = [
        {
            headerName: "Date (CT)", field: 'history_created_date_time_CT', cellRenderer: Date, minWidth: 180
        },
        {
            headerName: "Type", field: 'feed_type', cellClass: 'capitalize',
        },
        {
            headerName: "Original Price", field: 'originalprice', 
        },
        {
            headerName: "New Price", field: 'newprice',
        },
        {
            headerName: "Original Sale Price", field: 'originalsaleprice',
        },
        {
            headerName: "New Sale Price", field: 'newsale_price',valueFormatter: (params) => (params.value).toFixed(2)
        },
        {
            headerName: "Original Shipping Price", field: 'originalshipping_price',
        },
        {
            headerName: "New Shipping Price", field: 'newshippingprice',
        },
    ]

    const availabilityColumnDefs = [
        {
            headerName: "Date (CT)", field: 'history_created_date_time_CT', cellRenderer: Date
        },
        {
            headerName: "Type", field: 'feed_type', cellClass: 'capitalize',
        },
        {
            headerName: "Status", field: 'availability', cellClass: 'capitalize'
        },
    ]
    const defaultColDef = { resizable: true, flex: 1, autoHeight: true, filter: true, sortable: true }


    useEffect(() => {
        document.title =`Item Lookup`
        async function getClients() {
            setLoading(true)
            let clients = await ClientService.getClients()
            let markets = await ClientService.getMarkets()

            clients.data.forEach(client => {
                client.label = client.name
                client.options = markets.data.filter(x => x.parentId == client.id)
                client.options.forEach(option => {
                    option.label = option.name
                });
            });
            setClients(clients.data)
            setLoading(false)
        }
        getClients()
    }, [])



    function onChange(e) {
        if (e.key == 'Enter') {
            if (!selectedMarket || selectedMarket.id == 0) {
                toast({ status: 'error', title: "Error", content: 'Please Select Client', duration: 10000 })
            } else {
                getProduct(productKey, selectedMarket.id)
            }
        }
    }

    async function getProduct(productKey, market) {
        try {
            setLoading(true)
            let product = await ProductService.getProduct(productKey, market)
            setProduct(product.data)
            setTimeout(() => { getProductHistory(productKey, market, product.data) }, 5000);
        } catch (error) {
            toast({ status: 'error', title: "Not found", content: 'Product not available', duration: 5000 })
            setProduct({})
        } finally {
            setLoading(false)
        }
    }

    async function getProductHistory(productKey, market, product) {
        try {
            priceGridRef?.current.api.showLoadingOverlay();
            availabilityGridRef?.current.api.showLoadingOverlay();
            let productHistory = await ProductService.getProductHistory(productKey, market)
            let historyData = productHistory.data
            // historyData.forEach(history => {

            //     history.newPrice = product.price
            //     history.newSalePrice = product.salePrice
            //     history.newShippingPrice = product.shippingPrice
            // });
 
            setProductHistory(historyData)  
            
        } catch (error) {
            toast({ status: 'error', title: "Not found", content: 'Product history not available', duration: 5000 })
            setProductHistory([])
        } finally {
            priceGridRef.current.api.hideOverlay();
            availabilityGridRef.current.api.hideOverlay();
        }
    }

    const itemDetailsClassName = "flex gap-16"
    const section1Class = "flex-auto w-64"
    const section2Class = "flex-auto w-32"
    const itemDetails = <div>
        <div className={itemDetailsClassName}>
            <div className={section1Class}>
                <FormField label="SKU">
                    <TextInput id={product.productId} value={product.productId} isFullWidth />
                </FormField>
            </div>
            <div className={section2Class}>
                <FormField label="Type">
                    <TextInput id={product.type} value={product.type} isFullWidth />
                </FormField>
            </div>
        </div>
        <div className={itemDetailsClassName}>
            <div className={section1Class}>
                <FormField label="Title">
                    <TextInput id={product.title} value={product.title} isFullWidth />
                </FormField>
            </div>
            <div className={section2Class}>
                <FormField label="Availability">
                    <TextInput id={product.availability} value={product.availability} isFullWidth />
                </FormField>
            </div>
        </div>
        <div className={itemDetailsClassName}>
            <div className={section1Class}>
                <FormField label="Description">
                    <Textarea id={product.fullDescription} value={product.fullDescription} isFullWidth height="230px" />
                </FormField>
            </div>
            <div className={section2Class}>
                <FormField label="Item Group">
                    <TextInput id={product.itemGroup} value={product.itemGroup} isFullWidth />
                </FormField>
                <FormField label="Price">
                    <TextInput id={product.price} value={product.price} isFullWidth />
                </FormField>
                <FormField label="Sale price">
                    <TextInput id={product.salePrice} value={product.salePrice} isFullWidth />
                </FormField>
            </div>
        </div>
        <div className={itemDetailsClassName}>
            <div className={section1Class}>
                <FormField label="GTIN">
                    <TextInput id={product.gtin} value={product.gtin} isFullWidth />
                </FormField>
            </div>
            <div className={section2Class}>
                <FormField label="Shipping Price">
                    <TextInput id={product.shippingPrice} value={product.shippingPrice} isFullWidth />
                </FormField>
            </div>
        </div>
        <div className={itemDetailsClassName}>
            <div className={section1Class}>
                <FormField label="Brand">
                    <TextInput id={product.brand} value={product.brand} isFullWidth />
                </FormField>
            </div>
            <div className={section2Class}>
                <FormField label="Color">
                    <TextInput id={product.color} value={product.color} isFullWidth />
                </FormField>
            </div>
        </div>
        <div className={itemDetailsClassName}>
            <div className={section1Class}>
                <FormField label="URL">
                    <TextInput id={product.url} value={product.url} isFullWidth />
                </FormField>
            </div>
            <div className={section2Class}>
                <FormField label="Gender">
                    <TextInput id={product.gender} value={product.gender} isFullWidth />
                </FormField>
            </div>
        </div>
        <div className={itemDetailsClassName}>
            <div className={section1Class}>
                <FormField label="Image URL">
                    <TextInput id={product.imageUrl} value={product.imageUrl} isFullWidth />
                </FormField>
            </div>
            <div className={section2Class}>
                <FormField label="Style">
                    <TextInput id={product.style} value={product.style} isFullWidth />
                </FormField>
            </div>
        </div>
        <div className={itemDetailsClassName}>
            <div className={section1Class}>
                <Image src={product.imageUrl} fallbackSrc={fallbackImage} alt="Image" />
            </div>
            <div className={section2Class}>
                <div className="flex-1">
                    <FormField label="Category">
                        <TextInput id={product.category} value={product.category} isFullWidth />
                    </FormField>
                </div>
                <div className="flex-1">
                    <FormField label="Size">
                        <TextInput id={product.size} value={product.size} isFullWidth />
                    </FormField>
                </div>
                <div className="flex-1">
                    <FormField label="Condition">
                        <TextInput id={product.condition} value={product.condition} isFullWidth />
                    </FormField>
                </div>
                <div className="flex-1">
                    <FormField label="Custom Label 0">
                        <TextInput id={product.customLabel0} value={product.customLabel0} isFullWidth />
                    </FormField>
                </div>
                <div className="flex-1">
                    <FormField label="Custom Label 1">
                        <TextInput id={product.customLabel1} value={product.customLabel1} isFullWidth />
                    </FormField>
                </div>
                <div className="flex-1">
                    <FormField label="Custom Label 2">
                        <TextInput id={product.customLabel2} value={product.customLabel2} isFullWidth />
                    </FormField>
                </div>
            </div>
        </div>
        <div className={itemDetailsClassName}>
            <div className={section1Class}></div>
            <div className={section2Class}>
                <FormField label="Custom Label 3">
                    <TextInput id={product.customLabel3} value={product.customLabel3} isFullWidth />
                </FormField>
            </div>
        </div>
        <div className={itemDetailsClassName}>
            <div className={section1Class}></div>
            <div className={section2Class}>
                <FormField label="Custom Label 4">
                    <TextInput id={product.customLabel4} value={product.customLabel4} isFullWidth />
                </FormField>
            </div>
        </div>
    </div>

    const priceGrid = <div className="py-2 ag-theme-alpine" style={{ height: 250, width: '100%' }}>
        <AgGridReact ref={priceGridRef} rowData={productHistory.pricing} columnDefs={pricingColumnDefs} defaultColDef={defaultColDef} >
        </AgGridReact>
    </div>

    const availabilityGrid = <div className="py-2 ag-theme-alpine" style={{ height: 250, width: '100%' }}>
        <AgGridReact ref={availabilityGridRef} rowData={productHistory.availability} columnDefs={availabilityColumnDefs} defaultColDef={defaultColDef} >
        </AgGridReact>
    </div>

    const saveButton = <div className="py-1 flex justify-end content-center relative ">
        <div>
            <Button variant="dark" size='medium' isDisabled>Save</Button>
        </div>
    </div>

    if (loading) {
        return (
            <>
                <Box className="flex items-center justify-center" height="100%">
                    <Loading>Please wait while we are fetching the latest content</Loading>
                </Box>
            </>
        );
    }

    return (
        <>
            <Page title="Item Lookup"
                banner={banner}
                thumbnail={thumbnail}
                secondaryActions={secondaryAction(props)}

            >
                {loading && <>
                    <Box className="flex items-center justify-center" height="100%">
                        <Loading>Please wait while we are fetching the latest content</Loading>
                    </Box>
                </>
                }

                {!loading && <>
                    <br />
                    <Layout>
                        <Layout.Section>
                            <div className="flex justify-end space-x-4 items-center pb-4">
                                <label>Client </label>
                                <Select
                                    width={250}
                                    options={clients}
                                    customLabelKey="name"
                                    customValueKey="id"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    onChange={(selected, event) => {
                                        setSelectedMarket(selected)
                                    }} value={selectedMarket} />
                            </div>
                        </Layout.Section>
                    </Layout>

                    <Layout>
                        <Layout.Section>
                            <div className="flex justify-end pb-4">
                                <div className="w-64">
                                    <SearchInput value={productKey} onKeyDown={onChange} onChange={e => { setProductKey(e.target.value) }} />
                                </div>
                            </div>
                        </Layout.Section>
                    </Layout>

                    <Layout>
                        <Layout.Section>
                            <Layout.Panel title="Item Details" variant="darker">
                                {itemDetails}
                                {saveButton}
                            </Layout.Panel>
                        </Layout.Section>
                    </Layout>

                    <Layout>
                        <Layout.Section>
                            <Layout.Panel title="Item Attribute History" variant="darker">
                                Pricing
                                {priceGrid}
                            </Layout.Panel>
                            <Layout.Panel title="" variant="darker">
                                Availability
                                {availabilityGrid}
                            </Layout.Panel>
                        </Layout.Section>
                    </Layout>
                </>
                }
            </Page>

        </>
    )
}
export default ItemLookUp