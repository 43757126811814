
export default props => {
    console.log(props)
    const data = props.api.getDisplayedRowAtIndex(props.rowIndex).data || {};
    return (
        <div className="custom-tooltip" style={{ backgroundColor: props.color || 'white' }}>
            <p>
                {
                    data.clientNames.map(x => {
                        return <p> {x}</p>
                    })
                }
            </p>
        </div>
    );
};