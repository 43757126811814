import { FilterModel } from "../models/FilterModel";

export default class UtilityService {
    static ConvertAGGridFilterToApiFilter(agGridFilter) {
        let condition, subCondition;
        let filter = []
        const filterGridResponse = agGridFilter;
        // Step 1. Get all the object keys.
        const evilResponseKeys = Object.keys(filterGridResponse);
        for (const prop of evilResponseKeys) {
            const criterio = filterGridResponse[prop];
            if (!criterio.hasOwnProperty('condition1')) {
                condition = new FilterModel(prop, criterio.type, criterio.filter, 'AND',);
                filter.push(condition);
            } else {
                const Conditions = Object.keys(criterio);
                subCondition = new FilterModel(prop, '', '', criterio.operator, []);
                for (const cond of Conditions) {
                    if (cond === 'condition1' || cond === 'condition2') {
                        const subquery = criterio[cond];
                        condition = new FilterModel(prop, subquery.type, subquery.filter, criterio.operator);
                        subCondition.filters.push(condition);
                    }
                }
                filter.push(subCondition);
            }
        }
        return filter;
    }

    static ConvertAGGridSortToApiSort(agGridSort) {
        let sort = []
        agGridSort.forEach(sortItem => {
            sort.push({
                Column: sortItem.colId,
                SortOrder: sortItem.sort // == 'asc' ? 'Ascending' : 'Descending'
            })
        });
        return sort
    }

    static ConvertClientIdsToApiFilter(clients) {
        let filter = new FilterModel();
        filter.field = 'client_id';
        filter.logic = 'OR';
        filter.filters = []
        clients.forEach(clientId => {
            let subFilter = new FilterModel('client_id', 'eq', clientId, 'OR');
            filter.filters.push(subFilter);
        });
        return filter;
    }
}