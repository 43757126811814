import http from "../helper/http-common";
import AuthService from "./authService";
export default class ClientService {
  static getClients() {
    return http.get(`clients`, {
      headers: { Authorization: `bearer ${AuthService.getToken()}` },
    }).catch(function (error) {
      return Promise.reject(error)
    });
  }

  static getMarkets() {
    return http.get(`markets`, {
      headers: { Authorization: `bearer ${AuthService.getToken()}` },
    }).catch(function (error) {
      return Promise.reject(error)
    });
  }

  static getVendors() {
    return http
      .get(`vendors`, { headers: { Authorization: `bearer ${AuthService.getToken()}` }, })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getClientAccounts(clientId,marketId) {
    return http
      .get(`clientAccounts`, {params: {
        client: clientId,
        market:marketId,
      }, headers: { Authorization: `bearer ${AuthService.getToken()}` }, })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getClientMetrics() {
    let user = AuthService.getUser();
    return http
      .get(`home/metrics`, {
        params: {
          userid: user.id,
        },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static GetAllClients() {
    let user = AuthService.getUser();
    return http.get(`Client/${user.sdUserID}/Client/GetAllClients`, {
      headers: { Authorization: `bearer ${AuthService.getToken()}` },
    });
  }

  static getImports() {
    let user = AuthService.getUser();
    return http
      .get(`home/imports`, {
        params: {
          userid: user.id,
        },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getExports() {
    let user = AuthService.getUser();
    return http
      .get(`home/exports`, {
        params: {
          userid: user.id,
        },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getErrors() {
    let user = AuthService.getUser();
    return http.get(`home/errors?userid=${user.id}`, {
      headers: { Authorization: `bearer ${AuthService.getToken()}` },
    });
  }

  static getLatestImportExport() {
    let user = AuthService.getUser();
    return http
      .get(`home/latest/importexport`, {
        params: {
          userid: user.id,
        },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getAssociatedAccounts() {
    let user = AuthService.getUser();

    return http.get(`Client/${user.sdUserID}/Client/GetAssociatedAccounts`, {
      headers: { Authorization: `bearer ${AuthService.getToken()}` },
    });
  }

  static getAssociatedImportErrors() {
    let user = AuthService.getUser();

    return http.post(
      `Client/${user.sdUserID}/Client/GetAssociatedImportErrors`,
      {
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
        params: {
          Limit: 0,
          Offset: 0,
        },
      }
    );
  }

  static getAssociatedExportErrors() {
    let user = AuthService.getUser();

    return http.post(
      `Client/${user.sdUserID}/Client/GetAssociatedExportErrors`,
      {
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
        params: {
          Limit: 0,
          Offset: 0,
        },
      }
    );
  }

  static getTimeline() {
    return http.get(`home/timeline`, {
      headers: { Authorization: `bearer ${AuthService.getToken()}` },
    }).catch(function (error) {
      return Promise.reject(error)
    });
  }

  static getImportDetails(marketId) {
    return http
      .get(`accounts/${marketId}/importmapping`, {
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getExportMappings(accountId, vendorId) {
    return http
      .get(`accounts/${accountId}/exportmapping`, {
        params: { vendorId },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getDeliveryMethods(market, account) {
    return http
      .get(`deliverymethods`, {
        params: {
          market: market,
          vendor: 0,
          account: account
        },
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static updateDeliveryMethod(deliveryMethod) {
    return http.put(`deliverymethods`, {
      ...deliveryMethod,
      headers: { Authorization: `bearer ${AuthService.getToken()}` },
    }).catch(function (error) {
      return Promise.reject(error)
    });
  }

  static addDeliveryMethod(payload) {
    return http
      .post(`deliverymethods`, {
        ...payload,
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }
  static addNewExport(exports) {
    return http
      .post(`home/newExport`, {
        ...exports,
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }



  static allExport(clientId) {
    return http
      .post(`home/${clientId}/RunAllExport`, {
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static getLatestExport(clientId, accountKey) {
    return http
      .get(`home/${clientId}/RunLatestExport/${accountKey}`, {
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
      })
      .catch(function (error) {
        return Promise.reject(error)
      });
  }

  static updateStatus(ID,IsActive,clientId) {
    return http.put(`UpdateStatus`, {
        ID,
       IsActive,
       clientId,        
        headers: { Authorization: `bearer ${AuthService.getToken()}` },
    }).catch(function (error) {
        return Promise.reject(error)
    });
}

static acountExport(clientId, accountKey) {
  return http
    .get(`home/${clientId}/RecentExportsForAccount/${accountKey}`, {
      headers: { Authorization: `bearer ${AuthService.getToken()}` },
    })
    .catch(function (error) {
      return Promise.reject(error)
    });
}

static acountImport(clientId) {
  return http
    .get(`home/${clientId}/RecentImportsForAccount`, {
      headers: { Authorization: `bearer ${AuthService.getToken()}` },
    })
    .catch(function (error) {
      return Promise.reject(error)
    });
}
static getExportFileName(clientId,AccountKey) {
  return http
    .get(`delivery/${clientId}/GetExportFileName/${AccountKey}`, {
      headers: { Authorization: `bearer ${AuthService.getToken()}` },
    })
    .catch(function (error) {
      return Promise.reject(error)
    });
}

// static updateFileName(clientId,accountKey,exportFileName) {
//   return http.put(`updateexportfilename`, {
//      clientId,  
//      accountKey, 
//      exportFileName,   
//       headers: { Authorization: `bearer ${AuthService.getToken()}` },
//   }).catch(function (error) {
//       return Promise.reject(error)
//   });
// }

static updateAccountName(clientId,accountKey,accountName,exportFileName) {
  return http.put(`updateexportfilename`, {
     clientId,  
     accountKey,   
     accountName,   
     exportFileName,
      headers: { Authorization: `bearer ${AuthService.getToken()}` },
  }).catch(function (error) {
      return Promise.reject(error)
  });
}
}