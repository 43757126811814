import React from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { Page, Loading, Layout, Select, FormField, Button, Box } from "@dentsu-ui/components";
import AccountService from "../../services/AccountService";
import ClientService from "../../services/clientService";
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import FileDetails from '../../components/data/fileDetails';
import * as XLSX from "xlsx";
import moment from "moment";
import FileData from "../../components/data/fileData";
import FileUpload from "../../components/data/fileUpload";
import banner from "../../assets/images/background/dentsu_website_headers_13.jpg"
import thumbnail from "../../assets/images/FC-Logo-Icon.png"
import { secondaryAction } from "../../helper/secondaryAction"
class DataExplore extends React.Component {
    constructor(props) {
        super(props);
        this.downloadFile = [
            { id: 2001, name: "csv" },
            { id: 2002, name: "tsv" },
            { id: 2003, name: "txt" },
            { id: 2004, name: "xml" }
        ]
        this.exportData = React.createRef();
        this.state = {
            loading: false,
            file: "",
            fileData: {},
            loadFiledata: false,
            downloadFileType: "",
            fileName: "",
            totalColumnCount: "",
            fileType: "",
            filesize: "",
            totalRowCount: "",
            delimiter: "",
            clients: [],
            markets: [],
            vendor: [],
            accounts: [],
            clientId: 0,
            marketId: 0,
            vendorId: 0,
            data: [],
            cols: [],
        };
        this.handleFile = this.handleFile.bind(this);
        this.fileDataRef = React.createRef()
        document.title = `Data Explorer`
    }
    componentDidMount() {
        this.loadData();
    }
    fecthAccounts = async () => {
        return Promise.all([
            ClientService.getClients(),
            AccountService.getAccountsMarkets(),
            ClientService.getVendors(),
            AccountService.getAccounts()
        ])
    }
    loadData = async () => {
        try {
            this.setState({ loading: true });
            let [clients, market, vendors, accounts] = await this.fecthAccounts();
            this.setState({ clients: clients.data });
            this.setState({ clientId: clients.data[0].id });
            this.setState({ markets: market.data });
            this.setState({ marketId: market.data[0].id });
            this.setState({ vendor: vendors.data });
            this.setState({ accounts: accounts.data });
        }
        catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    }
    handleFile(file) {
        this.setState({ fileName: file.name });
        this.setState({ filesize: file.size });
        this.setState({ fileType: file.type });
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { raw: false });
            this.setState({ data: data, cols: this.make_cols(ws["!ref"]) });
            this.setState({ totalRowCount: this.state.data.length });
            this.setState({ totalColumnCount: this.state.cols.length });
            this.setState({ delimiter: "comma" });
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    }
    make_cols = refstr => {
        let o = [],
            C = XLSX.utils.decode_range(refstr).e.c + 1;
        for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
        return o;
    };
    onfileDownload = () => {
        this.fileDataRef.current.download(this.state.downloadFileType, this.state.fileName.replace('.csv', ''))
    }

    onChangeStage(name) {
        this.setState({ downloadFileType: name });
    }

    render() {
        if (this.state.loading) {
            return (
                <>
                    <Box className="flex items-center justify-center" height="100%">
                        <Loading>Please wait while we are fetching the latest content</Loading>
                    </Box>
                </>
            );
        }
        const fileDownload = <div>
            <FormField label="Type">
                <Select options={this.downloadFile}
                    menuPlacement='bottom'
                    width={250}
                    customLabelKey="name"
                    customValueKey="id"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    onChange={(selected, event) => {
                        this.setState({ downloadFileType: selected });
                    }} value={this.state.downloadFileType} />
            </FormField>
            <Button variant="tertiary" size="medium" onClick={e => this.onfileDownload()}>
                Download
            </Button>
        </div >
        const fileDetails = <div>
            <FileDetails value={{
                isNew: true,
                fileName: this.state.fileName,
                totalColumnCount: this.state.totalColumnCount,
                fileType: this.state.fileType,
                filesize: this.state.filesize,
                totalRowCount: this.state.totalRowCount,
                delimiter: this.state.delimiter,
            }}></FileDetails>
        </div>
        const fileData = <div>
            <FileData data={this.state.data} ref={this.fileDataRef} />
        </div>
        return (
            <>
                <Page title="Data Explorer"
                    banner={banner}
                    thumbnail={thumbnail}
                    secondaryActions={secondaryAction(this.props)}

                >
                    <Layout>
                        <Layout.Section>
                            <Layout.Panel title="File Upload" hasBorder variant="darker">
                                <FileUpload handleFile={this.handleFile} />
                            </Layout.Panel>
                        </Layout.Section>
                        <Layout.Section>
                            <Layout.Panel title="File Download" hasBorder variant="darker">
                                {fileDownload}
                            </Layout.Panel>
                        </Layout.Section>
                    </Layout>
                    <Layout>
                        <Layout.Section>
                            <Layout.Panel title="File Details" variant="darker">
                                {fileDetails}
                            </Layout.Panel>
                        </Layout.Section>
                    </Layout>
                    <Layout>
                        <Layout.Section isScrollable>
                            <Layout.Panel title="File Details" variant="darker">
                                {fileData}
                            </Layout.Panel>
                        </Layout.Section>
                    </Layout>

                </Page>
            </>
        )
    }
}

export default DataExplore

