import React from 'react';
const TicketDetails = (props) => {

    const ticketId = props.data ? props.data.ticket.id : '';

    const findLink = () => {
        window.open(`/ticketdetails?ticketId=${ticketId}`, '_blank');
    }
    return (
        <>
            <a onClick={findLink}>{ticketId || 'More...'}</a>
        </>
    )
}

export default TicketDetails