import React from "react";
import { withRouter } from "react-router-dom";
import "./Login.css"
import logo from "../assets/images/FeedConnectLogo.png"
import img_iProspect_Patterns8 from "../assets/images/iProspect_Patterns8.jpeg"
import sideLogo from "../assets/images/Technology-44-kv.jpg"

import { Button, TextInput, Alert } from "@dentsu-ui/components";

import AuthService from "../services/authService";
import { ColorBlack } from "@dentsu-ui/tokens";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      username: "",
      password: "",
      show: false,
      invalid: false
    };

    this.handleLogin = this.handleLogin.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  updateUserName = (event) => {
    this.setState({
      username: event.target.value,
    });
  };
  updatePassword = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  handleSubmit(e) {
    if (e.key === 'Enter' && this.state.username.length > 0 && this.state.password.length > 0) {
      this.handleLogin()
    }
  }

  async handleLogin() {
    this.setState({ loading: true });
    try {
      await AuthService.login(this.state.username, this.state.password)
      this.setState({ invalid: false })
      window.location.reload()
    } catch (error) {
      this.setState({ invalid: true })
      console.log(error)
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <div className="w-full flex flex-wrap">
        <div className="w-full md:w-1/2 flex flex-col">
          <div className="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
            <a href="#" className="text-white font-bold text-xl pt-10 pb-2"><img
              src={logo} /></a>
            <div className={this.state.invalid || "invisible"}>
              <Alert status="error">Incorrect username or password</Alert>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col">
                <label htmlFor="email" className="text-lg">Email</label>
                <TextInput id="email" description="Username" placeholder="your@iprospect.com" type="text" onChange={this.updateUserName} isFullWidth
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline" />
              </div>

              <div className="flex flex-col pt-4">
                <label htmlFor="password" className="text-lg">Password</label>
                <TextInput id="password" description="Password" placeholder="Password" type="password" isFullWidth
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={this.updatePassword} onKeyDown={this.handleSubmit} />
              </div>
              <Button onClick={this.handleLogin} style={{ backgroundColor: ColorBlack }} className="bg-black text-white font-bold text-lg hover:bg-gray-700 p-2 mt-8"
                isLoading={this.state.loading}>
                Log In
              </Button>
            </div>
          </div>
        </div>
        <div className="w-1/2 shadow-2xl">
          <img className="object-cover w-full h-screen hidden md:block" src={sideLogo} />
        </div>
      </div >
    )
  }
}

export default withRouter(Login)
