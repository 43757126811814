import React, { useState} from "react";
import { Icon, Dialog,Button} from "@dentsu-ui/components";

const DeleteDialog = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
    <Icon className="cursor-pointer mb-2" color={'black'} size={25} icon={'cross'}
     onClick={() => setDialogOpen(true)} data-id={props.index}> </Icon> 
       <Dialog id={props.index} title="Delete" isOpen={dialogOpen} onClose={() => setDialogOpen(false)}>
            <Dialog.Body >
            <p>Are you sure you want to delete this category mapping?</p>
            </Dialog.Body>
            <Dialog.Footer id={props.index} >
            <Button variant="secondary" onClick={() => setDialogOpen(false)}>
                Cancel
            </Button>
            <Button onClick={props.deleteFieldMapping} data-id={props.index}>
                Delete
            </Button>
            </Dialog.Footer>
        </Dialog>
        </>
  )
}

export default DeleteDialog