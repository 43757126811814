import React from "react";
import { Select, Loading, Icon, FormField, DateInput, Legends, Page, Caption, Stack, TextInput, Divider, FormatNumber, Button, Spacer, Image, Switch, Radio, Checkbox, } from "@dentsu-ui/components";
import ThemeSwitch from "../../components/ThemeSwitch";
import RefreshContent from "../../components/RefreshContent";
import AccountService from "../../services/AccountService";
import ClientService from "../../services/clientService";
import banner from "../../assets/images/background/dentsu_website_headers_5.jpg"
import thumbnail from "../../assets/images/FC-Logo-Icon.png"
import { secondaryAction } from "../../helper/secondaryAction"
class AccountsManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            markets: [],
            markets: [],
            vendors: [],
            accountsData: [],
            workDays: [],
            vendorName: "",
            dayOfWeek: "",
            mon: false,
            tues: false,
            wed: false,
            thurs: false,
            fri: false,
            sat: false,
            sun: false,
            selectedMarkets: [],
            selectedAccounts: [],
            marketName: "",
            accountName: "",
            selectedAccountName: "",
            activeMode: false,
            active: "",
            fieldMapping: [{ importField: null, fcFields: null }, { importField: null, fcFields: null }, { importField: null, fcFields: null }, { importField: null, fcFields: null }],
            feedConnectField: [],
        };
        document.title =`Account Setup`
    }

    componentDidMount() {
        this.loadData();
    }
    fecthAccounts = async (id) => {
        return Promise.all([
            AccountService.getManagementAccount(id),
            ClientService.getVendors(),
            AccountService.getAccountsMarkets(),
        ]);
    }
    loadData = async () => {
        try {
            this.setState({ loading: true });
            let [accountsData, vendors, markets] = await this.fecthAccounts(this.props.match.params.id);
            this.setState({ accountsData: accountsData.data });
            this.setState({ vendors: vendors.data })
            this.setState({ markets: markets.data });
            this.vendorName(accountsData.data.vendor);
            this.marketName(accountsData.data.client);
            this.workDays(accountsData.data.dayOfWeek);
        }
        catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    }
    workDays = (weekDays) => {
        let arr = weekDays.split(' ');
        if (arr[0] == 'M') {
            this.setState({ mon: true });
        }
        if (arr[0] == 'T') {
            this.setState({ tues: true });
        }
        if (arr[0] == 'W') {
            this.setState({ wed: true });
        }
        if (arr[0] == 'R') {
            this.setState({ thurs: true });
        }
        if (arr[0] == 'F') {
            this.setState({ fri: true });
        }
        if (arr[0] == 'S') {
            this.setState({ sat: true });
        }
        if (arr[0] == 'U') {
            this.setState({ sun: true });
        }
    }
    updateAccount = (event) => {
        this.setState({
            accountName: event.target.value,
        });
    };

    vendorName(id) {
        let vendor = this.state.vendors.filter(x => x.id == id)[0] || {}
        this.setState({ vendorName: vendor.displayName });
        return vendor.displayName
    }
    marketName(id) {
        let market = this.state.markets.filter(x => x.id === id)[0] || {}
        this.setState({ marketName: market.displayName });
        return market.displayName
    }
    handleCheckboxChange = event => {

    }
    async updateAccountInfo() {
        try {
            await AccountService.updateAccount(this.state.userId, this.state.firstName, this.state.lastName, this.state.email, this.state.team, this.state.role, this.state.selectedClients)
        } catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    };
    ActiveOrInactive = () => {
        this.setState({});
    };
    AddFieldMapping = () => {
        this.setState({ fieldMapping: [...this.state.fieldMapping, { importField: null, fcFields: null }] })
    }
    render() {
        const fieldMapping = (this.state.fieldMapping);
        const addFields = fieldMapping.map((index) => {
            let i = 0;
            return (
                <div className="p-3 justify-around space-x-4" key={'addFields' + index}>
                    <div className="flex items-center">
                        <div className="flex space-y-4 sm:space-x-10 xl:space-x-40 gap-4 justify-around">
                            <label className="font-bold"></label>
                            <Select
                                maxMenuHeight="50"
                                menuPlacement='bottom'
                                width={250}>
                            </Select>
                            <label className="font-bold"></label>
                            <Select
                                maxMenuHeight="50"
                                menuPlacement='bottom'
                                width={250}>
                            </Select>
                        </div>
                    </div>
                </div>
            );
        })
        return (
            <Page title="Account Setup"
                banner={banner}
                thumbnail={thumbnail}
                secondaryActions={secondaryAction(this.props)}
            >
                <br />
                <div className="container w-full mx-auto">
                    <div className="w-full px-4 md:px-0 md:mt-8 mb-16 text-gray-800 leading-normal">
                        <div className="flex flex-row flex-wrap flex-grow mt-2">
                            {/* <div className="w-full p-3">
                                <div className="bg-white border rounded shadow">
                                    <div className="border-b p-3 bg-[#60607d]">
                                        <h5 className="font-bold uppercase text-[#ffffff]">Accouont SetUp</h5>
                                    </div>
                                    <div className="p-5 grid gap-1 grid-cols-3">
                                        <div className="flex flex-col">
                                            <Switch isChecked={this.state.accountsData.isActive} onChange={this.ActiveOrInactive} />
                                            <label className="font-bold">{this.state.active}</label>
                                        </div>
                                        <div>
                                            <FormField label="Account Name">
                                                <TextInput value={this.state.accountsData.name || ''} onChange={this.updateAccount} />
                                            </FormField>
                                        </div>
                                    </div>
                                    <div className="w-full p-3 grid gap-1 grid-cols-3">
                                        <div className="flex flex-row space-x-4 items-center">
                                            <FormField label="Partner">
                                                <Select defaultValue={this.state.vendorName}
                                                    maxMenuHeight="50"
                                                    menuPlacement='bottom'
                                                    width={250}
                                                    customLabelKey="name"
                                                    customValueKey="id"
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    onChange={(option) => {
                                                        this.setState({ vendorName: option })
                                                    }}
                                                    options={this.state.vendors}
                                                    value={this.state.vendorName} />
                                            </FormField>
                                        </div>
                                        <div className="flex flex-row space-x-4 items-center">
                                            <FormField label="Program">
                                                <Select options={this.state.markets}
                                                    maxMenuHeight="50"
                                                    menuPlacement='bottom'
                                                    width={250}
                                                    customLabelKey="name"
                                                    customValueKey="id"
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    onChange={(selected, event) => {
                                                        this.setState({ marketName: selected })
                                                    }} value={this.state.marketName} />
                                            </FormField>
                                        </div>
                                        <div className="flex flex-row space-x-4 items-center">
                                            <FormField label="Feed Type">
                                                <Select options={""}
                                                    maxMenuHeight="50"
                                                    menuPlacement='bottom'
                                                    width={250}
                                                    customLabelKey="name"
                                                    customValueKey="id"
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    onChange={(selected, event) => {
                                                        this.onChangeAccount(selected.id)
                                                    }} value={this.state.selected} />
                                            </FormField>
                                        </div>
                                    </div>
                                    <div className="w-full p-3 grid">
                                        <div className="flex flex-row space-x-4 items-center">
                                            <label>Delivery Dates</label>
                                            <Radio name="group" value={""} label="All" />
                                            <div className="flex flex-row">
                                                <div className="space-x-1">
                                                    <Checkbox id={this.state.sun} isChecked={this.state.sun} onChange={this.handleCheckboxChange}>Sun</Checkbox>
                                                </div>
                                                <div className="space-x-1">
                                                    <Checkbox id={this.state.mon} isChecked={this.state.mon} onChange={this.handleCheckboxChange}>Mon</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox id={this.state.tues} isChecked={this.state.tues} onChange={this.handleCheckboxChange}>Tue</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox id={this.state.wed} isChecked={this.state.wed} onChange={this.handleCheckboxChange}>Wed</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox id={this.state.thurs} isChecked={this.state.thurs} onChange={this.handleCheckboxChange}>Thurs</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox id={this.state.fri} isChecked={this.state.fri} onChange={this.handleCheckboxChange}>Fri</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox id={this.state.sat} isChecked={this.state.sat} onChange={this.handleCheckboxChange}>Sat</Checkbox>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3 grid gap-1 grid-cols-3">
                                        <div className="flex flex-row space-x-4 items-center">
                                            <FormField label="start Time">
                                                <DateInput placeholder="DD/MM/YYYY" value={this.state.accountsData.startTime} parseDate={date => new Date(date)} formatDate={date => date.toLocaleDateString()} onChange={date => {
                                                    console.log('onChange date', date);
                                                }} />
                                            </FormField>
                                            <Select
                                                maxMenuHeight="50"
                                                menuPlacement='bottom'
                                                width={100}>
                                            </Select>
                                        </div>
                                        <div className="flex flex-row space-x-4 items-center">
                                            <FormField label="Cadence">
                                                <Select
                                                    maxMenuHeight="50"
                                                    menuPlacement='bottom'
                                                    width={150}>
                                                </Select>
                                            </FormField>
                                        </div>
                                        <div className="flex flex-row space-x-4 items-center">
                                            <FormField label="Threshold">
                                                <Select
                                                    maxMenuHeight="50"
                                                    menuPlacement='bottom'
                                                    width={150}>
                                                </Select>
                                            </FormField>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="w-full p-3">
                                <div className="bg-white border rounded shadow">
                                    <div className="border-b p-3 bg-[#60607d]">
                                        <h5 className="font-bold uppercase text-[#ffffff]">Field Mapping</h5>
                                    </div>
                                    <div className="p-5 flex flex-row justify-around">
                                        <h5 className="font-bold underline">Import Feed Values</h5>
                                        <h5 className="font-bold underline">FeedConnect Field</h5>
                                    </div>
                                    <div className="flex flex-wrap">
                                        {addFields}
                                    </div>
                                    <div className="flex flex-row justify-around">
                                        <Icon color={'black'} size={25} icon={'add'} onClick={this.AddFieldMapping}></Icon>
                                        <div className="pb-10">
                                            <Button variant="tertiary">Preview </Button><Spacer width={8} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full p-3">
                                <div className="bg-white border rounded shadow">
                                    <div className="border-b p-3 bg-[#60607d]">
                                        <h5 className="font-bold uppercase text-[#ffffff]">Feed Review</h5>
                                    </div>
                                    <div className="w-full p-3">
                                        <div className="bg-white border rounded shadow border-red-600 gap-1 grid-cols-1">
                                            <div className="border-b p-5 grid  ">
                                                <h5 className="font-bold uppercase text-[#60607d]]">Feed Analysis
                                                </h5>
                                                <div>
                                                    <h5>5 Warnings 2,394 Warnings , required fields</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-5">
                                            <table className="w-full p-5 text-gray-700 min-w-full">
                                                <thead className="px-6 py-3 border-b border-gray-300 bg-gray-50 text-left text-xs leading-4 font-medium">
                                                    <tr>
                                                        <th className="px-6 py-3 border-b border-gray-300 bg-gray-50 text-left text-xs leading-4 font-bold uppercase text-left text-black-900">SKU</th>
                                                        <th className="font-bold uppercase text-left text-black-900">Title</th>
                                                        <th className="font-bold uppercase text-left text-black-900">Brand</th>
                                                        <th className="font-bold uppercase text-left text-black-900">URL</th>
                                                        <th className="font-bold uppercase text-left text-black-900">Image Link</th>
                                                        <th className="font-bold uppercase text-left text-black-900">Pr</th>
                                                        <th className="font-bold uppercase text-left text-black-900">SAL</th>
                                                        <th className="font-bold uppercase text-left text-black-900">Avai</th>
                                                        <th className="font-bold uppercase text-left text-black-900">Category</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {""}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row flex-wrap flex-grow justify-center items-center">
                                <div className="">
                                    <Button variant="tertiary" size="medium" onClick={this.updateAccountInfo} >Save</Button>
                                </div> &nbsp;&nbsp;&nbsp;&nbsp;
                                <div className="">
                                    <Button variant="tertiary" size="medium" >Cancel</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        )
    }
}

export default AccountsManagement