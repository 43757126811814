import { useSelector } from 'react-redux'
import { Image } from '@dentsu-ui/components'
import fallbackImage from '../../assets/images/no-image.png'

const ExportVendorDetail = (props) => {
    const vendors = useSelector(state => state.vendors.value)

    const getVendorLogo = (id) => {
        try {
            return require(`../../assets/images/vendor-logo/${id}.jpg`);
        } catch (error) {
            return fallbackImage
        }
    }

    const getVendorName = (id) => {
        let vendor = vendors.filter(x => x.id == id)[0] || {}
        return vendor.displayName
    }

    const getVendorDisplayName = (id) => {
        let vendor = vendors.filter(x => x.id == id)[0] || {}
        return vendor.name
    }

    return (
        <>
            <div className="flex items-center py-2">
                <div className="flex-shrink-0 h-10 w-10">
                    <Image className="h-10 w-10 rounded-full" src={getVendorLogo(props.value)} fallbackSrc={fallbackImage}></Image>
                </div>
                <div className="ml-4">
                    <div className="text-sm leading-5 font-medium text-gray-900">{getVendorName(props.value)}</div>
                    <div className="text-sm leading-5 text-gray-500">{getVendorDisplayName(props.value)}</div>
                </div>
            </div>
        </>
    )
}

export default ExportVendorDetail;